<!--
 * @Author: PC
 * @Date: 2022-07-08 15:39:33
 * @LastEditTime: 2022-08-08 21:36:51
 * @Description: 请您输入文件描述
-->
<template>
    <div id="myChart" :style="{'margin-top':'30px', width: '480px', height: '370px'}"></div>
</template>
<script>
    export default {
        data () {
            return {

            }
        },
        mounted () {
            // this.drawLine();
            this.drawLine()
        },
        methods: {
            drawLine(){
                // 基于准备好的dom，初始化echarts实例
                let that = this
                let myChart = this.$echarts.init(document.getElementById('myChart'))
                // 绘制图表
                myChart.setOption({
                  legend: {
                    orient: 'vertical',
                    x: 'center',
                    y: 'bottom',
                    align: 'left',
                    padding:[0,0,0,0],
                    itemHeight: 6,
                    itemWidth: 6,
                    textStyle: {
                      color: '#fff',
                    },
                    formatter: function(industry_type) {
                      var index = 0;
                      var clientlabels = that.$store.state.home.allAtlas;
                      var clientcounts = that.$store.state.home.allAtlas;
                      clientlabels.forEach(function(value,i){
                          if(value.name == industry_type){
                              index = i;
                          }
                      });
                      return industry_type + "  " + '(' + clientcounts[index].value + ')';
                    }
                    // this.$store.state.home.allAtlas
                    // formatter: function(name) {
                    //   var data = setOption.series[0].data;
                    //   var total = 0;
                    //   var tarValue;
                    //   for (var i = 0; i < data.length; i++) {
                    //     total += data[i].value;
                    //     if (data[i].name == name) {
                    //       tarValue = data[i].value;
                    //     }
                    //   }
                    //   var v = tarValue;
                    //   var p = Math.round(((tarValue / total) * 100));
                    //   return `${name}  ${v}人(${p}%)`;
                    // },
                  },
                  backgroundColor: '',
                  title: {
                    text: '',
                    left: 'center',
                    top: 20,
                    textStyle: {
                      color: ''
                    }
                  },
                  tooltip: {
                    trigger: 'item',
                    color: '#fff'
                  },
                  visualMap: {
                    show: false,
                    // min: 0,
                    // max: 30,
                    inRange: {
                      // colorLightness: [0, 1]
                    }
                  },
                  color: [
                        '#5c6ae5',
                        '#dd7077',
                        '#2182ff',
                        '#edb76e',
                        '#51dbb2',
                        '#a969c6'
                  ],
                  series: [
                    {
                      name: '',
                      type: 'pie',
                      radius: '40%',
                      center: ['50%', '35%'],
                      data: this.$store.state.home.allAtlas,
                      /* data: [
                        { value: this.$store.state.home.allAtlas[0].company_num, name: this.$store.state.home.allAtlas[0].industry_type },
                        { value: this.$store.state.home.allAtlas[1].company_num, name: this.$store.state.home.allAtlas[1].industry_type },
                        { value: this.$store.state.home.allAtlas[2].company_num, name: this.$store.state.home.allAtlas[2].industry_type },
                        { value: this.$store.state.home.allAtlas[3].company_num, name: this.$store.state.home.allAtlas[3].industry_type },
                        { value: this.$store.state.home.allAtlas[4].company_num, name: this.$store.state.home.allAtlas[4].industry_type },
                        // { value: this.$store.state.home.allAtlas[5].company_num, name: this.$store.state.home.allAtlas[5].industry_type },
                        
                        // 医药制造（7）；生物技术（8）、医疗器械（2）、数字健康（1）、材料（1）、化工（1）
                      ], */
                      roseType: 'radius',
                      label: {
                        normal: {
                          textStyle: {
                              color: '#fff',
                          },
                          formatter: `{b} ({c})`
                        },
                      },
                      
                      labelLine: {
                        lineStyle: {
                          // color: 'rgba(255, 255, 255, 0.3)'
                        },
                        smooth: 0.2,
                        length: 10,
                        length2: 20
                      },
                      itemStyle: {
                        // color: 'green',
                        // shadowBlur: 200,
                        // shadowColor: 'rgba(0, 0, 0, 0.5)'
                      },
                       
                      animationType: 'scale',
                      animationEasing: 'elasticOut',
                      animationDelay: function () {
                        return Math.random() * 200;
                      }
                    },
                  ]
                });
            },
            //#region 
            /* drawLineTwo () {
                let myChart = this.$echarts.init(document.getElementById('myChart'))
                myChart.setOption({
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b} : {c} ({d}%)'
                    },
                    legend: {
                        orient: 'vertical',
                        x: 'right',
                        y: 'center',
                        align: 'left',
                        itemHeight: 6,
                        itemWidth: 6,
                        // color: '#fff'
                        textStyle: {
                            color: '#fff',
                        }
                    },
                    toolbox: {
                        show: true,
                        feature: {
                            mark: { show: false },
                            dataView: { show: false, readOnly: false },
                            restore: { show: false },
                        }
                    },
                    series: [
                        {
                            name: '医疗健康产业图谱',
                            type: 'pie',
                            radius: [1, 80],
                            center: ['40%', '50%'],
                            roseType: 'area',
                            itemStyle: {
                                borderRadius: 0
                            },
                            color: [
                                '#5c6ae5',
                                '#dd7077',
                                '#2182ff',
                                '#edb76e',
                                '#51dbb2',
                            ],
                            data: [
                                { value: 400, name: '数字健康', },
                                { value: 235, name: '化妆品' },
                                { value: 274, name: '医疗器械' },
                                { value: 310, name: '医药制造' },
                                { value: 335, name: '生物技术' },
                            ],
                            label: {
                                normal: {
                                    textStyle: {
                                        color: '#fff'
                                    }
                                }
                            }
                        }
                    ]
                })
            } */
            //#endregion
        }
    }
</script>