<!--
 * @Author: ch3nh2
 * @Date: 2022-11-01 09:35:26
 * @LastEditors: ch3nh2
 * @LastEditTime: 2023-08-31 13:46:45
 * @FilePath: \beilunchanye_fe_20211208\src\components\SwitchMainScreen.vue
 * @Description: 产业、集团、园区切换组件
-->
<template>
	<div class="switch-main-screen">
		<!-- 正常状态 -->
		<transition name="el-fade-in-linear">
			<div v-show="!active" class="switch-item switch-item-normal" :style="background('normal')" @click="switchFunc"></div>
		</transition>
		<!-- 激活状态 -->
		<transition name="el-fade-in-linear">
			<div v-show="active" class="switch-item switch-item-active" :style="background('active')">
				<div class="switch-item-active-hide" @click="switchFunc"></div>
				<div class="switch-item-active-item switch-item-active-01" @click="onItemOne">
					<div class="switch-item-icon" :style="icon('01')"></div>
					<div class="switch-item-name">
						{{ typeNum == 1 ? '园区' : '集团' }}
					</div>
				</div>
				<div class="switch-item-active-item switch-item-active-02" @click="onItemTwo">
					<div class="switch-item-icon" :style="icon('02')"></div>
					<div class="switch-item-name">
						{{ $store.state.mapType || typeNum == 1 ? '产业' : '园区' }}
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	import { notification } from 'ant-design-vue';
	export default {
		name: 'SwitchMainScreen',
		data() {
			return {
				active: true,
				previousPage: '',
			};
		},
		props: {
			/** typeNum定义：1.集团 2.海星医药健康创新园A区（贝伦） 3.融汇 4.中关村国际大厦 5.海星医药健康创新园B区（飞驰绿能） 6.卫星厂 7.滑雪场 8.大行基业 9.海星医药 10.其他资产 11.馨雅大厦 */
			typeNum: {
				type: Number,
				default: 1,
			},
		},
		watch: {},
		computed: {
			background() {
				const backgroundObj = {
					normal: `background-image: url(${require('../assets/images/switchMainScreen/item-' + this.typeNum + '-normal.png')})`,
					active: `background-image: url(${require('../assets/images/switchMainScreen/item-' + this.typeNum + '-active.png')})`,
				};
				return function (type) {
					return backgroundObj[type];
				};
			},
			icon() {
				return function (type) {
					return `background-image: url(${require('../assets/images/switchMainScreen/item-' + this.typeNum + '-icon-' + type + '.png')})`;
				};
			},
		},
		components: {},
		methods: {
			switchFunc() {
				this.active = !this.active;
			},
			onItemOne() {
				if (this.typeNum == 1) {
					if (this.previousPage) {
						this.$store.state.mapType = true;
						this.$router.push({ name: this.previousPage });
					} else {
						notification.error({
							message: '无访问权限，请联系管理员核实~',
						});
					}
				} else {
					this.$store.state.mapType = false;
					this.previousPage = this.$route.name;
					this.$router.push('/');
				}
				if (this.$store.state.mapType) {
					this.cameraFly();
				} else {
					this.chaneFullType();
				}
			},
			onItemTwo() {
				if (this.typeNum == 1) {
					this.$store.state.mapType = false;
					this.$router.push({ name: 'xyds' });
				} else {
					this.$store.state.mapType = !this.$store.state.mapType;
				}
				if (this.$store.state.mapType) {
					this.cameraFly();
				} else {
					this.chaneFullType();
				}
			},
			permissionsPage() {
				const loginValue = JSON.parse(localStorage.getItem('code'));
				const _test = loginValue.userData.permissions;
				const permissions = [
					{
						name: '其他资产_fe',
						page: 'other',
					},
					{
						name: '海星医药健康创新园C区_fe',
						page: 'haixing',
					},
					{
						name: '大行基业_fe',
						page: 'dahang',
					},
					{
						name: '温泉冰雪体育公园项目',
						page: 'jinHui',
					},
					{
						name: '北京卫星制造厂',
						page: 'weixingHome',
					},
					{
						name: '海星医药健康创新园B区',
						page: 'feiChiHome',
					},
					{
						name: '中关村国际创新大厦',
						page: 'zhongguancunHome',
					},
					{
						name: '融汇国际大厦',
						page: 'roHuiHome',
					},
					{
						name: '海星医药健康创新园A区',
						page: 'beilun',
					},
					{
						name: '四季青科技园·馨雅大厦_fe',
						page: 'xyds',
					},
				];
				for (let i = 0; i < permissions.length; i++) {
					for (let key in _test) {
						if (_test[key] == permissions[i].name) {
							this.previousPage = permissions[i].page;
						}
					}
				}
			},
			cameraFly() {
				const pathName = this.$route.name;
				const screenWrap = document.getElementById('screenWrap');
				let msg = {};
				let msg2 = {
					funcName: 'resetFull',
				};
				if (pathName == 'xyds') {
					msg = {
						funcName: 'cameraFly',
						param: {
							id: 22,
							title: '馨雅大厦',
							content: '馨雅大厦位于西四环北路63号，地理位置优越，交通便利，附近有多条公交线路经过。楼内多种户型的办公间可供选择，并可根据客户的要求自由分隔，商务设施齐全，周边分布有餐饮、超市、酒店等配套设施。',
						},
					};
				} else if (pathName == 'beilun' || pathName == 'attract' || pathName == 'overView' || pathName == 'smartPark') {
					msg = {
						funcName: 'cameraFly',
						param: {
							id: 5,
							title: '海星医药健康创新园A区',
							content: '中关村科学城医药健康产业园，是中关村科学城首个以生物医药产业为核心的产业园,项目总占地面积28702.44平米，总建筑面积 57695.46平米，由18个单体建筑组成，建筑控高为24米，建筑首层层高4.5米，2~4层高6.4米，项目产业定位为生物医药的研发和中试。',
						},
					};
				} else if (pathName == 'roHuiHome' || pathName == 'roHuiIndustry') {
					msg = {
						funcName: 'cameraFly',
						param: {
							id: 2,
							title: '融汇国际大厦',
							content: '大厦位于北京市西北二环核心商区、总建筑面积45000平米，办公区面积234500平米，入驻企业涵盖安全技术、精准营销、云计算、基础设施等多个领域的行业重点企业，初步形成了业态相对丰富、链条较为完备的金融科技发展生态。',
						},
					};
				} else if (pathName == 'feiChiHome') {
					msg = {
						funcName: 'cameraFly',
						param: {
							id: 3,
							title: '海星医药健康创新园B区',
							content: '项目位于海淀区永丰产业基地丰贤中路9号，用地性质为高新技术产业用地，项目用地33433.56m2，总建筑面积68400 m2，地上建筑面积43400 m2，地下建筑面积25000 m2。控制规划指标，控制高度≤24米，总体容积率1.3，地块绿地率35％，项目打造具备生物医药企业所需的GMP生产厂房和研发基地。',
						},
					};
				} else if (pathName == 'jinHui') {
					msg = {
						funcName: 'cameraFly',
						param: {
							id: 4,
							title: '温泉冰雪体育公园项目',
							content: '位于海淀区温泉镇双坡路11号，总占地面积 1100亩。项目以冰雪运动为主，四季兼顾。项目拥有我市离市区最近的室外中大型滑雪场——北京西山滑雪场，雪道面积12万平米，单雪季接待人次超10万，现已成为海淀区冰雪运动新地标。',
						},
					};
				} else if (pathName == 'weixingHome') {
					msg = {
						funcName: 'cameraFly',
						param: {
							id: 6,
							title: '北京卫星制造厂',
							content: '北京卫星制造厂旧址位于北京市海淀区创业大街——知春路，是北京乃至中国科技、教育、文化资源最为丰富的区域。项目以中关村大街、学院路为轴线，是中关村科学城核心区，也是中关村国家自主创新示范区核心区的核心。北京卫星制造厂是我国东方红一号卫星诞生地，也是我国多个卫星、返回舱诞生地，具有丰富的历史背景。',
						},
					};
				} else if (pathName == 'zhongguancunHome') {
					msg = {
						funcName: 'cameraFly',
						param: {
							id: 8,
							title: '中关村国际创新大厦',
							content: '中关村国际创新大厦被海淀区政府定位为前沿科技项目，并由海淀区领导亲自为大厦冠名，入驻企业由海淀区投促局进行甄选，均为新技术产业。项目于2015 年4 月16 日开始入驻并全程跟进大厦整体改造，内配套餐饮、咖啡厅、超市等设施一应俱全。北京万佳鑫物业秉承“为您着想、为您服务、让您满意”的宗旨，努力提升服务品质，为客户营造安全、舒适、高雅的办公环境。',
						},
					};
				} else if (pathName == 'dahang') {
					msg = {
						funcName: 'cameraFly',
						param: {
							id: 9,
							title: '大行基业大厦',
							content: '大行基业大厦是位于北京市海淀区人大北路33号，环境优雅，美观大方，生态良好，邻里和睦，祥和文明，康乐和谐。物业管理完善，贴心人性化，地理环境优越，交通便利，周边配套设施完善，满足生活所需。',
						},
					};
				} else if (pathName == 'haixing') {
					msg = {
						funcName: 'cameraFly',
						param: {
							id: 10,
							title: '海星医药健康创新园C区',
							content: '海星医药健康创新园C区位于北京市海淀区丰贤中路9号',
						},
					};
				} else if (pathName == 'other') {
					msg = {
						funcName: 'cameraFly',
						param: {
							id: 11,
							title: '丹棱SOHO',
							content: '丹棱SOHO（原中冠大厦）位于北京中关村核心区，丹棱街与海淀东三街交口，北邻中关村广场步行街，南朝海淀医院，东接新中关购物中心，西靠北京市海淀区人民法院。地处中关村商务区核心区域。',
						},
					};
				}
				screenWrap.contentWindow.postMessage(msg2, '*');
				screenWrap.contentWindow.postMessage(msg, '*');
			},
			chaneFullType() {
				const screenWrap = document.getElementById('screenWrap');
				let msg = {
					funcName: 'addPolygonRegion',
				};
				screenWrap.contentWindow.postMessage(msg, '*');
			},
		},
		mounted() {
			this.permissionsPage();
		},
		beforeDestory() {},
	};
</script>

<style lang="less">
	.switch-main-screen {
		position: absolute;
		top: 0;
		left: 0;
		width: 168px;
		height: 1080px;

		.switch-item {
			z-index: 2000;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			background-size: 100%;
			background-repeat: no-repeat;
		}

		.switch-item-normal {
			width: 46px;
			height: 238px;
			opacity: 0.5;
			cursor: pointer;
		}

		.switch-item-active {
			width: 84px;
			height: 700px;
			user-select: none;

			.switch-item-active-hide {
				position: absolute;
				top: 50%;
				width: 40px;
				height: 52px;
				transform: translateY(-50%);
				cursor: pointer;
			}

			.switch-item-active-item {
				position: absolute;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				left: 14px;
				cursor: pointer;

				&:active {
					opacity: 0.5;
				}

				.switch-item-icon {
					width: 20px;
					height: 20px;
					margin-bottom: 6px;
					background-size: 100%;
					background-repeat: no-repeat;
				}

				.switch-item-name {
					color: #ffffff;
					writing-mode: vertical-rl;
				}
			}

			.switch-item-active-01 {
				top: 262px;
			}

			.switch-item-active-02 {
				top: 382px;
			}
		}
	}
</style>
