<template>
  <div :class="`wrap_three_btn ${className}`">
    <ul
      :class="{
        wrap_three_btn_list: true,
        map_hide_left:
          !$store.state.showDataValue ||
          $store.state.screenNumber == 4 ||
          !$store.state.mapType,
      }"
    >
      <!-- change_btn_item -->
      <!--  <li :class="{'wrap_three_btn_item': true, 'change_btn_item': item.id == changeId}" 
                v-for="item in list" 
                :key="item.id"
                @click="changeItemFun(item)"
            >
                <div style="height: 28px;">
                    <img :src="item.id == changeId ? item.changeIcon : item.icon" alt="">
                </div>
                <span>{{ item.name }}</span>
            </li> -->
      <!-- <li :class="{ 'wrap_three_btn_item': true, 'change_btn_item': $store.state.mapType }"
                @click="changeMapType">
                <div style="height: 28px;">
                    <img v-if="!$store.state.mapType" src="@/assets/images/yuanqu_icon.png" alt="">
                    <img v-if="$store.state.mapType" src="@/assets/images/chanye_icon.png" alt="">
                </div>
                <span v-if="!$store.state.mapType">园区</span>
                <span v-else>产业</span>
            </li> -->
      <li
        v-if="$store.state.mapType"
        :class="{ wrap_three_btn_item: true, change_btn_item: mapValue }"
        @click="changeMap"
      >
        <div style="height: 28px">
          <img
            v-if="!mapValue"
            src="@/assets/images/earth_no_icon.png"
            alt=""
          />
          <img v-if="mapValue" src="@/assets/images/shuju_icon.png" alt="" />
        </div>
        <span v-if="!mapValue">地图</span>
        <span v-else>数据</span>
      </li>

      <li
        v-if="$store.state.mapType && !isGD"
        :class="{
          wrap_three_btn_item: true,
          change_btn_item: $store.state.goStatus,
        }"
        @click.stop="changeStar"
      >
        <div style="height: 28px">
          <img
            v-if="!$store.state.goStatus"
            src="@/assets/images/start_no_icon.png"
            alt=""
          />
          <img
            v-if="$store.state.goStatus"
            src="@/assets/images/stop_icon.png"
            alt=""
          />
        </div>
        <span v-if="!$store.state.goStatus">开始</span>
        <span v-else>暂停</span>
      </li>

      <!-- <li :class="{'wrap_three_btn_item': true, 'change_btn_item': item.id == changeId}" @click="changeItemFun(item)">
                <div style="height: 28px;">
                    <img src="@/assets/images/start_no_icon.png" alt="">
                </div>
                <span>开始</span>
            </li> -->

      <!--  <li class="wrap_three_btn_item">
                <div style="height: 28px;">
                    <img src="@/assets/images/landmark_no_icon.png" alt="">
                </div>
                <span>漫游</span>
            </li>
            <li class="wrap_three_btn_item">
                <div style="height: 28px;">
                    <img src="@/assets/images/start_no_icon.png" alt="">
                </div>
                <span>开始</span>
            </li> -->
    </ul>
    <ul :class="{ change_roam_list: true, show_roam_list: changeId == 2 }">
      <li
        v-for="item in roamList"
        :key="item.id"
        :class="{
          change_roam_item: true,
          change_now_roam_item: changeRoamItemId == item.id,
        }"
        @click="changeRoamItem(item)"
      >
        <div class="screen_image">
          <img src="@/assets/images/screen_image.jpg" v-if="item.id == 1" />
          <img
            src="@/assets/images/screen_image2.jpeg"
            v-else-if="item.id == 3"
          />
          <img
            src="@/assets/images/screen_image4.jpeg"
            v-else-if="item.id == 2"
          />
          <img src="@/assets/images/screen_image3.png" v-else />
        </div>
        <p :class="{ change_roam_text: changeRoamItemId == item.id }">
          {{ item.name }}
        </p>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    isGD: {
      default: false,
    },
    className: {
      type: String,
      default: "beilun_three_btn_style",
    },
  },
  data() {
    return {
      /* list: [
                {
                    id:1,
                    name: '地图',
                    icon: require('@/assets/images/earth_no_icon.png'),
                    changeIcon: require('@/assets/images/earth_icon.png'),
                },
                {
                    id:2,
                    name: '漫游',
                    icon: require('@/assets/images/landmark_no_icon.png'),
                    changeIcon: require('@/assets/images/landmark_icon.png'),
                },
                {
                    id:3,
                    name: '开始',
                    icon: require('@/assets/images/start_no_icon.png'),
                    changeIcon: require('@/assets/images/start_icon.png'),
                }
            ], */
      roamList: [
        {
          id: 1,
          name: "海星医药健康创新园A区",
        },
        {
          id: 3,
          name: "融汇国际大厦",
        },
        {
          id: 2,
          name: "海星医药健康创新园B区",
        },
        {
          id: 4,
          name: "温泉冰雪体育公园项目",
        },
      ],
      changeId: 0,
      changeRoamItemId: 0,
      setValue: null,
      mapValue: false,
      // starValue: false,
      mapType: false,
      // goNum: 1
    };
  },

  computed: {
    startValue() {
      return this.$store.state.goStatus;
    },
  },

  mounted() {
    window.addEventListener("message", this.dataDelayFun);
  },

  methods: {
    dataDelayFun(value) {
      if (
        value.data.funcName == "dataDelayFun" &&
        value.data.param.delayValue
      ) {
        // console.log('延迟显示数据',value)
        this.$store.commit("changeAnimateDelay", true);
      }
    },
    /* changeItemFun (item) {
            // console.log(item)
            const nowChangeId = this.changeId
            if (item.id == nowChangeId) {
                if (item.id == 1) {
                    this.$store.commit('changeShowFun', true)
                }
                if (item.id == 3) {
                    this.$store.dispatch('automaticGo', false)
                }
                this.changeId = 0
            } else {
                this.changeId = item.id
                if (item.id == 3 && !this.$store.state.loadingOk) {
                    this.$store.dispatch('automaticGo', true)
                }
                if (item.id == 1) {
                    this.$store.commit('changeShowFun', false)
                }
            }
        }, */
    changeMapType() {
      // this.mapType = !this.mapType
      // this.$store.commit('powerArr')
      this.$store.commit("changeMapType");
      // // console.log(this.mapType)
      if (this.$store.state.mapType) {
        this.cameraFly();
      } else {
        this.chaneFullType();
      }
    },
    cameraFly() {
      const pathName = this.$route.name;
      const screenWrap = document.getElementById("screenWrap");
      let msg = {};
      let msg2 = {
        funcName: "resetFull",
      };
      if (
        pathName == "beilun" ||
        pathName == "attract" ||
        pathName == "overView" ||
        pathName == "smartPark"
      ) {
        msg = {
          funcName: "cameraFly",
          param: {
            surround: true,
            id: 5,
            title: "海星医药健康创新园A区",
            content:
              "中关村科学城医药健康产业园，是中关村科学城首个以生物医药产业为核心的产业园,项目总占地面积28702.44平米，总建筑面积 57695.46平米，由18个单体建筑组成，建筑控高为24米，建筑首层层高4.5米，2~4层高6.4米，项目产业定位为生物医药的研发和中试。",
          },
        };
      } else if (pathName == "roHuiHome" || pathName == "roHuiIndustry") {
        msg = {
          funcName: "cameraFly",
          param: {
            surround: true,
            id: 2,
            title: "融汇国际大厦",
            content:
              "大厦位于北京市西北二环核心商区、总建筑面积45000平米，办公区面积234500平米，入驻企业涵盖安全技术、精准营销、云计算、基础设施等多个领域的行业重点企业，初步形成了业态相对丰富、链条较为完备的金融科技发展生态。",
          },
        };
      } else if (pathName == "feiChiHome") {
        msg = {
          funcName: "cameraFly",
          param: {
            surround: true,
            id: 3,
            title: "海星医药健康创新园B区",
            content:
              "项目位于海淀区永丰产业基地丰贤中路9号，用地性质为高新技术产业用地，项目用地33433.56m2，总建筑面积68400 m2，地上建筑面积43400 m2，地下建筑面积25000 m2。控制规划指标，控制高度≤24米，总体容积率1.3，地块绿地率35％，项目打造具备生物医药企业所需的GMP生产厂房和研发基地。",
          },
        };
      } else if (pathName == "jinHui") {
        msg = {
          funcName: "cameraFly",
          param: {
            surround: true,
            id: 4,
            title: "温泉冰雪体育公园项目",
            content:
              "位于海淀区温泉镇双坡路11号，总占地面积 1100亩。项目以冰雪运动为主，四季兼顾。项目拥有我市离市区最近的室外中大型滑雪场——北京西山滑雪场，雪道面积12万平米，单雪季接待人次超10万，现已成为海淀区冰雪运动新地标。",
          },
        };
      } else if (pathName == "weixingHome") {
        msg = {
          funcName: "cameraFly",
          param: {
            surround: true,
            id: 6,
            title: "北京卫星制造厂",
            content:
              "北京卫星制造厂旧址位于北京市海淀区创业大街——知春路，是北京乃至中国科技、教育、文化资源最为丰富的区域。项目以中关村大街、学院路为轴线，是中关村科学城核心区，也是中关村国家自主创新示范区核心区的核心。北京卫星制造厂是我国东方红一号卫星诞生地，也是我国多个卫星、返回舱诞生地，具有丰富的历史背景。",
          },
        };
      } else if (pathName == "zhongguancunHome") {
        msg = {
          funcName: "cameraFly",
          param: {
            surround: true,
            id: 8,
            title: "中关村国际创新大厦",
            content:
              "中关村国际创新大厦被海淀区政府定位为前沿科技项目，并由海淀区领导亲自为大厦冠名，入驻企业由海淀区投促局进行甄选，均为新技术产业。项目于2015 年4 月16 日开始入驻并全程跟进大厦整体改造，内配套餐饮、咖啡厅、超市等设施一应俱全。北京万佳鑫物业秉承“为您着想、为您服务、让您满意”的宗旨，努力提升服务品质，为客户营造安全、舒适、高雅的办公环境。",
          },
        };
      } else if (pathName == "dahang") {
        msg = {
          funcName: "cameraFly",
          param: {
            surround: true,
            id: 9,
            title: "大行基业大厦",
            content:
              "大行基业大厦是位于北京市海淀区人大北路33号，环境优雅，美观大方，生态良好，邻里和睦，祥和文明，康乐和谐。物业管理完善，贴心人性化，地理环境优越，交通便利，周边配套设施完善，满足生活所需。",
          },
        };
      } else if (pathName == "haixing") {
        msg = {
          funcName: "cameraFly",
          param: {
            surround: true,
            id: 10,
            title: "海星医药健康创新园C区",
            content: "海星医药健康创新园C区位于北京市海淀区丰贤中路9号",
          },
        };
      }
      screenWrap.contentWindow.postMessage(msg2, "*");
      setTimeout(() => {
        screenWrap.contentWindow.postMessage(msg, "*");
      }, 3000);
    },

    chaneFullType() {
      const screenWrap = document.getElementById("screenWrap");
      let msg = {
        funcName: "addPolygonRegion",
      };
      screenWrap.contentWindow.postMessage(msg, "*");
    },

    changeMap() {
      const screenWrap = document.getElementById("screenWrap");
      let msg = null;
      this.$store.commit("changeShowFun", this.mapValue);
      this.mapValue = !this.mapValue;
      if (this.mapType) {
        if (!this.mapValue) {
          msg = {
            funcName: "reset",
          };
        } else {
          msg = {
            funcName: "showAllModal",
            param: {
              id: 5,
            },
          };
        }
        screenWrap.contentWindow.postMessage(msg, "*");
      }
    },
    changeStar() {
      // console.log(this.startValue)
      let _startValue = !this.startValue;
      if (_startValue) {
        const screenWrap = document.getElementById("screenWrap");
        const msg = {
          funcName: "showAllModal",
          param: {
            id: 5,
          },
        };
        let msg2 = {
          funcName: "resetFull",
        };
        screenWrap.contentWindow.postMessage(msg2, "*");
        screenWrap.contentWindow.postMessage(msg, "*");
      }
      // this.$store.commit('changeMapTypeOut', true)
      // console.log(this.$store.state.mapType, this.$store.state.showDataValue, this.$store.state.animateDelay)
      this.$store.dispatch("automaticGo", _startValue);
    },
    changeRoamItem(item) {
      this.changeRoamItemId = item.id;
      this.roamPosition(item.id);
    },

    roamPosition(num) {
      const screenWrap = document.getElementById("screenWrap");
      let msg = {};
      if (num == 1) {
        msg = {
          funcName: "cameraFly",
          param: {
            id: 5,
            title: "海星医药健康创新园A区",
            content:
              "中关村科学城医药健康产业园，是中关村科学城首个以生物医药产业为核心的产业园,项目总占地面积28702.44平米，总建筑面积 57695.46平米，由18个单体建筑组成，建筑控高为24米，建筑首层层高4.5米，2~4层高6.4米，项目产业定位为生物医药的研发和中试。",
          },
        };
      } else if (num == 3) {
        msg = {
          funcName: "cameraFly",
          param: {
            id: 2,
            title: "融汇国际大厦",
            content:
              "大厦位于北京市西北二环核心商区、总建筑面积45000平米，办公区面积234500平米，入驻企业涵盖安全技术、精准营销、云计算、基础设施等多个领域的行业重点企业，初步形成了业态相对丰富、链条较为完备的金融科技发展生态。",
          },
        };
      } else if (num == 2) {
        msg = {
          funcName: "cameraFly",
          param: {
            id: 3,
            title: "海星医药健康创新园B区",
            content:
              "项目位于海淀区永丰产业基地丰贤中路9号，用地性质为高新技术产业用地，项目用地33433.56m2，总建筑面积68400 m2，地上建筑面积43400 m2，地下建筑面积25000 m2。控制规划指标，控制高度≤24米，总体容积率1.3，地块绿地率35％，项目打造具备生物医药企业所需的GMP生产厂房和研发基地。",
          },
        };
      } else if (num == 4) {
        msg = {
          funcName: "cameraFly",
          param: {
            id: 4,
            title: "温泉冰雪体育公园项目",
            content:
              "位于海淀区温泉镇双坡路11号，总占地面积 1100亩。项目以冰雪运动为主，四季兼顾。项目拥有我市离市区最近的室外中大型滑雪场——北京西山滑雪场，雪道面积12万平米，单雪季接待人次超10万，现已成为海淀区冰雪运动新地标。",
          },
        };
      }
      screenWrap.contentWindow.postMessage(msg, "*");
    },
  },
};
</script>
<style lang="less">
.wrap_three_btn {
  z-index: 1002;
  position: absolute;
  top: 50%;
  left: 512px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;

  .wrap_three_btn_list {
    transition: all 1s;

    .wrap_three_btn_item {
      width: 54px;
      height: 54px;
      margin-bottom: 15px;
      border-radius: 10px;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      cursor: pointer;
      transition: all 0.5s;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .change_btn_item {
      color: rgba(255, 255, 255, 1);
    }
  }

  .map_hide_left {
    transform: translateX(-450px);
  }

  .change_roam_list {
    width: 120px;
    height: 411px;
    margin-left: 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    transform: rotateY(90deg);
    transition: all 0.5s;

    .change_roam_item {
      /* background: #fff; */
      width: 100%;
      height: 92px;
      cursor: pointer;
      transition: all 0.5s;

      .screen_image {
        width: 110px;
        height: 65px;
        overflow: hidden;
        margin: 0 auto;
        margin-top: 4px;

        img {
          width: 100%;
        }
      }

      p {
        text-align: center;
        color: #fff;
        font-size: 14px;
        transition: all 0.5s;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 8px;
      }

      .change_roam_text {
        font-weight: bold;
      }
    }
  }

  .show_roam_list {
    transform: rotateY(0deg);
  }
}

.beilun_three_btn_style {
  .wrap_three_btn_item {
    background: #182e5b;
  }

  .change_btn_item {
    background: #3b4b81;
    border: 1px solid #67a3ed;
  }

  .change_roam_list {
    .change_roam_item {
      background: #001d3b;
      border: 1px solid #002b57;
    }

    .change_now_roam_item {
      border: 1px solid #88b4f4;
    }
  }
}

.roHui_three_btn_style {
  .wrap_three_btn_item {
    background-color: rgba(50, 34, 19, 0.5);
  }

  .change_btn_item {
    background: #322213;
    position: relative;
    /* border: 1px solid #f68e3f; */
  }

  .change_btn_item::before {
    transition: all 0.5s;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 1px;
    background: linear-gradient(to top, #f68e3f, #fff);
    border-radius: 10px;
    z-index: -1;
  }

  .change_roam_list {
    .change_roam_item {
      background: #18100d;
      border: 1px solid #3c2a24;
    }

    .change_now_roam_item {
      border: 1px solid #fff;
    }
  }
}

.feiChi_three_btn_style {
  .wrap_three_btn_item {
    background-color: rgba(1, 35, 44, 0.5);
  }

  .change_btn_item {
    background: #01232c;
    border: 1px solid #06fadf;
  }

  .change_roam_list {
    .change_roam_item {
      background: #001c24;
      border: 1px solid #023341;
    }

    .change_now_roam_item {
      border: 1px solid #19b5cc;
      position: relative;
    }

    .change_now_roam_item::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 22px;
      background: url(./../assets/images/feichi_Light.png) no-repeat;
      bottom: -12px;
      left: -2px;
    }
  }
}

.zhongguancun_three_btn_style {
  .wrap_three_btn_item {
    background-color: rgba(25, 87, 69, 0.5);
  }

  .change_btn_item {
    background: #195745;
    position: relative;
    /* border: 1px solid #f68e3f; */
  }

  .change_btn_item::before {
    transition: all 0.5s;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 1px;
    background: linear-gradient(to top, #42e5b4, #fff);
    border-radius: 10px;
    z-index: -1;
  }

  .change_roam_list {
    .change_roam_item {
      background: #195745;
      border: 1px solid #22755e;
    }

    .change_now_roam_item {
      border: 1px solid #fff;
    }
  }
}

.weixingHome_three_btn_style {
  .wrap_three_btn_item {
    background-color: rgba(24, 51, 117, 0.5);
  }

  .change_btn_item {
    background: #183375;
    position: relative;
    /* border: 1px solid #f68e3f; */
  }

  .change_btn_item::before {
    transition: all 0.5s;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 1px;
    background: linear-gradient(to top, #135ca8, #fff);
    border-radius: 10px;
    z-index: -1;
  }

  .change_roam_list {
    .change_roam_item {
      background: #183375;
      border: 1px solid #135ca8;
    }

    .change_now_roam_item {
      border: 1px solid #fff;
    }
  }
}

.dahang_three_btn_style {
  .wrap_three_btn_item {
    background: rgba(4, 15, 30, 0.8);
  }

  .change_btn_item {
    background: rgba(4, 15, 30, 1);
    border: 1px solid #4ae5fa;
  }
}

.haixing_three_btn_style {
  .wrap_three_btn_item {
    background: rgba(0, 18, 18, 0.8);
  }

  .change_btn_item {
    background: rgba(0, 18, 18, 1);
    border: 1px solid #19ffff;
  }
}

.other_three_btn_style {
  .wrap_three_btn_item {
    background: rgba(7, 20, 35, 0.8);
  }

  .change_btn_item {
    background: rgba(7, 20, 35, 1);
    border: 1px solid #c7e5fd;
  }
}

.gdds_three_btn_style {
  left: 470px;
  .wrap_three_btn_item {
    background: rgba(4, 15, 30, 0.8);
  }

  .change_btn_item {
    background: rgba(4, 15, 30, 1);
    border: 1px solid #4ae5fa;
  }
}
</style>
