/*
 * @Author: PC
 * @Date: 2022-04-02 15:58:37
 * @LastEditTime: 2022-09-20 13:34:09
 * @Description: 请您输入文件描述
 */
import axios from "axios"; //引用axios
// import qs from 'qs'
// create an axios instance
const service = axios.create({
  baseURL: "https://api-dataview.bjstarfish.com/api/v1/",
  // send cookies when cross-domain requests
  timeout: 160000,
  // request timeout
}); // request interceptor
service.interceptors.request.use(
  (config) => {
    // console.log(config);
    // const _url = config.url
    /* let token = null
    if (_url == 'get_user' || _url == 'logout_fe') {
        token = JSON.parse(localStorage.getItem('code')) || undefined
    } */
    // do something before request is sent
    //
    config.headers["Content-Type"] = "application/json; charset=utf-8";
    config.headers["Accept"] = "application/json";
    // config.data = qs.stringify(config)
    // config.headers['token'] = localStorage.getItem("code") && JSON.parse(localStorage.getItem("code")).token
    return config;
  },
  (error) => {
    // do something with request error
    // console.log(error);
    // for debug
    return Promise.reject(error);
  }
); // response interceptor
service.interceptors.response.use(
  /**  * If you want to get http information such as headers or status  * Please return  response => response  */
  /**  * Determine the request status by custom code  * Here is just an example  * You can also judge the status by HTTP Status Code  */

  (response) => {
    const res = response.data;
    // // console.log(res)
    //res is my own data
    return Promise.resolve(res);
  },
  (error) => {
    // console.log("err" + error); // for debug
    return Promise.reject(error);
  }
);
export default service; //导出封装后的axios
