const zhongguancunIndustry = {
  state: {
    popUpFlag: false,
    popUpCont: {},
    industryActive: '',
    enterpriseEchartsArr: [
      {
        name: '计算机软件',
        value: '10',
      },
      {
        name: '生物/制药/医疗器械',
        value: '2',
      },
      {
        name: '教育/科研/培训',
        value: '2',
      },
      {
        name: '咨询/顾问',
        value: '2',
      },
      {
        name: '金融/银行',
        value: '3',
      },
      {
        name: '汽车/摩托车',
        value: '1',
      },
      {
        name: 'IT服务/系统集成',
        value: '1',
      },
      {
        name: '政府/非盈利机构',
        value: '1',
      },
      {
        name: '电子技术/半导体/集成电路',
        value: '1',
      },
      {
        name: '通信/电信',
        value: '1',
      },
      {
        name: '房地产/物业管理',
        value: '1',
      },

    ],
    businessEchartsArr: [
      {
        value: '1',
        name: '餐饮'
      },
      {
        value: '1',
        name: '咖啡'
      },
      {
        value: '1',
        name: '超市'
      },
    ],
    delegateList: [
      {
        title: '北京本应科技有限公司',
        type: '计算机软件',
        txt: `北京本应科技有限公司是大数据驱动的科技智库和技术创新解决方案提供商。公司是国家高新技术企业、中关村高新技术企业、中关村金种子企业，致力于运用科技大数据，开展科技实时监测、技术预测、产业研究、技术量化风控、技术与企业评价等技术研发和产品创新工作。公司汇集专利、论文、人才、机构、产业等数据，搭建了科技大数据平台，自主研发了“复杂技术网络排名算法”、科学技术知识引擎、产业雷达与创新链智能构建和分析方法、基于 GIS 地理信息的区域科技创新监测与管理系统。`,
      },
      {
        title: '北京超市发连锁股份有限公司',
        type: '批发/零售',
        txt: `北京超市发连锁股份有限公司，是北京市著名的超市连锁企业，前身为北京市海淀区副食品公司。1999年10月公司完成股份制改造，成为北京首家股份制连锁公司，以“超市发”为品牌，主营生鲜日食品、百货、家居用品及代理品牌商品的零售、批发业务。现有直营店74家，经营面积16万平方米，员工7000余人。拥有商品配送基地3万余平方米，近6000平方米的生鲜商品恒温库和低温库。几年来，公司逐步实现经营规模化、物流机械化、管理专业化、流通信息化。销售额呈两位数稳步增长，跃居中国连锁百强企业`,
      },
      {
        title: '北京灵汐科技有限公司',
        type: '电子技术/半导体/集成电路',
        txt: `北京灵汐科技有限公司是一家全球领先的类脑计算技术科技公司。公司产品包括类脑芯片，基于类脑芯片的模组、计算板卡和服务器、配套软件工具链和系统软件。产品可广泛应用于云端和边缘端的AI应用场景，以及脑科学研究。 公司期望携手业内国际标准组织，秉承开源开放、合作共赢的宗旨，与产业伙伴一起促进人工智能产业发展。`,
      },
      {
        title: '北京罗格数据科技有限公司',
        type: '计算机软件',
        txt: `北京罗格数据是国内首家专业的涉税大数据价值服务提供商，是“互联网＋税务大数据”的领跑者。公司致力于企业涉税大数据所承载的信息链、供应链、价值链的挖掘和服务，跨界变现大数据价值。主要的商业模式是以“共生共赢、自洽融合”的方式构建涉税大数据价值变现的生态体系。`,
      },
      {
        title: '北京千乘探索科技有限公司',
        type: '通信/电信',
        txt: `北京千乘探索科技有限公司成立于2017年，公司定位卫星研制及地球空间信息服务商，业务领域涵盖地球空间信息数据应用、卫星及地面配套产品研制与交付、商业卫星测控及星座运营等，主营业务以卫星遥感服务为基础，有机结合低轨卫星星座通信互联、物联服务及北斗导航服务，有效拓展用户信息渠道、增加数据维度、提升服务价值，为用户提供高度定制化的、快速响应的空间信息服务。公司是国家高新技术企业、中关村高新技术企业、中关村雏鹰人才企业、中关村金种子企业、中关村海淀区胚芽企业、中国遥感应用协会理事单位、商务部卫星产业投资合作委员会副主席单位。公司已分别通过GJB9001和ISO9001质量体系认证。`,
      },
      {
        title: '墨奇科技（北京）有限公司',
        type: '计算机软件',
        txt: `墨奇科技是领先的 AI 基础技术和平台公司。自 2016 年创建，始终以“探寻人工智能本身的机理，以创新改变世界”为使命。在这个使命下，墨奇科技致力于构建先进的新型 AI 知识数据库，自动化地处理机器知识，让企业可以便捷的应用人工智能获得数据洞察，加快 AI 时代的智慧产业升级步伐。同时，墨奇基于 AI 知识数据库，构建了能为数十亿人提供保护隐私、安全可靠的下一代身份识别和认证平台，统一物理和数字身份，为建设智慧信用社会提供技术支撑。`,
      },
      {
        title: '北京中关村中技知识产权服务集团有限公司',
        type: '金融/银行',
        txt: `北京中关村中技知识产权服务集团（以下简称“中关村中技集团”）成立于2014年12月14日，由北京市海淀区国有资产投资经营有限公司出资设立，实缴资本4000万元，定位为科技企业投融资综合服务体系的运营管理平台。在国家大力推动科技创新的背景下，针对国内中小微科技型企业普遍具有轻资产、高风险、高成长、高收益的特点，以及在融资过程中经常面临的知识产权评估难、质押难、处置难问题，中关村中技集团创新性地构建了“评—投—保—贷—易”一体化的服务体系，为企业提供了系统性解决方案。`,
      },
      {
        title: '深圳华大基因股份有限公司',
        type: '教育/科研/培训',
        txt: `公司主营业务为通过基因检测、质谱检测、生物信息分析等多组学大数据技术手段，为科研机构、企事业单位、医疗机构、社会卫生组织等提供研究服务和精准医学检测综合解决方案。华大基因秉承“基因科技造福人类”的愿景，以推动生命科学研究进展、生命大数据应用和提高全球医疗健康水平为出发点，基于基因领域研究成果及精准检测技术在民生健康方面的应用，致力于加速科技创新，减少出生缺陷，加强肿瘤防控，抑制重大疾病对人类的危害，实现精准治愈感染，全面助力精准医学。`,
      },
      {
        title: '印湘中广（北京）餐饮管理有限公司',
        type: '娱乐休闲/餐饮/服务',
        txt: `印湘中广（北京）餐饮管理有限公司坚持创变求新，不断沉淀企业底蕴，以精益求精的态度赢得客户的信赖，公司于2021年2月4日在美丽的北京-海淀区正式成立，主要业务有软件开发、应用软件服务、企业管理、餐饮管理、企业管理咨询、餐饮服务，印湘中广（北京）餐饮管理有限公司全体员工期待用自己真诚的服务、扎实的技术努力为用户提供解决方案，实现和客户的全面双赢`,
      },
      {
        title: '北京小鹏汽车有限公司',
        type: '汽车/摩托车',
        txt: `小鹏汽车成立于2014年，是一家专注未来出行的科技公司。我们一直坚持饱和式研发投入，构建全栈自研的核心能力，今天小鹏汽车已经成为中国领先的智能电动汽车公司之一。小鹏汽车的使命是，用科技为人类创造更便捷愉悦的出行生活。`,
      },
    ],
    settleArr: [
      {
        key: 'company_num',
        title: '入驻企业数',
        color: '#4ea682',
        cover: require('@/assets/images/zhongguancunIndustryIcon1.png'),
        num: 0,
        company: '家'
      },
      {
        key: 'top_num',
        title: '上市企业数',
        color: '#2c6fc5',
        cover: require('@/assets/images/zhongguancunIndustryIcon2.png'),
        num: 0,
        company: '家'
      },
      {
        key: 'high_num',
        title: '国高新企业数',
        color: '#b6732c',
        cover: require('@/assets/images/zhongguancunIndustryIcon3.png'),
        num: 0,
        company: '家'
      },
      {
        key: 'in_num',
        title: '入驻商家数',
        color: '#25a1bd',
        cover: require('@/assets/images/zhongguancunIndustryIcon4.png'),
        num: 0,
        company: '家'
      },
      {
        key: 'employee_num',
        title: '企业员工数',
        color: '#51d2d1',
        cover: require('@/assets/images/zhongguancunIndustryIcon5.png'),
        num: 0,
        company: '人'
      },
      {
        key: 'tech_num',
        title: '人才数',
        color: '#5965c6',
        cover: require('@/assets/images/zhongguancunIndustryIcon6.png'),
        num: 0,
        company: '人'
      },
      {
        key: 'new_right_num',
        title: '企业创新知识产权',
        color: '#c69c3a',
        cover: require('@/assets/images/zhongguancunIndustryIcon7.png'),
        num: 0,
        company: '项'
      },
      {
        key: 'right_num',
        title: '企业知识产权',
        color: '#ce5260',
        cover: require('@/assets/images/zhongguancunIndustryIcon8.png'),
        num: 0,
        company: '项'
      },
    ]
  },
  mutations: {
    industryDetail(state, payload) {
      if (state.popUpFlag) {
        state.popUpFlag = false
        state.industryActive = -1
        setTimeout(() => {
          state.popUpFlag = true
          state.popUpCont = payload.item
          state.industryActive = payload.index
        }, 200);
        return
      }
      state.popUpFlag = true
      state.popUpCont = payload.item
      state.industryActive = payload.index
    },
    industryClosePop(state) {
      state.popUpFlag = false
      state.industryActive = -1
    }
  },
}
export default zhongguancunIndustry