const zhongguancunAttract = {
  state: {
    lease: [
      {
        area: 42154.54,
        ratio: 0,
        YesLeased: 28921.97,
        noLeased: 13232.57
      },
      {
        area: 2966.22,
        ratio: 0,
        YesLeased: 926.04,
        noLeased: 2040.18
      },
      {
        area: 0,
        ratio: 0,
        YesLeased: 0,
        noLeased: 0
      },
    ],
    notHireArr: [
      {
        num: 1,
        cont: [
          {
            room: '1-7',
            area: 408.72,
          },
          {
            room: '1-5',
            area: 341.07,
          },
          {
            room: '1-3',
            area: 141.89,
          }
        ]
      },
      {
        num: 3,
        cont: [
          {
            room: '3-8',
            area: 460.05,
          },
          {
            room: '3-7',
            area: 295.73,
          },
          {
            room: '3-6',
            area: 309.82,
          },
          {
            room: '3-2',
            area: 309.54,
          }
        ]
      },
      {
        num: 7,
        cont: [
          {
            room: '7-7',
            area: 295.73,
          }
        ]
      },
      {
        num: 9,
        cont: [
          {
            room: '9-8',
            area: 460.05,
          },
          {
            room: '9-7',
            area: 295.73,
          },
          {
            room: '9-6',
            area: 452.57,
          },
          {
            room: '9-1',
            area: 424.41,
          }
        ]
      },
    ],
    alreadyHireArr: [
      {
        num: 1,
        company: [
          {
            name: '北京超市发连锁股份有限公司',
            area: 247.92,
            time: '2030/1/31'
          },
          {
            name: '印湘中广（北京）餐饮管理有限公司',
            area: 458.85,
            time: '2026/2/28'
          },
          {
            name: '北京瑞吉咖啡有限公司',
            area: 77.38,
            time: '2024/12/24'
          },
        ]
      },
      {
        num: 2,
        company: [
          {
            name: '北京有竹居网络技术有限公司',
            area: 2850.52,
            time: '2023/6/19'
          },
        ]
      },
      {
        num: 3,
        company: [
          {
            name: '北京罗格数据科技有限公司',
            area: 430.75,
            time: '2022/5/31'
          },
        ]
      },
      {
        num: 4,
        company: [
          {
            name: '北京有竹居网络技术有限公司',
            area: 3263.82,
            time: '-'
          },
        ]
      },
      {
        num: 5,
        company: [
          {
            name: '北京罗格数据科技有限公司',
            area: 3273.09,
            time: '-'
          },
        ]
      },
      {
        num: 6,
        company: [
          {
            name: '北京千乘探索科技有限公司',
            area: 424.41,
            time: '2023/5/31'
          },
          {
            name: '北京灵汐科技有限公司',
            area: 748.17,
            time: '2023/5/15'
          },
          {
            name: '北京小冰红棉科技有限公司',
            area: 892.16,
            time: '2022/5/31'
          },
          {
            name: '北京红棉小冰科技有限公司',
            area: 912.62,
            time: '2022/5/31'
          },
        ]
      },
      {
        num: 7,
        company: [
          {
            name: '北京小冰红棉科技有限公司',
            area: 884.46,
            time: '2022-12-31'
          },
          {
            name: '北京华海恒泰投资管理有限公司',
            area: 467.77,
            time: '2023-3-31'
          },
          {
            name: '北京小冰红棉科技有限公司',
            area: 452.57,
            time: '2022-5-31'
          },
          {
            name: '墨奇科技（北京）有限公司',
            area: 424.39,
            time: '2023-05-31'
          },
        ]
      },
      {
        num: 8,
        company: [
          {
            name: '北京灵汐科技有限公司',
            area: 1632.76,
            time: '2023-5-15'
          },
          {
            name: '墨奇科技（北京）有限公司',
            area: 1640.33,
            time: '2023-05-31'
          }
        ]
      },
      {
        num: 8,
        company: [
          {
            name: '国华未来科技(北京)有限公司',
            area: 452.13,
            time: '2022-3-31'
          },
          {
            name: '北京市海淀区智识前沿科技促进中心',
            area: 296.04,
            time: '2023-8-14'
          },
          {
            name: '北京小冰红棉科技有限公司',
            area: 467.77,
            time: '2022-5-31'
          },
          {
            name: '墨奇科技（北京）有限公司',
            area: 424.39,
            time: '2022-10-31'
          }
        ]
      },
      {
        num: 9,
        company: [
          {
            name: '国华未来科技(北京)有限公司',
            area: 452.13,
            time: '2022-3-31'
          },
          {
            name: '北京市海淀区智识前沿科技促进中心',
            area: 296.04,
            time: '2023-8-14'
          },
          {
            name: '北京小冰红棉科技有限公司',
            area: 467.77,
            time: '2022-5-31'
          },
          {
            name: '墨奇科技（北京）有限公司',
            area: 424.39,
            time: '2022-10-31'
          }
        ]
      },
      {
        num: 10,
        company: [
          {
            name: '中路智链科技集团有限公司',
            area: 424.41,
            time: '2022-9-30'
          },
          {
            name: '北京慧堂国际教育科技有限公司',
            area: 452.13,
            time: '2022-12-31'
          },
          {
            name: '北京小鹏汽车有限公司',
            area: 763.81,
            time: '2024-3-31'
          },
          {
            name: '志诺维思（北京）基因科技有限公司',
            area: 452.57,
            time: '2022-9-30'
          }
        ]
      },
      {
        num: 11,
        company: [
          {
            name: '北京北大软件工程股份有限公司',
            area: 3273.09,
            time: '2023/1/14'
          }
        ]
      },
      {
        num: 12,
        company: [
          {
            name: '北京中关村中技知识产权服务集团有限公司',
            area: 1891.2,
            time: '2022-12-31'
          },
          {
            name: '北京本应科技有限公司',
            area: 341.75,
            time: '2022-12-31'
          },
          {
            name: '北京中技知识产权融资担保有限公司',
            area: 758.97,
            time: '2023-4-15'
          },
          {
            name: '北京中技商业保理有限公司',
            area: 140.59,
            time: '2022-12-31'
          },
          {
            name: '北京中技科融小额贷款有限公司',
            area: 140.59,
            time: '2022-12-31'
          }
        ]
      },
      {
        num: 15,
        company: [
          {
            name: '北京赛目科技有限公司',
            area: 1172.58,
            time: '2023-9-30'
          },
          {
            name: '深圳华大基因股份有限公司',
            area: 1380.39,
            time: '2025-3-31'
          },
          {
            name: '深圳华大智造销售有限公司',
            area: 424.39,
            time: '2025-3-31'
          },
          {
            name: '深圳市华大智造软件软件技术有限公司北京分公司',
            area: 295.73,
            time: '2025-3-31'
          }
        ]
      },
      {
        num: 16,
        company: [
          {
            name: '北京小鹏汽车有限公司',
            area: 3273.09,
            time: '2024/3/31'
          }
        ]
      },

    ]
  }
}
export default zhongguancunAttract