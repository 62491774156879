<template>
    <div class="home_left_content">
        <div class="home_left_wrapBg">
        </div>
        <div class="home_left_wrap">
          <Introduce />
          <Medical />
          <Plan />
        </div>
    </div>
</template>
<script>
    import '@/assets/css/home/home.css'
    import Introduce from '@/components/homeLeftCont/introduce.vue'
    import Medical from '@/components/homeLeftCont/medical.vue'
    import Plan from '@/components/homeLeftCont/plan.vue'
    export default {
      components: {
        Introduce,
        Medical,
        Plan
      }
    }
</script>
<style lang="less" scoped>
  
</style>