import service from '@/utils/http'

/* 贝伦数据请求 */

// 园区概况
export function getYuanqu() {
    return service({
        url: 'beilun_yuanqu',
        method: 'get'
    })
}

// 企业情况
export function getCompanyProFile() {
    return service({
        url: 'beilun_company_profile',
        method: 'get'
    })
}


// 最近七天人员进出情况
export function getPersonnel() {
    return service({
        url: 'beilun_personnel_access',
        method: 'get'
    })
}

// 最近七天车辆进出情况
export function getCarAccess() {
    return service({
        url: 'beilun_car_access',
        method: 'get'
    })
}

// 最近七天饭堂消费情况
export function getCanteen() {
    return service({
        url: 'beilun_canteen_access',
        method: 'get'
    })
}


// 园区概况
export function getProject() {
    return service({
        url: 'beilun_project_overview',
        method: 'get'
    })
}

// 园区整体运营情况
export function getWhole() {
    return service({
        url: 'beilun_the_whole_operation',
        method: 'get'
    })
}

// 车位情况
export function getParking() {
    return service({
        url: 'beilun_parking_situation',
        method: 'get'
    })
}


// 配套设施
export function getSupporting() {
    return service({
        url: 'beilun_supporting_measure',
        method: 'get'
    })
}

// 医疗健康产业图谱
export function getMedicalMap() {
    return service({
        url: 'beilun_medical_map',
        method: 'get'
    })
}

// 企业概况，企业评价
export function getComRepres(params) {
    return service({
        url: 'company_list_fe',
        method: 'get',
        params
    })
}


// 产业园区企业概况
export function getCompany() {
    return service({
        url: 'beilun_company',
        method: 'get'
    })
}

// 园区企业融资
export function getCorporate() {
    return service({
        url: 'beilun_corporate_finance',
        method: 'get'
    })
}


// 企业代表
export function getAllCompany() {
    return service({
        url: 'beilun_all_company',
        method: 'get'
    })
}

// 13号楼
export function getTheFloor() {
    return service({
        url: 'beilun_the_floor',
        method: 'get'
    })
}

// 飞驰
export function getProjectOverview() {
    return service({
        url: 'feichi_project_overview',
        method: 'get'
    })
}

export function getUserData(params) {
    return service({
        url: 'login_with_code_fe',
        method: 'get',
        params: params
    })
}



/* ========================== */