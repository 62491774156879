<template>
	<div class="screen_header_model">
		<!-- <span style="color: #fff;">{{ $store.state.screenNumber }}</span> -->
		<!-- {{$store.state.mapType}} -->
		<div class="screen_header" :class="[{ beilunTopBg: $store.state.screenNumber == 2 }]" v-if="$store.state.screenNumber == 2">
			<ul class="header_menu_list">
				<li
					v-for="item in headerMenu"
					:key="item.id"
					:class="{
						header_menu_item: true,
						change_menu_item: item.id == changeItem,
						change_three: changeItem == 3,
						change_two: changeItem == 2,
						change_one: changeItem == 1,
						change_four: changeItem == 4,
					}"
					:style="{ visibility: item.id == 4 ? 'hidden' : 'unset' }"
					@click="switchItem(item)"
				>
					{{ item.name }}
				</li>
			</ul>
			<h1>海星医药健康创新园A区</h1>
			<span class="header_weather">
				{{ timeValue }} {{ weatherValue.weather }} {{ weatherValue.temperature }}℃
				<div class="sign_out_model" @click="arrountOut">
					<img src="@/assets/images/sign_out_icon.png" alt="" />
					<span>退出</span>
				</div>
				<div class="sign_out_model" style="min-width: 110px; display: flex; justify-content: flex-end" @click="goScreenfull">
					<img v-if="!screenfullValue" style="width: 24px" src="@/assets/images/beilun_full_icon.png" alt="" />
					<img v-else style="width: 24px" src="@/assets/images/beilun_out_full_icon.png" alt="" />
					<span>{{ !screenfullValue ? '全屏' : '退出全屏' }}</span>
				</div>
			</span>
		</div>
		<div class="screen_header rohui_screen_header" v-if="$store.state.screenNumber == 1">
			<ul class="rohui_header_menu_list">
				<li
					v-for="item in rohuiMenu"
					:key="item.id"
					:class="{
						rohui_header_menu_item: true,
						rohui_change_item: rohuichangeId == item.id,
					}"
					@click="roHuiSwitch(item)"
				>
					{{ item.name }}
				</li>
			</ul>
			<h1>融汇国际大厦</h1>
			<span class="rohui_header_weather">
				{{ timeValue }} {{ weatherValue.weather }} {{ weatherValue.temperature }}℃
				<div class="sign_out_model" @click="arrountOut">
					<img src="@/assets/images/sign_out_icon3.png" alt="" />
					<span>退出</span>
				</div>
				<div class="sign_out_model" style="min-width: 110px; display: flex; justify-content: flex-end" @click="goScreenfull">
					<img v-if="!screenfullValue" style="width: 24px" src="@/assets/images/rohui_full_icon.png" alt="" />
					<img v-else style="width: 24px" src="@/assets/images/rohui_out_full_icon.png" alt="" />
					<span>{{ !screenfullValue ? '全屏' : '退出全屏' }}</span>
				</div>
			</span>
		</div>

		<div class="screen_header feichi_screen_header" v-if="$store.state.screenNumber == 3">
			<!-- <span class="rohui_header_weather">晴 -1°C~5°C</span> -->
			<div class="weather_now_model">
				<div class="weather_icon_box">
					<img src="@/assets/images/weather_icon.png" alt="" />
				</div>
				<span style="display: inline-block; margin-right: 26px; margin-left: 14px">{{ weatherValue.weather }}</span>
				<span>{{ weatherValue.temperature }}℃</span>
			</div>
			<h1>海星医药健康创新园B区</h1>
			<span class="feichi_week_text">
				<!-- 2021年02月01日 星期三 08:45:12 -->
				{{ timeValue }}
				<div class="sign_out_model" @click="arrountOut">
					<img src="@/assets/images/sign_out_icon2.png" alt="" />
					<span>退出</span>
				</div>
				<div class="sign_out_model" style="min-width: 110px; display: flex; justify-content: flex-end" @click="goScreenfull">
					<img v-if="!screenfullValue" src="@/assets/images/feici_full_icon.png" alt="" />
					<img v-else src="@/assets/images/feici_out_full_icon.png" alt="" />
					<span>{{ !screenfullValue ? '全屏' : '退出全屏' }}</span>
				</div>
			</span>
		</div>
		<div class="screen_header zhongguancun_screen_header" v-if="$store.state.screenNumber == 5">
			<div class="zhongguancun_header_menu_box zhongguancun_header_left">
				<ul class="zhongguancun_header_menu_list">
					<li
						v-for="item in zhongguancunMenu"
						:key="item.id"
						:class="{
							zhongguancun_header_menu_item: true,
							zhongguancun_change_item: zhongguancunchangeId == item.id,
						}"
						@click="zhongguancunSwitch(item)"
					>
						{{ item.name }}
					</li>
				</ul>
			</div>
			<h1>中关村国际创新大厦</h1>
			<div class="zhongguancun_header_menu_box zhongguancun_header_right">
				<span class="zhongguancun_header_weather">
					{{ timeValue }} {{ weatherValue.weather }} {{ weatherValue.temperature }}℃
					<!-- <div class="sign_out_model" @click="arrountOut">
                      <img src="@/assets/images/sign_out_icon2.png" alt="" />
                      <span>退出</span>
                  </div>
                 <div class="sign_out_model" style="min-width: 110px;display: flex;justify-content: flex-end;" @click="goScreenfull">
                      <img v-if="!screenfullValue" src="@/assets/images/feici_full_icon.png" alt="">
                      <img v-else src="@/assets/images/feici_out_full_icon.png" alt="">
                      <span>{{ !screenfullValue ? '全屏' : '退出全屏' }}</span>
                  </div> -->
				</span>
				<div class="zhongguancun_btn_box sign_out_model" @click="arrountOut">
					<div class="sign_out_model" style="display: flex; justify-content: flex-end" @click="goScreenfull">
						<img v-if="!screenfullValue" style="width: 24px; height: 24px" src="@/assets/images/zhongguancun_full_icon.png" alt="" />
						<img v-else style="width: 24px; height: 24px" src="@/assets/images/zhongguancun_out_full_icon.png" alt="" />
						<span>{{ !screenfullValue ? '全屏' : '退出全屏' }}</span>
					</div>
					<img style="width: 24px; height: 24px" src="@/assets/images/sign_out_icon5.png" alt="" />
					<span>退出</span>
				</div>
			</div>
		</div>
		<div class="screen_header weixing_screen_header" v-if="$store.state.screenNumber == 6">
			<div></div>
			<h1>北京卫星制造厂</h1>
			<span class="weixing_header_weather">
				{{ timeValue }} {{ weatherValue.weather }} {{ weatherValue.temperature }}℃
				<div class="sign_out_model" @click="arrountOut">
					<img src="@/assets/images/sign_out_icon6.png" alt="" />
					<span>退出</span>
				</div>
				<div class="sign_out_model" style="display: flex; justify-content: flex-end" @click="goScreenfull">
					<img v-if="!screenfullValue" src="@/assets/images/weixing_full_icon.png" alt="" />
					<img v-else src="@/assets/images/weixing_out_full_icon.png" alt="" />
					<span>{{ !screenfullValue ? '全屏' : '退出全屏' }}</span>
				</div>
			</span>
		</div>
		<div class="screen_header dahang_screen_header" v-if="$store.state.screenNumber == 7">
			<h1>大行基业大厦</h1>
			<span class="dahang_header_weather">
				{{ timeValue }} {{ weatherValue.weather }} {{ weatherValue.temperature }}℃
				<div class="sign_out_model" @click="arrountOut">
					<img src="@/assets/images/sign_out_icon.png" alt="" />
					<span>退出</span>
				</div>
				<div class="sign_out_model" style="display: flex; justify-content: flex-end" @click="goScreenfull">
					<img v-if="!screenfullValue" src="@/assets/images/beilun_full_icon.png" alt="" />
					<img v-else src="@/assets/images/beilun_out_full_icon.png" alt="" />
					<span>{{ !screenfullValue ? '全屏' : '退出全屏' }}</span>
				</div>
			</span>
		</div>
		<div class="screen_header haixing_screen_header" v-if="$store.state.screenNumber == 8">
			<h1>海星医药健康创新园C区</h1>
			<span class="haixing_header_weather">
				{{ timeValue }} {{ weatherValue.weather }} {{ weatherValue.temperature }}℃
				<div class="sign_out_model" @click="arrountOut">
					<img src="@/assets/images/sign_out_icon5.png" alt="" />
					<span>退出</span>
				</div>
				<div class="sign_out_model" style="display: flex; justify-content: flex-end" @click="goScreenfull">
					<img v-if="!screenfullValue" src="@/assets/images/zhongguancun_full_icon.png" alt="" />
					<img v-else src="@/assets/images/zhongguancun_out_full_icon.png" alt="" />
					<span>{{ !screenfullValue ? '全屏' : '退出全屏' }}</span>
				</div>
			</span>
		</div>
		<div class="screen_header other_screen_header" v-if="$store.state.screenNumber == 9">
			<h1>其他资产</h1>
			<span class="other_header_weather">
				{{ timeValue }} {{ weatherValue.weather }} {{ weatherValue.temperature }}℃
				<div class="sign_out_model" @click="arrountOut">
					<img src="@/assets/images/sign_out_icon.png" alt="" />
					<span>退出</span>
				</div>
				<div class="sign_out_model" style="display: flex; justify-content: flex-end" @click="goScreenfull">
					<img v-if="!screenfullValue" src="@/assets/images/beilun_full_icon.png" alt="" />
					<img v-else src="@/assets/images/beilun_out_full_icon.png" alt="" />
					<span>{{ !screenfullValue ? '全屏' : '退出全屏' }}</span>
				</div>
			</span>
		</div>
		<div class="screen_header gdds_screen_header" v-if="$store.state.screenNumber == 10">
			<h1>北京高德大厦</h1>
			<span class="gdds_header_time"> {{ timeValue }} {{ weatherValue.weather }} {{ weatherValue.temperature }}℃ </span>
			<div class="gdds_header_menu">
				<div class="sign_out_model" @click="arrountOut">
					<img src="@/assets/images/sign_out_icon.png" alt="" />
					<span>退出</span>
				</div>
				<div class="sign_out_model" style="display: flex; justify-content: flex-end" @click="goScreenfull">
					<img v-if="!screenfullValue" src="@/assets/images/beilun_full_icon.png" alt="" />
					<img v-else src="@/assets/images/beilun_out_full_icon.png" alt="" />
					<span>{{ !screenfullValue ? '全屏' : '退出全屏' }}</span>
				</div>
			</div>
		</div>
		<div class="screen_header xyds_screen_header" v-if="$store.state.screenNumber == 11">
			<div class="xyds_screen_header_shadow"></div>
			<h1>四季青科技园·馨雅大厦</h1>
			<span class="xyds_header_weather">
				{{ timeValue }} {{ weatherValue.weather }} {{ weatherValue.temperature }}℃
				<div class="sign_out_model" @click="arrountOut">
					<img src="@/assets/images/sign_out_icon6.png" alt="" />
					<span>退出</span>
				</div>
				<div class="sign_out_model" style="display: flex; justify-content: flex-end" @click="goScreenfull">
					<img v-if="!screenfullValue" src="@/assets/images/weixing_full_icon.png" alt="" />
					<img v-else src="@/assets/images/weixing_out_full_icon.png" alt="" />
					<span>{{ !screenfullValue ? '全屏' : '退出全屏' }}</span>
				</div>
			</span>
		</div>
	</div>
</template>
<script>
	import '@/utils/gaodeWeather.js';
	import moment from 'moment';
	import screenfull from 'screenfull';
	import 'moment/locale/zh-cn';
	import { logout } from '@/api/login';
	moment.locale();
	export default {
		data() {
			return {
				headerMenu: [
					{
						id: 1,
						name: '园区总览',
						pathName: 'beilun',
					},
					{
						id: 2,
						name: '园区运营',
						pathName: 'attract',
					},
					{
						id: 3,
						name: '园区产业',
						pathName: 'overView',
					},
					{
						id: 4,
						name: '智慧园区',
						pathName: 'smartPark',
					},
				],
				rohuiMenu: [
					{
						id: 1,
						name: '园区招商',
						pathName: 'roHuiHome',
					},
					{
						id: 2,
						name: '园区产业',
						pathName: 'roHuiIndustry',
					},
				],
				zhongguancunMenu: [
					{
						id: 1,
						name: '园区总览',
						pathName: 'zhongguancunHome',
					},
					{
						id: 2,
						name: '园区招商',
						pathName: 'zhongguancunAttract',
					},
					{
						id: 3,
						name: '园区产业',
						pathName: 'zhongguancunIndustry',
					},
				],
				changeItem: 3,
				rohuichangeId: 2,
				zhongguancunchangeId: 1,
				timeValue: '',
				setTimeValue: '',
				weatherValue: {},
				screenfullValue: false,
			};
		},
		mounted() {
			const _pathName = this.$route.name;
			const _this = this;
			this.headerMenu.map(item => {
				if (_pathName == item.pathName) {
					// console.log(item)
					this.changeItem = item.id;
				}
			});
			this.rohuiMenu.map(item => {
				if (_pathName == item.pathName) {
					// console.log(item)
					this.rohuichangeId = item.id;
				}
			});
			this.timeValue = moment().format('YYYY年MM月DD日 dddd HH:mm:ss');
			this.setTimeValue = setInterval(() => {
				// // console.log(111222)
				this.timeValue = moment().format('YYYY年MM月DD日 dddd HH:mm:ss');
			}, 1000);

			// console.log(window)
			// 获取高德天气实时
			window.AMap.plugin('AMap.Weather', () => {
				//创建天气查询实例
				let weather = new window.AMap.Weather();
				//执行实时天气信息查询
				const requireWeather = () => {
					weather.getLive('北京', function (err, data) {
						// console.log("天气", data);
						_this.weatherValue = data;
					});
				};
				requireWeather();
			});
		},
		watch: {
			$route: {
				handler: function (route) {
					this.headerMenu.map(item => {
						if (route.name == item.pathName) {
							// console.log(item)
							this.changeItem = item.id;
						}
					});
					this.rohuiMenu.map(item => {
						if (route.name == item.pathName) {
							// console.log(item)
							this.rohuichangeId = item.id;
						}
					});
					this.zhongguancunMenu.map(item => {
						if (route.name == item.pathName) {
							// console.log(item)
							this.zhongguancunchangeId = item.id;
						}
					});
				},
				immediate: true,
			},
		},
		methods: {
			moment,
			goScreenfull() {
				this.screenfullValue = !this.screenfullValue;
				if (this.screenfullValue) {
					screenfull.toggle();
				} else {
					screenfull.exit();
				}
			},
			switchItem(item) {
				//  var test = ''
				this.changeItem = item.id;
				this.$router.push({
					name: item.pathName,
				});
			},
			roHuiSwitch(item) {
				this.rohuichangeId = item.id;
				this.$router.push({
					name: item.pathName,
				});
			},
			zhongguancunSwitch(item) {
				this.zhongguancunchangeId = item.id;
				this.$router.push({
					name: item.pathName,
				});
			},
			arrountOut() {
				// console.log('退出登录')
				const codeValue = JSON.parse(localStorage.getItem('code'));
				if (codeValue.token) {
					logout({
						token: codeValue.token,
					}).then(() => {
						localStorage.removeItem('code');
						this.$store.commit('getPower');
						this.$store.commit('changeShowMapValue', false);
						this.$store.commit('changeLoadingStatus', true);
						this.$router.push('login');
						this.$store.mapType = false;
						this.$message.success('退出成功', 1);
					});
				} else {
					localStorage.removeItem('code');
					this.$store.commit('getPower');
					this.$store.commit('changeShowMapValue', false);
					this.$store.commit('changeLoadingStatus', true);
					this.$router.push('login');
					this.$store.mapType = false;
					this.$message.success('退出成功', 1);
				}
				this.$store.state.mapType = false;
			},
		},
	};
</script>
<style lang="less">
	.beilunTopBg {
		background: url('./../assets/images/borderTop.png') no-repeat;

		h1 {
			background: linear-gradient(180deg, #e7ffff 0%, #2463cc 100%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}

	// .beilunTopBg{}
	.screen_header {
		width: 100%;
		position: absolute;
		z-index: 1002;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		padding-left: 98px;
		padding-bottom: 45px;
		box-sizing: border-box;

		h1 {
			display: inline-block;
			font-size: 40px;
			font-family: PingFang SC;
			font-weight: bold;
			color: #e4eaff;

			margin-top: 6px;
			margin-right: 60px;
			margin-left: 60px;
			/* margin-right: 194px; */
		}

		.header_weather {
			color: #fff;
			font-size: 18px;
			transform: rotate(-3deg);
			margin-bottom: 10px;
		}

		.sign_out_model {
			float: right;
			display: flex;
			margin-left: 20px;
			cursor: pointer;

			img {
				margin-right: 10px;
			}
		}

		.header_menu_list {
			width: 392px;
			display: flex;
			color: #fff;
			justify-content: space-between;
			transform: rotate(3deg);
			margin-bottom: -10px;
		}

		.header_menu_list .header_menu_item {
			position: relative;
			padding: 0 4px;
			cursor: pointer;
			transition: all 0.5s;
		}

		.change_menu_item {
			font-size: 22px;
			font-weight: 500;
		}

		.header_menu_list .change_menu_item::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 2px;
			background: #8ebffd;
			left: 0;
		}

		.change_three::before {
			bottom: -10px;
			transform: rotate(0deg);
		}

		.change_two::before {
			bottom: -12px;
			transform: rotate(1deg);
		}

		.change_one::before {
			bottom: -8px;
			transform: rotate(1deg);
		}

		.change_four::before {
			bottom: -8px;
			transform: rotate(-1deg);
		}
	}

	.rohui_screen_header {
		h1 {
			display: inline-block;
			font-size: 40px;
			font-family: PingFang SC;
			font-weight: bold;
			color: #e4eaff;
			background: linear-gradient(180deg, #e7ffff 0%, #c4ae94 100%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			margin-top: 6px;
			margin-right: 218px;
			margin-left: 428px;
		}

		.rohui_header_weather {
			color: #fff;
			font-size: 18px;
			float: right;
			margin-bottom: 16px;
		}

		.rohui_header_menu_list {
			width: 276px;
			display: flex;
			color: #fff;
			justify-content: space-between;
			margin-bottom: 16px;
			align-items: center;

			.rohui_header_menu_item {
				width: 138px;
				text-align: center;
				position: relative;
				z-index: 1;
				transition: all 0.5s;
				cursor: pointer;
			}

			.rohui_header_menu_item::after {
				content: '';
				transition: all 0.5s;
				position: absolute;
				bottom: -2px;
				width: 0;
				opacity: 0.1;
				height: 13px;
				left: 0;
				z-index: -1;
				background: #3b1d00;
				transform: skewX(-30deg);
			}

			.rohui_header_menu_item::before {
				content: '';
				transition: all 0.5s;
				position: absolute;
				bottom: -2px;
				width: 0;
				opacity: 0.1;
				height: 2px;
				left: -3px;
				z-index: 0;
				transform: skewX(-42deg);
				background: linear-gradient(to right, #dfb583 0, #402204 62%);
			}

			.rohui_change_item {
				font-size: 22px;
				font-weight: 500;
			}

			.rohui_change_item::after {
				width: 100%;
				opacity: 1;
			}

			.rohui_change_item::before {
				width: 100%;
				opacity: 1;
			}
		}
	}

	.feichi_screen_header {
		h1 {
			display: inline-block;
			font-size: 40px;
			font-family: PingFang SC;
			font-weight: bold;
			color: #e4eaff;
			background: #bbfcf9;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			margin-top: 6px;
			margin-right: 183px;
			margin-left: 442px;
		}

		.feichi_week_text {
			float: right;
			color: #c3f9f9;
			font-size: 18px;
			margin-bottom: 15px;
		}

		.weather_now_model {
			margin-bottom: 15px;
			display: flex;
			color: #c3f9f9;
			align-items: center;

			.weather_icon_box {
				width: 24px;
				height: 24px;

				img {
					width: 100%;
				}
			}
		}
	}

	.zhongguancun_screen_header {
		color: #fff;
		align-items: center;
		padding-left: 0;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 70%;
			background: rgba(0, 0, 0, 0.5);
			z-index: -1;
		}

		.zhongguancun_header_menu_list .zhongguancun_change_item {
			font-size: 22px;
			position: relative;

			&::before {
				content: '';
				position: absolute;
				bottom: 0;
				left: 10%;
				width: 80%;
				height: 2px;
				background-color: #44eab8;
			}

			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 42%;
				width: 0;
				height: 0;
				border-left: 8px solid transparent;
				border-right: 8px solid transparent;
				border-bottom: 8px solid #44eab8;
			}
		}

		.zhongguancun_header_left {
			background: url(../assets/images/zhongguancunHeadLeftBg.png);
		}

		.zhongguancun_header_right {
			display: flex;
			justify-content: flex-end;
			background: url(../assets/images/zhongguancunHeadRightBg.png);
		}

		.zhongguancun_header_menu_box {
			width: 666px;
			background-size: 100% 100%;
			background-repeat: no-repeat;
		}

		.zhongguancun_header_menu_list {
			display: flex;
			justify-content: flex-end;

			li {
				font-size: 14px;
				padding: 12px 0;
				margin: 0 60px;
				line-height: 23px;
				transition: 0.5s;
				cursor: pointer;
				// }
			}

			// color: #fff;
		}

		.zhongguancun_header_weather {
			padding: 12px 0;
			// margin-right: 50px;
			font-size: 18px;
		}

		h1 {
			// color: #fff;
			// background: linear-gradient(none);
			margin-right: 0px;
			margin-left: 0px;
			background: rgba(0, 0, 0, 0);
			padding: 0px 80px 15px;
			margin-top: 20px;
			background: url(../assets/images/zhongguancunHeadBg.png) no-repeat;
		}
	}

	.zhongguancun_btn_box {
		display: flex;
		align-items: center;
		margin-right: 50px;

		// margin-left: 10px;
		.sign_out_model {
			margin-right: 20px;
		}
	}

	.zhongguancun_btn_box .sign_out_model {
		margin-left: 0;
	}

	.weixing_screen_header {
		background: url(../assets/images/weixingHeadBg2.png) no-repeat;
		justify-content: space-between;
		align-items: center;

		h1 {
			padding-left: 500px;
			margin-right: 60px;
			color: #fff;
			background: linear-gradient(180deg, #fff 0%, #135ca8 100%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}

		.weixing_header_weather {
			color: #fff;
			margin-top: 11px;
			padding-right: 95px;
			font-size: 18px;
		}
	}

	.dahang_screen_header {
		background-image: url(../assets/images/dahang/hearder.png);
		background-size: 100%;
		background-repeat: no-repeat;

		h1 {
			margin-left: -39px;
			margin-top: 10px;
		}

		.dahang_header_weather {
			position: absolute;
			top: 14px;
			right: 87px;
			font-size: 18px;
			color: #ffffff;
		}
	}

	.haixing_screen_header {
		top: 44px;
		background-image: url(../assets/images/haixing/header.png);
		background-size: 100%;
		background-repeat: no-repeat;

		h1 {
			margin-left: -38px;
			margin-top: -30px;
		}

		.haixing_header_weather {
			position: absolute;
			top: -27px;
			right: 87px;
			font-size: 18px;
			color: #78f7fb;
		}
	}

	.other_screen_header {
		background-image: url(../assets/images/other/header.png);
		background-size: 100%;
		background-repeat: no-repeat;
		background-color: rgba(5, 12, 26, 0.5);

		h1 {
			margin-left: -35px;
		}

		.other_header_weather {
			position: absolute;
			top: 15px;
			right: 90px;
			font-size: 18px;
			color: #ffffff;
		}
	}

	.xyds_screen_header {
		top: -12px;
		height: 112px;
		background-image: url(../assets/images/xyds/header.png);
		background-size: 100%;
		background-repeat: no-repeat;
		padding-left: 0;
		padding-bottom: 0;
		.xyds_screen_header_shadow {
			width: 100%;
			height: 100%;
			display: block;
			position: absolute;
			background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), transparent);
		}

		h1 {
			top: 18px;
			position: absolute;
			font-family: PingFang SC;
			font-weight: bold;
			color: #ffffff;
			text-shadow: 0px 3px 3px #040819;
		}

		.xyds_header_weather {
			position: absolute;
			top: 55px;
			right: 30px;
			font-size: 20px;
			color: #f1ebe4;
		}
	}

	.gdds_screen_header {
		padding-bottom: 16px;
		background-image: url(../assets/images/gdds/header.png);
		background-size: 100%;
		background-repeat: no-repeat;
		background-color: rgba(5, 12, 26, 0.5);

		h1 {
			font-weight: bold;
			margin-top: 29px;
			margin-left: -28px;
			font-family: Microsoft YaHei;
			background-image: linear-gradient(#ffffff, #b9d7ff);
			background-clip: text;
			color: transparent;
		}
		.gdds_header_menu,
		.gdds_header_time {
			position: absolute;
			font-size: 18px;
			color: #ffffff;
		}
		.gdds_header_menu {
			top: 32px;
			right: 195px;
		}
		.gdds_header_time {
			top: 32px;
			left: 150px;
		}
	}
</style>
