<!--
 * @Author: ch3nh2
 * @Date: 2023-04-21 15:48:35
 * @LastEditors: ch3nh2
 * @LastEditTime: 2023-08-26 13:41:08
 * @FilePath: \beilunchanye_fe_20211208\src\components\GDDSwitchScreen.vue
 * @Description: none
-->
<template>
  <div :class="`bottom-switch bottom-switch-${pageId}`">
    <div
      class="switch-button switch-button-prev"
      @click="onSwitchPage('prev')"
    ></div>
    <div
      id="switchContainer"
      class="switch-container-wrap"
    >
      <div class="switch-container">
        <div
          v-for="(item, index) in list"
          :key="item.id"
          :id="index == 0 && 'switchItem'"
          class="switch-item"
          :class="{ 'switch-item-active': active == item.id }"
          @click="changeScreenItem(item)"
        >
          <div class="switch-wrap">
            <div class="switch-cover">
              <img
                :src="item.imgUrl"
                :alt="item.perName"
              />
            </div>
            <div class="switch-name">
              <p>{{ item.perName }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="switch-button switch-button-next"
      @click="onSwitchPage('next')"
    ></div>
  </div>
</template>
<script>
export default {
  props: {
    pageId: {
      type: Number,
      default: 1,
    },
  },
  computed: {},
  data() {
    return {
      list: [
        {
          id: 1,
          perName: "高德大厦",
          imgUrl: require("@/assets/images/screen_image_gd_1.png"),
        },
        {
          id: 2,
          perName: "四道口融寓",
          imgUrl: require("@/assets/images/screen_image_gd_2.png"),
        },
        {
          id: 3,
          perName: "小米科技",
          imgUrl: require("@/assets/images/screen_image_gd_3.png"),
        },
      ],
      scrollType: "",
      active: 1,
    };
  },
  watch: {},
  methods: {
    handleScroll() {
      let scrollTo = {
        el: "#switchItem",
        container: "#switchContainer",
        offset: 0,
        x: true,
        y: false,
      };
      this.$nextTick(() => {
        if (this.list.length > 0) {
          let offset;
          const switchContainer = document.querySelector(
            ".switch-container-wrap"
          );
          const switchItem = document.querySelector(".switch-item-active");
          const containerW = switchContainer.offsetWidth;
          const itemW = switchItem.offsetWidth;
          const itemX = switchItem.offsetLeft - 60;
          const itemPost = itemX + itemW;
          if (this.scrollType == "prev") {
            if (itemPost <= 716) {
              offset = 0;
            } else {
              offset = containerW + itemPost;
            }
          }

          if (this.scrollType == "next") {
            if (itemPost > containerW) {
              offset = itemX;
            } else {
              offset = 0;
            }
          }
          scrollTo = { ...scrollTo, ...{ offset } };
          this.$scrollTo("#switchItem", 500, scrollTo);
        }
      });
    },
    onSwitchPage(type) {
      let index = this.list.findIndex((item) => this.active == item.id);
      if (type == "prev") {
        this.scrollType = "prev";
        index = index == 0 ? this.list.length - 1 : index - 1;
      }

      if (type == "next") {
        this.scrollType = "next";
        index = index == this.list.length - 1 ? 0 : index + 1;
      }
      this.changeScreenItem(this.list[index]);
    },
    changeScreenItem(item) {
      this.active = item.id;
    },
  },
  mounted() { },
};
</script>
<style lang="less" scoped>
.bottom-switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 1010;
  height: 180px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  user-select: none;

  .switch-container-wrap {
    max-width: 820px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    .switch-container {
      display: flex;
      align-items: center;

      .switch-item {
        cursor: pointer;
        position: relative;
        width: 92px;
        height: 78px;
        flex-shrink: 0;
        margin-right: 12px;
        background-size: 100%;
        background-repeat: no-repeat;
        transition: all 0.2s;

        &:last-child {
          margin-right: 0;
        }

        .switch-wrap {
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          width: 92px;
          height: 70px;
          top: 4px;
          left: 50%;
          transform: translateX(-50%);

          .switch-cover {
            width: 78px;
            height: 43px;

            img {
              width: 100%;
              max-height: 100%;
            }
          }

          .switch-name {
            width: 90%;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            text-align: center;

            p {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }

      .switch-item-active {
        width: 196px;
        height: 188px;

        .switch-wrap {
          top: 10px;
          width: 187px;
          height: 133px;

          .switch-cover {
            width: 166px;
            height: 94px;
            margin-bottom: 4px;
            transition: width 0.2s;
          }

          .switch-name {
            font-size: 20px;
            font-weight: bold;
          }
        }
      }
    }
  }

  .switch-button {
    width: 50px;
    height: 100px;
    outline: none;
    cursor: pointer;
    background-size: 100%;
    background-repeat: no-repeat;

    &:active {
      opacity: 0.5;
    }
  }

  .switch-button-prev {
    margin-right: 10px;
  }

  .switch-button-next {
    margin-left: 10px;
  }
}

.loop(@i) when (@i>0) {
  .loop((@i - 1));

  .bottom-switch-@{i} {
    .switch-item {
      background-image: url("../assets/images/bottomSwitch/item-@{i}-n.png");
    }

    .switch-item-active {
      background-image: url("../assets/images/bottomSwitch/item-@{i}-a.png");
    }

    .switch-button-prev {
      background-image: url("../assets/images/bottomSwitch/item-@{i}-l.png");
    }

    .switch-button-next {
      background-image: url("../assets/images/bottomSwitch/item-@{i}-r.png");
    }
  }
}

.loop(9);

.bottom-switch-1 {
  // bottom: 33px;

  .switch-container-wrap
    .switch-container
    .switch-item-active
    .switch-wrap
    .switch-name {
    color: #d8eeff;
  }
}

.bottom-switch-2 {
  // bottom: 42px;

  .switch-container-wrap
    .switch-container
    .switch-item-active
    .switch-wrap
    .switch-name {
    color: #ddd0c1;
  }
}

.bottom-switch-3 {
  // bottom: 10px;

  .switch-container-wrap
    .switch-container
    .switch-item-active
    .switch-wrap
    .switch-name {
    color: #33d1a1;
  }
}

.bottom-switch-4 {
  .switch-container-wrap
    .switch-container
    .switch-item-active
    .switch-wrap
    .switch-name {
    color: #4ae5fa;
  }
}

.bottom-switch-5 {
  .switch-container-wrap
    .switch-container
    .switch-item-active
    .switch-wrap
    .switch-name {
    color: #18f5f5;
  }
}

.bottom-switch-6 {
  .switch-container-wrap
    .switch-container
    .switch-item-active
    .switch-wrap
    .switch-name {
    color: #d8eeff;
  }
}

.bottom-switch-7 {
  .switch-container-wrap
    .switch-container
    .switch-item-active
    .switch-wrap
    .switch-name {
    color: #d8eeff;
  }
}

.bottom-switch-8 {
  // bottom: 50px;

  .switch-container-wrap
    .switch-container
    .switch-item-active
    .switch-wrap
    .switch-name {
    color: #1cd5a2;
  }
}

.bottom-switch-9 {
  .switch-container-wrap
    .switch-container
    .switch-item-active
    .switch-wrap
    .switch-name {
    color: #d8eeff;
  }
}
</style>
