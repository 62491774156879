<template>
    <div class="word_cloud">
        <div v-if="wordCloudData.length>0" class="word_cloud_data" id="wordCloudContainer"></div>
        <div v-else class="word_cloud_nodata">
            <img :src="require(`@/assets/images/word_cloud_nodata_${noDataIcon}.png`)" alt="">
        </div>
    </div>
</template>

<script>
import { WordCloud } from "@antv/g2plot";
export default {
    name: "WordCloud",
    props: {
        wordCloudData: {
            default: () => []
        },
        noDataIcon: {
            default: () => 'bl'
        }
    },
    data() {
        return {};
    },
    mounted() {
        this.createWordCloud()
    },
    methods: {
        createWordCloud() {
            if (this.wordCloudData && this.wordCloudData.length > 0) {
                const data = this.wordCloudData;
                this.wordCloud = new WordCloud("wordCloudContainer", {
                    data,
                    wordField: "name",
                    colorField: "name",
                    weightField: "weight",
                    wordStyle: {
                        fontSize: [8, 32],
                    },
                    tooltip: false,
                    random: () => 0.5,
                });
                this.wordCloud.render();
            }
        }
    }
};
</script>

<style lang="less" scoped>
.word_cloud {
    height: 216px;

    .word_cloud_data {
        height: 100%;
        padding: 20px;
    }

    .word_cloud_nodata {
        height: 100%;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 160px;
            height: 110px;
        }
    }
}
</style>

