import Vue from 'vue'
// import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import store from './store'
import * as echarts from 'echarts'
import VueScrollTo from 'vue-scrollto'
import {
  Button,
  Input,
  FormModel,
  Icon,
  Checkbox,
  message,
  notification,
  Select,
  Spin
} from 'ant-design-vue'
// import ant from 'ant-design-vue'
// import 'ant-design-vue/dist/antd.less'
import 'ant-design-vue/lib/style/components.less'
import 'ant-design-vue/lib/style/themes/index.less'


import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import BaiduMap from 'vue-baidu-map'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(BaiduMap, {
  ak: 'mRfFK6WEIEPrVTqneDufDNTn7hg5Rjk2'
})
Vue.use(VueAwesomeSwiper)
Vue.prototype.$message = message;
Vue.prototype.$notification = notification;
// Vue.use(ant)
Vue.use(Button)
Vue.use(Icon)
Vue.use(Input)
Vue.use(FormModel)
Vue.use(Checkbox)
Vue.use(Select)
Vue.use(Spin)
Vue.use(ElementUI);
Vue.use(VueScrollTo);
// Vue.use(SelectOption)
// Vue.use(Vuex)

Vue.config.productionTip = false

Vue.prototype.$echarts = echarts


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
