<template>
  <div>
    <div id="financingEchartChart" :style="{width: '235px', height: '215px'}">
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  mounted() {
    this.drawLine()
  },
  methods: {
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('financingEchartChart'))
      // 绘制图表
      myChart.setOption({
        tooltip: {
            show: false
        },
      legend: {
        show: false,
        orient: 'vertical',
        // top: '50%',
        left: 'right'
      },
      color: [
            new echarts.graphic.LinearGradient(1,1,0,0, [{
                offset:0.4,
                color: '#5e8fed'
            },
            {
                offset: 0.8,
                color: 'rgba(94,143,237,0.6)'
            },
            {
                offset: 1,
                color: 'transparent'
            }]),
            new echarts.graphic.LinearGradient(0,1,1,0, [{
                offset:0.4,
                color: '#ffc691'
            },
            {
                offset: 0.8,
                color: 'rgba(255,198,145, 0.6)'
            },
            {
                offset:1,
                color: 'transparent'
            }]),
            new echarts.graphic.LinearGradient(0,0,1,1, [{
                offset:0.4,
                color: '#e07379'
            },
            {
                offset: 0.8,
                color: 'rgba(224,115,121, 0.6)'
            },
            {
                offset:1,
                color: 'transparent'
            }]),
            new echarts.graphic.LinearGradient(0,0,0,1, [{
                offset:0.4,
                color: '#51dbb2'
            },
            {
                offset: 0.8,
                color: 'rgba(81,219,178, 0.6)'
            },
            {
                offset:1,
                color: 'transparent'
            }])
      ],
      series: [
        {
          name: 'Access From',
          type: 'pie',
          center: ['56%','50%'],
          radius: ['60%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 100,
            // borderColor: '#fff',
            borderWidth: 1,
          },
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: false,
              fontSize: '12',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [
            { 
                value: this.$store.state.home.dataInfo.financingInfo.a, 
                name: 'Search Engine'
            },
            { 
                value: this.$store.state.home.dataInfo.financingInfo.b,
                name: 'Direct'
            },
            { 
                value: this.$store.state.home.dataInfo.financingInfo.c,
                name: 'Email'
            },
            { 
                value: this.$store.state.home.dataInfo.financingInfo.d,
                name: 'park'
            },
          ]
        }
      ]
      })
    }
  }
}
</script>