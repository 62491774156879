<template>

  <div class="homeMainCont_footer">
    <TitleMain :txt="'主要技术'" />
    <WordCloud noDataIcon="bl" :wordCloudData="$store.state.home.dataInfo.activeInfo.company_keywords" />
    <div class="homeMainCont_chart">
      <TitleMain :txt="'研发效率'" />
      <template
        v-if="$store.state.home.dataInfo.activeInfo.distribution && $store.state.home.dataInfo.activeInfo.distribution.length > 0">
        <div class="homeMainCont_chart_text">
          <p style="font-size:14px;">该企业近三年的研发效率平均值<span style="font-size:18px;color:#EB515C;">({{
              $store.state.home.dataInfo.activeInfo.rd_efficiency.recent_years_company_rd_efficiency_avg.toFixed(2)
          }})</span>
          </p>
          <p style="font-size:14px;">
            {{ $store.state.home.dataInfo.activeInfo.rd_efficiency &&
                ($store.state.home.dataInfo.activeInfo.rd_efficiency.recent_years_company_rd_efficiency_avg * 100)
                > ($store.state.home.dataInfo.activeInfo.rd_efficiency.recent_years_cluster_rd_efficiency_avg * 100) ?
                '高于' : ($store.state.home.dataInfo.activeInfo.rd_efficiency.recent_years_company_rd_efficiency_avg * 100)
                  ==
                  ($store.state.home.dataInfo.activeInfo.rd_efficiency.recent_years_cluster_rd_efficiency_avg * 100) ? '等于' :
                  ($store.state.home.dataInfo.activeInfo.rd_efficiency.recent_years_company_rd_efficiency_avg * 100)
                    < ($store.state.home.dataInfo.activeInfo.rd_efficiency.recent_years_cluster_rd_efficiency_avg * 100) ? '低于'
                    : ''
            }}同行业研发效率平均值<span style="font-size:18px;color:#2182FF;">
              ({{ $store.state.home.dataInfo.activeInfo.rd_efficiency.recent_years_cluster_rd_efficiency_avg.toFixed(2)
              }})</span>
          </p>
        </div>
        <div class="PieChat2">
          <div id="myChart2" :style="{ width: '400px', height: '260px' }"> </div>
        </div>
      </template>
      <div v-else class="homeMainCont-right-chart-nodata">
        <img src="@/assets/images/word_cloud_nodata_bl.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import TitleMain from '@/components/TitleMain.vue';
import WordCloud from '@/components/WordCloud.vue';


export default {
  components: {
    TitleMain,
    WordCloud
  },
  mounted() {
    this.drawLine2()
  },
  methods: {
    drawLine2() {
      if (!this.$store.state.home.dataInfo.activeInfo.distribution || this.$store.state.home.dataInfo.activeInfo.distribution.length == 0) return
      let datetime = [], company = [], industry = [];
      this.$store.state.home.dataInfo.activeInfo.distribution.map((item) => {
        datetime.push(String(item.date_time))
        company.push(item.company_rd_efficiency)
        industry.push(item.cluster_rd_efficiency)
      })
      let myChart2 = this.$echarts.init(document.getElementById('myChart2'))
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
          }
        },
        color: ['#149cf6', '#dbad85'],
        legend: {
          itemHeight: 6,
          itemWidth: 6,
          x: 'center',
          y: 'top',
          textStyle: {
            fontSize: 14,
            color: '#ffffff'
          },
          data: [
            { name: '企业自主研发', icon: 'rect' },
            { name: '行业平均自主研发', icon: 'rect' }]
        },
        grid: {
          left: '4%',
          right: '13%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisTick: {
              show: false
            },
            data: datetime,
            axisLabel: {
              show: true,
              textStyle: {
                color: '#ffffff'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            boundaryGap: false,
            nameTextStyle: {
              color: "#ffffff",
              fontSize: 12,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: 'rgb(67,71,87)'
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: '#ffffff'
              }
            }
          }
        ],
        series: [
          {
            name: '企业自主研发',
            type: 'line',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            data: company
          },
          {
            name: '行业平均自主研发',
            type: 'line',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            data: industry
          },
        ]
      };

      myChart2.setOption(option)
    },
  },

}
</script>
<style lang="less" scoped>
.homeMainCont_footer {

  // padding: 36px 21px 0;
  .homeMainCont_list {
    display: flex;
    margin-top: 17px;

    .homeMainCont_list_item {
      // width: 175px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .homeMainCont_list_iconBox {
      display: flex;
      width: 40px;
      height: 40px;
      position: relative;
    }

    .homeMainCont_list_itemIcon {
      margin: auto;
    }

    .homeMainCont_list_itemBorder {
      // border:10px solid #ddd;
      // border-image: linear-gradient(#1f5154,#4fd6af) 30 30; 
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 0;

      img {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .homeMainCont_list_itemBorder img:nth-child(2) {
      top: -5px;
      left: -4px;
    }

    .left_txt_box {
      display: flex;
      padding: 10px 26px 0;

      p {
        // width: 45%;
        margin-top: 3px;
        font-size: 12px;
      }

      span {
        font-size: 20px;
        margin: 0 7px;
        color: #5e8fed;
      }
    }
  }

  .homeMainCont_chart {
    margin-top: 30px;

    .homeMainCont-right-chart-nodata {
      width: 100%;
      height: 420px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 160px;
        height: 110px;
      }
    }

    .homeMainCont_chart_text {
      margin-top: 20px;
      margin-left: 20px;
      margin-bottom: 10px;

      span {
        font-family: 'bigdsfont';
      }
    }
  }
}
</style>