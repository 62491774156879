var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("wrap_three_btn " + _vm.className)},[_c('ul',{class:{
      wrap_three_btn_list: true,
      map_hide_left:
        !_vm.$store.state.showDataValue ||
        _vm.$store.state.screenNumber == 4 ||
        !_vm.$store.state.mapType,
    }},[(_vm.$store.state.mapType)?_c('li',{class:{ wrap_three_btn_item: true, change_btn_item: _vm.mapValue },on:{"click":_vm.changeMap}},[_c('div',{staticStyle:{"height":"28px"}},[(!_vm.mapValue)?_c('img',{attrs:{"src":require("@/assets/images/earth_no_icon.png"),"alt":""}}):_vm._e(),(_vm.mapValue)?_c('img',{attrs:{"src":require("@/assets/images/shuju_icon.png"),"alt":""}}):_vm._e()]),(!_vm.mapValue)?_c('span',[_vm._v("地图")]):_c('span',[_vm._v("数据")])]):_vm._e(),(_vm.$store.state.mapType && !_vm.isGD)?_c('li',{class:{
        wrap_three_btn_item: true,
        change_btn_item: _vm.$store.state.goStatus,
      },on:{"click":function($event){$event.stopPropagation();return _vm.changeStar.apply(null, arguments)}}},[_c('div',{staticStyle:{"height":"28px"}},[(!_vm.$store.state.goStatus)?_c('img',{attrs:{"src":require("@/assets/images/start_no_icon.png"),"alt":""}}):_vm._e(),(_vm.$store.state.goStatus)?_c('img',{attrs:{"src":require("@/assets/images/stop_icon.png"),"alt":""}}):_vm._e()]),(!_vm.$store.state.goStatus)?_c('span',[_vm._v("开始")]):_c('span',[_vm._v("暂停")])]):_vm._e()]),_c('ul',{class:{ change_roam_list: true, show_roam_list: _vm.changeId == 2 }},_vm._l((_vm.roamList),function(item){return _c('li',{key:item.id,class:{
        change_roam_item: true,
        change_now_roam_item: _vm.changeRoamItemId == item.id,
      },on:{"click":function($event){return _vm.changeRoamItem(item)}}},[_c('div',{staticClass:"screen_image"},[(item.id == 1)?_c('img',{attrs:{"src":require("@/assets/images/screen_image.jpg")}}):(item.id == 3)?_c('img',{attrs:{"src":require("@/assets/images/screen_image2.jpeg")}}):(item.id == 2)?_c('img',{attrs:{"src":require("@/assets/images/screen_image4.jpeg")}}):_c('img',{attrs:{"src":require("@/assets/images/screen_image3.png")}})]),_c('p',{class:{ change_roam_text: _vm.changeRoamItemId == item.id }},[_vm._v(" "+_vm._s(item.name)+" ")])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }