/*
 * @Author: PC
 * @Date: 2022-07-08 15:39:33
 * @LastEditTime: 2022-09-23 10:57:41
 * @Description: 请您输入文件描述
 */
const zhongguancunHome = {
  state: {
    survey: [
      {
        key: 'all_area',
        title: '总建筑面积',
        imgUrl: require('@/assets/images/zhongguancunRightIcon1.png'),
        num: 0,
        company: 'm²'
      },
      {
        key: 'all_floor',
        title: '总楼层',
        imgUrl: require('@/assets/images/zhongguancunRightIcon2.png'),
        num: 0,
        company: '层'
      },
      {
        key: 'car_num',
        title: '车位总数',
        imgUrl: require('@/assets/images/zhongguancunRightIcon3.png'),
        num: 0,
        company: '个'
      },
      {
        key: 'floor_height',
        title: '层高',
        imgUrl: require('@/assets/images/zhongguancunRightIcon4.png'),
        num: 0,
        company: '米'
      },
    ],
    areaArr: [
      {
        key: 'can_rent_area',
        title: '可租赁面积',
        num: 0
      },
      {
        key: 'has_rent_area',
        title: '已出租面积',
        num: 0
      },
      {
        key: 'not_rent_area',
        title: '未出租面积',
        num: 0
      }
    ],
    profileArr: [
      {
        key: 'company_num',
        title: '入驻企业',
        iconUrl: require('@/assets/images/zhongguancunHomeIcon1.png'),
        bgUrl: require('@/assets/images/zhongguancunHomeBg1.png'),
        num: 0,
        company: '家'
      },
      {
        key: 'in_num',
        title: '入驻商家',
        iconUrl: require('@/assets/images/zhongguancunHomeIcon2.png'),
        bgUrl: require('@/assets/images/zhongguancunHomeBg2.png'),
        num: 0,
        company: '家'
      },
      {
        key: 'high_num',
        title: '国高新企业数',
        iconUrl: require('@/assets/images/zhongguancunHomeIcon3.png'),
        bgUrl: require('@/assets/images/zhongguancunHomeBg3.png'),
        num: 0,
        company: '家'
      },
      {
        key: 'employee_num',
        title: '企业员工数',
        iconUrl: require('@/assets/images/zhongguancunHomeIcon4.png'),
        bgUrl: require('@/assets/images/zhongguancunHomeBg4.png'),
        num: 0,
        company: '人'
      },
      {
        key: 'tech_num',
        title: '科技人才数',
        iconUrl: require('@/assets/images/zhongguancunHomeIcon5.png'),
        bgUrl: require('@/assets/images/zhongguancunHomeBg5.png'),
        num: 0,
        company: '人'
      },
    ],
    areaEchartsNum: 0,
  }
}
export default zhongguancunHome