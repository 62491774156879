import { getMedicalMap, getCompany, getCorporate, getComRepres } from '@/api/beilun'
const home = {
  state: {
    test: '123',
    homeInfo: false,
    noneAmin: false,
    moreFlag: false,
    allAtlas: [
      /* {num: 7, name: '医药制造'},
      {num: 8, name: '生物技术'},
      {num: 2, name: '医疗器械'},
      {num: 2, name: '数字健康'},
      {num: 1, name: '材料'},
      {num: 1, name: '化工'}, */
    ],
    activeList: [],
    dataInfo: {
      financingInfo: {
        all: 0,
        a: '',
        b: '',
        c: '',
        d: ''
      },
      moreInfo: '此外，海新域城市更新集团还构建了以北大西门中关村国际创新大厦、未来科技大厦为中心的人工智能、集成电路走廊，以及融汇国际大厦金融示范区和北京卫星制造厂为核心的智能制造示范区。',
      survey: {
        num: '',
        topNum: '',
        peoples: '',
        personnel: '',
        property: '',
        newResult: '',
      },
      swiperList: [
        []
      ],
      activeInfo: {}
    }
  },
  mutations: {
    setSwiperList(state, department) {
      console.log('保存数据', department)
      state.dataInfo.swiperList[0] = department
    },
    changeAllAtlas(state, department) {
      state.allAtlas = department
      // console.log(state.allAtlas,11111)
    }
  },
  actions: {
    getHomeData({ state, commit }) {
      // console.log(state)
      getMedicalMap().then(data => {
        // console.log('医疗健康产业图谱', data)
        const _data = data.data
        let _list = []
        _data.map(item => {
          _list.push({
            value: item.company_num,
            name: item.industry_type
          })
        })
        // state.allAtlas = [ ...data.data ]
        commit('changeAllAtlas', _list)
      })
      getCompany().then(data => {
        // console.log('产业园区企业概况', data)
        const _data = data.data[0]
        state.dataInfo.survey.num = _data.company_num ? Number(_data.company_num) : ''
        state.dataInfo.survey.topNum = _data.listed_num ? Number(_data.listed_num) : ''
        state.dataInfo.survey.peoples = _data.employee_num ? Number(_data.employee_num) : ''
        state.dataInfo.survey.personnel = _data.talent_num ? Number(_data.talent_num) : ''
        state.dataInfo.survey.property = _data.innovations ? Number(_data.innovations) : ''
        state.dataInfo.survey.newResult = _data.intellectual_property ? Number(_data.intellectual_property) : ''
      })
      getCorporate().then(data => {
        // console.log('园区企业融资', data)
        const _data = data.data[0]
        let _pre_A = _data.pre_A ? Number(_data.pre_A) : 0
        let _pre_B = _data.pre_B ? Number(_data.pre_B) : 0
        let _pre_C = _data.pre_C ? Number(_data.pre_C) : 0
        let _new_pre = _data.new_pre ? Number(_data.new_pre) : 0
        state.dataInfo.financingInfo.a = _data.pre_A ? Number(_data.pre_A) : ''
        state.dataInfo.financingInfo.b = _data.pre_B ? Number(_data.pre_B) : ''
        state.dataInfo.financingInfo.c = _data.pre_C ? Number(_data.pre_C) : ''
        state.dataInfo.financingInfo.d = _data.new_pre ? Number(_data.new_pre) : ''
        state.dataInfo.financingInfo.all = Number(_pre_A) + Number(_pre_B) + Number(_pre_C) + Number(_new_pre)
      })
      getComRepres({
        type: 1,
        limit: 999
      }).then(res => {
        const _data = res.data
        let newData = []
        _data.map(item => {

          let _obj = {
            baseInfo: {},
            result: {}
          }

          const company_industry = [];
          _obj.name = item.company_name
          _obj.company_info = item.company_info
          _obj.company_keywords = item.company_keywords ? JSON.parse(item.company_keywords) : [];
          _obj.patent_star = item.patent_star ? JSON.parse(item.patent_star) : null;
          _obj.intellectual_property_statistics = item.intellectual_property_statistics ? JSON.parse(item.intellectual_property_statistics) : null
          _obj.rd_efficiency = item.rd_efficiency ? JSON.parse(item.rd_efficiency) : null
          _obj.distribution = item.distribution ? JSON.parse(item.distribution) : []
          _obj.company_industry = item.company_industry ? JSON.parse(item.company_industry) : []
          _obj.company_industry.map((item) => {
            if (item.children && item.children.length > 0) {
              item.children.map((jtem) => {
                company_industry.push({
                  value: jtem.industry_id,
                  label: jtem.industry_name
                })
              })
            }
          })
          _obj.company_industry = company_industry
          _obj.grade = item.grade ? JSON.parse(item.grade) : []
          _obj.tech_rd_strength = item.tech_rd_strength ? JSON.parse(item.tech_rd_strength) : []


          _obj.atlas = item.type
          _obj.colony = item.group_of
          _obj.baseInfo.people = item.number
          _obj.baseInfo.stage = item.phase
          _obj.levelInfo = item.proportion
          _obj.enterprise = [23, 43, 55, 23, 11]
          _obj.industry = [45, 32, 68, 29, 20]
          _obj.colony = item.group_of
          _obj.levelNum = item.rate
          _obj.level = item.type ? `（${item.type}）` : ''
          _obj.result.property = item.property_rights
          _obj.result.newResult = item.new_results || ''
          newData.push(_obj)
        })
        // console.log(newData,18989282)
        commit('setSwiperList', newData)
      })
    }
  }
}

export default home