<!--
 * @Author: PC
 * @Date: 2022-07-08 15:39:33
 * @LastEditTime: 2022-08-08 20:21:27
 * @Description: 请您输入文件描述
-->
<template>
  <div class="financing_wrap"> 
    <TitleRight :txt="'园区股权投资'" />
    <div class="financing_cont">
      <div style="height: 215px;width: 235px;">
        <div class="financing_echartBox" v-if="showFinacing">
            <FinancingEchart />
            <div class="inside_round">
            <div class="mainEchartLeftChart_number">
                <span>{{$store.state.home.dataInfo.financingInfo.all}} 家</span>
                <!-- <p>家</p> -->
            </div>
            </div>
        </div>
      </div>
      <div class="financing_right">
        <div class="financing_right_item">
          <BlockLine :line="false" :color="'#5e8fed'" />
          <div class="txt_box">
            <p class="txt_box_top">A轮</p>
            <div class="txt_boxBottom">
              <div class="left_txt_box left_txt_box_a">
                <!-- <p><span>XX</span>亿元</p> -->
                <p><span>{{$store.state.home.dataInfo.financingInfo.a ? $store.state.home.dataInfo.financingInfo.a : '-'}}</span>家</p>
              </div>
            </div>
          </div>
        </div>
        <div class="financing_right_item">
          <BlockLine :line="false" :color="'#ffc691'" />
          <div class="txt_box">
            <p class="txt_box_top">B轮</p>
            <div class="txt_boxBottom">
              <div class="left_txt_box left_txt_box_b">
                <!-- <p><span>XX</span>亿元</p> -->
                <p><span>{{$store.state.home.dataInfo.financingInfo.b ? $store.state.home.dataInfo.financingInfo.b : '-'}}</span>家</p>
              </div>
            </div>
          </div>
        </div>
        <div class="financing_right_item">
          <BlockLine :line="false" :color="'#e07379'" />
          <div class="txt_box">
            <p class="txt_box_top">C轮</p>
            <div class="txt_boxBottom">
              <div class="left_txt_box left_txt_box_c">
                <!-- <p><span>XX</span>亿元</p> -->
                <p><span>{{$store.state.home.dataInfo.financingInfo.c ? $store.state.home.dataInfo.financingInfo.c : '-'}}</span>家</p>
              </div>
            </div>
          </div>
        </div>
        <div class="financing_right_item">
          <BlockLine :line="false" :color="'#52d6b6'" />
          <div class="txt_box">
            <p class="txt_box_top">新三板</p>
            <div class="txt_boxBottom">
              <div class="left_txt_box left_txt_box_d">
                <!-- <p><span>XX</span>亿元</p> -->
                <p><span>{{$store.state.home.dataInfo.financingInfo.d ? $store.state.home.dataInfo.financingInfo.d : '-'}}</span>家</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import TitleRight from '@/components/TitleRight.vue'
import BlockLine from '@/components/BlockLine.vue'
import FinancingEchart from '@/components/homeRightCont/financingEchart.vue'
export default {
  components: {
    FinancingEchart,
    TitleRight,
    BlockLine
  },
  data () {
      return {
        showFinacing: false
      }
  },
  mounted () {
      setTimeout(() => {
          this.showFinacing = true
      }, 1000);
  }
}
</script>
<style lang="less" scoped>
.financing_wrap{
  position: relative;
  .financing_cont{
    display: flex;
  }
  .financing_echartBox{
    position: relative;
  }
  .inside_round{
    width: 110px;
    height: 110px;
    background: #070d25;
    box-shadow: 0 0 25px #5e8fed inset;
    border-radius: 50%;
    position: absolute;
    top: 52px;
    left: 76px;
  }
  .mainEchartLeftChart_number{
    // position: absolute;
    // top: 67px;
    // left: 67px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    p{
      text-align: center;
      font-size: 16px;
    }
    span{
      text-align: center;
      font-size: 24px;
    }
  }
  .financing_right{
    margin-left: 31px;
    flex: 1;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    .financing_right_item{
      width: 100%;
      margin: 11px 0;
      display: flex;
    }
  }
  .txt_box{
    display: flex;
  }
  .txt_box_top{
    margin-left: 10px;
    margin-right: 20px;
  }
  .left_txt_box{
    display: flex;
    justify-content: space-between;
    // padding: 8px 0 0;
    p{
      // width: 45%;
      font-size: 12px;
      margin-right: 10px;
    }
    .redColor{
      color: #e54852;
    }
    span{
      font-size: 16px;
      margin: 0 6px;
    }
  }
  .left_txt_box_a{
    span{
      color: #5e8fed;
    }
  }
  .left_txt_box_b{
    span{
      color: #ffc691;
    }
  }
  .left_txt_box_c{
    span{
      color: #e07379;
    }
  }
  .left_txt_box_d{
    span{
      color: #52d6b6;
    }
  }
}
</style>
