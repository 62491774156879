<!--
 * @Author: PC
 * @Date: 2022-04-02 17:59:56
 * @LastEditTime: 2023-08-31 21:38:50
 * @Description: 请您输入文件描述
-->
<template>
	<div id="app">
		<Header v-if="$store.state.mapType && isPhone === false" />
		<router-view />
		<!-- 通过搜索地址进行查询 -->
		<!-- <SearchModel v-if='!$store.state.loadingOk && $store.state.showMapValue && $store.state.mapType' /> -->
		<ThreeBtn :className="threeBtnClass" :isGD="isGD" v-if="!$store.state.loadingOk && $store.state.showMapValue && isPhone === false" />
		<SwitchScreen v-if="$store.state.mapType && isPhone === false && !isGD" :pageId="switchPageId" />
		<GDDSwitchScreen v-if="$store.state.mapType && isPhone === false && isGD" />
		<SwitchMainScreen v-if="!$store.state.loadingOk && !isPhone && !isGD" :typeNum="screenTypeNum" />
		<div class="loading-wrap" v-if="$store.state.loadingOk && $store.state.showMapValue && isPhone === false">
			<p class="loading-text">场景加载中...</p>
		</div>
		<iframe v-if="$store.state.showMapValue && isPhone === false" id="screenWrap" style="position: absolute; top: 0; left: 0; height: 100%; width: 100%" src="https://www.thingjs.com/p/f6f0ef21aa3f5fb26f4579c7?params=105b0f77fd24654d4eebc434e9"> </iframe>
	</div>
</template>
<script>
	import 'animate.css';
	// import 'http://wwcdn.weixin.qq.com/node/wework/wwopen/js/wwLogin-1.2.4.js'
	import Header from '@/components/Header';
	import ThreeBtn from '@/components/ThreeBtn.vue';
	import SwitchScreen from '@/components/SwitchScreen.vue';
	import GDDSwitchScreen from '@/components/GDDSwitchScreen.vue';
	import SwitchMainScreen from '@/components/SwitchMainScreen';
	// import SearchModel from '@/components/SearchModel.vue'
	// import '@/utils/gaodeWeather.js'
	// import { set_GetUUIDFun } from '@/utils/utils.js'
	export default {
		components: {
			Header,
			ThreeBtn,
			SwitchScreen,
			GDDSwitchScreen,
			SwitchMainScreen,
			// SearchModel
		},
		data() {
			return {
				switchPageId: 10,
				screenTypeNum: 1,
				threeBtnClass: '',
				showCode: true,
				isPhone: true,
				isGD: false,
			};
		},
		watch: {
			// "$store.state.showCodeModel"(newVal, oldVal) {
			//   // console.log(newVal, oldVal)
			//   const _code = this.$route.query.code
			//   localStorage.setItem('code', JSON.stringify({ code: _code, expired: new Date().getTime() }))
			//   this.$store.commit('changeShowCodeModel', false)
			// },
			$route: {
				handler: function (route) {
					if (route.name == 'Home') {
						this.screenTypeNum = 1;
					} else if (route.name == 'roHuiHome' || route.name == 'roHuiIndustry') {
						this.screenTypeNum = 3;
						this.switchPageId = 2;
						this.threeBtnClass = 'roHui_three_btn_style';
						this.$store.commit('changeScreen', 1);
						document.getElementsByTagName('body')[0].className = 'two_background';
					} else if (route.name == 'beilun' || route.name == 'attract' || route.name == 'overView' || route.name == 'smartPark') {
						// // console.log(route)
						this.screenTypeNum = 2;
						this.switchPageId = 1;
						this.threeBtnClass = 'beilun_three_btn_style';
						this.$store.commit('changeScreen', 2);
						document.getElementsByTagName('body')[0].className = 'one_background';
					} else if (route.name == 'feiChiHome') {
						// // console.log(route)
						this.screenTypeNum = 5;
						this.switchPageId = 8;
						this.threeBtnClass = 'feiChi_three_btn_style';
						this.$store.commit('changeScreen', 3);
						document.getElementsByTagName('body')[0].className = 'three_background';
					} else if (route.name == 'jinHui') {
						// // console.log(route)
						this.screenTypeNum = 7;
						this.switchPageId = 9;
						this.threeBtnClass = 'feiChi_three_btn_style';
						this.$store.commit('changeScreen', 4);
						document.getElementsByTagName('body')[0].className = 'three_background';
					} else if (route.name == 'zhongguancunHome') {
						// // console.log(route)
						this.screenTypeNum = 4;
						this.switchPageId = 3;
						this.threeBtnClass = 'zhongguancun_three_btn_style';
						this.$store.commit('changeScreen', 5);
						document.getElementsByTagName('body')[0].className = 'four_background';
					} else if (route.name == 'weixingHome') {
						// // console.log(route)
						this.screenTypeNum = 6;
						this.switchPageId = 6;
						this.threeBtnClass = 'weixingHome_three_btn_style';
						this.$store.commit('changeScreen', 6);
						document.getElementsByTagName('body')[0].className = 'five_background';
					} else if (route.name == 'dahang') {
						this.screenTypeNum = 8;
						this.switchPageId = 4;
						this.threeBtnClass = 'dahang_three_btn_style';
						this.$store.commit('changeScreen', 7);
						document.getElementsByTagName('body')[0].className = 'five_background';
					} else if (route.name == 'haixing') {
						this.screenTypeNum = 9;
						this.switchPageId = 5;
						this.threeBtnClass = 'haixing_three_btn_style';
						this.$store.commit('changeScreen', 8);
						document.getElementsByTagName('body')[0].className = 'five_background';
					} else if (route.name == 'other') {
						this.screenTypeNum = 10;
						this.switchPageId = 7;
						this.threeBtnClass = 'other_three_btn_style';
						this.$store.commit('changeScreen', 9);
						document.getElementsByTagName('body')[0].className = 'five_background';
					} else if (route.name == 'xyds') {
						this.screenTypeNum = 11;
						this.switchPageId = 10;
						this.threeBtnClass = 'haixing_three_btn_style';
						this.$store.commit('changeScreen', 11);
						document.getElementsByTagName('body')[0].className = 'five_background';
					} else if (route.name == 'gdds') {
						this.isGD = true;
						this.threeBtnClass = 'gdds_three_btn_style';
						this.$store.commit('changeScreen', 10);
						document.getElementsByTagName('body')[0].className = 'five_background';
					}
				},
				immediate: true,
			},
		},

		mounted() {
			this.initScale();
		},

		methods: {
			initScale() {
				this.isPhone = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
				if (this.isPhone) {
					let body = document.getElementsByTagName('body')[0];
					body.style.width = '100vw';
					body.style.height = '100vh';
					this.$router.replace({
						name: 'tips',
					});
					return;
				}
				window.onload = function () {
					adjust();
					window.onresize = function () {
						adjust();
					};
				};
				const adjust = function () {
					let winWidth = document.documentElement.clientWidth;
					let body = document.getElementsByTagName('body')[0];
					let scale = winWidth / 1920;
					body.style.cssText = 'transform:scale(' + scale + ');';
				};
				this.tingEquipmen();
				this.$store.dispatch('codeLogin');
			},
			tingEquipmen() {
				window.addEventListener('message', this.goScreen);
				window.addEventListener('message', this.offSetTime);
				window.addEventListener('message', this.goOldSpot);
			},
			goScreen(item) {
				const screenWrap = document.getElementById('screenWrap');
				const _data = item.data;
				const pathName = this.$route.name;
				let msg2 = {
					funcName: 'resetFull',
				};
				let msg = {};
				if (_data.param && _data.param.goStatus) {
					if (pathName == 'xyds') {
						msg = {
							funcName: 'cameraFly',
							param: {
								id: 22,
								title: '馨雅大厦',
								content: '馨雅大厦位于西四环北路63号，地理位置优越，交通便利，附近有多条公交线路经过。楼内多种户型的办公间可供选择，并可根据客户的要求自由分隔，商务设施齐全，周边分布有餐饮、超市、酒店等配套设施。',
							},
						};
					} else if (pathName == 'beilun' || pathName == 'attract' || pathName == 'overView') {
						msg = {
							funcName: 'cameraFly',
							param: {
								id: 5,
								title: '海星医药健康创新园A区',
								content: '中关村科学城医药健康产业园，是中关村科学城首个以生物医药产业为核心的产业园,项目总占地面积28702.44平米，总建筑面积 57695.46平米，由18个单体建筑组成，建筑控高为24米，建筑首层层高4.5米，2~4层高6.4米，项目产业定位为生物医药的研发和中试。',
							},
						};
					} else if (pathName == 'roHuiHome' || pathName == 'roHuiIndustry') {
						msg = {
							funcName: 'cameraFly',
							param: {
								id: 2,
								title: '融汇国际大厦',
								content: '大厦位于北京市西北二环核心商区、总建筑面积45000平米，办公区面积234500平米，入驻企业涵盖安全技术、精准营销、云计算、基础设施等多个领域的行业重点企业，初步形成了业态相对丰富、链条较为完备的金融科技发展生态。',
							},
						};
					} else if (pathName == 'feiChiHome') {
						msg = {
							funcName: 'cameraFly',
							param: {
								id: 3,
								title: '海星医药健康创新园B区',
								content: '项目位于海淀区永丰产业基地丰贤中路9号，用地性质为高新技术产业用地，项目用地33433.56m2，总建筑面积68400 m2，地上建筑面积43400 m2，地下建筑面积25000 m2。控制规划指标，控制高度≤24米，总体容积率1.3，地块绿地率35％，项目打造具备生物医药企业所需的GMP生产厂房和研发基地。',
							},
						};
					} else if (pathName == 'jinHui') {
						msg = {
							funcName: 'cameraFly',
							param: {
								id: 4,
								title: '温泉冰雪体育公园项目',
								content: '位于海淀区温泉镇双坡路11号，总占地面积 1100亩。项目以冰雪运动为主，四季兼顾。项目拥有我市离市区最近的室外中大型滑雪场——北京西山滑雪场，雪道面积12万平米，单雪季接待人次超10万，现已成为海淀区冰雪运动新地标。',
							},
						};
					} else if (pathName == 'zhongguancunHome') {
						msg = {
							funcName: 'cameraFly',
							param: {
								id: 8,
								title: '中关村国际创新大厦',
								content: '中关村国际创新大厦被海淀区政府定位为前沿科技项目，并由海淀区领导亲自为大厦冠名，入驻企业由海淀区投促局进行甄选，均为新技术产业。项目于2015 年4 月16 日开始入驻并全程跟进大厦整体改造，内配套餐饮、咖啡厅、超市等设施一应俱全。北京万佳鑫物业秉承“为您着想、为您服务、让您满意”的宗旨，努力提升服务品质，为客户营造安全、舒适、高雅的办公环境。',
							},
						};
					} else if (pathName == 'weixingHome') {
						msg = {
							funcName: 'cameraFly',
							param: {
								id: 6,
								title: '北京卫星制造厂',
								content: '北京卫星制造厂旧址位于北京市海淀区创业大街——知春路，是北京乃至中国科技、教育、文化资源最为丰富的区域。项目以中关村大街、学院路为轴线，是中关村科学城核心区，也是中关村国家自主创新示范区核心区的核心。北京卫星制造厂是我国东方红一号卫星诞生地，也是我国多个卫星、返回舱诞生地，具有丰富的历史背景。',
							},
						};
					} else if (pathName == 'dahang') {
						msg = {
							funcName: 'cameraFly',
							param: {
								id: 9,
								title: '大行基业大厦',
								content: '大行基业大厦是位于北京市海淀区人大北路33号，环境优雅，美观大方，生态良好，邻里和睦，祥和文明，康乐和谐。物业管理完善，贴心人性化，地理环境优越，交通便利，周边配套设施完善，满足生活所需。',
							},
						};
					} else if (pathName == 'haixing') {
						msg = {
							funcName: 'cameraFly',
							param: {
								id: 10,
								title: '海星医药健康创新园C区',
								content: '海星医药健康创新园C区位于北京市海淀区丰贤中路9号',
							},
						};
					} else if (pathName == 'other') {
						msg = {
							funcName: 'cameraFly',
							param: {
								id: 11,
								title: '丹棱SOHO',
								content: '丹棱SOHO（原中冠大厦）位于北京中关村核心区，丹棱街与海淀东三街交口，北邻中关村广场步行街，南朝海淀医院，东接新中关购物中心，西靠北京市海淀区人民法院。地处中关村商务区核心区域。',
							},
						};
					}
					if (pathName != 'gdds' || pathName != 'group') {
						this.$store.commit('changeLoadingStatus', false);
						this.$store.commit('changeMapTypeStatus', true);
						this.$store.commit('changeAnimateDelay', true);
						setTimeout(() => {
							screenWrap.contentWindow.postMessage(msg2, '*');
							screenWrap.contentWindow.postMessage(msg, '*');
						}, 5000);
					}
				}
			},
			offSetTime(item) {
				if (item.data.funcName == 'offSetTime') {
					this.$store.dispatch('NoautomaticGo');
				}
			},
			goOldSpot(item) {
				if (item.data.funcName == 'goOldSpot') {
					this.$store.commit('changeOldNum');
				}
			},
		},
	};
</script>
<style>
	@font-face {
		font-family: 'DSDIGI1';
		src: url('./assets/fonts/DS-Digital/DS-DIGI-1.ttf');
	}

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		/* text-align: center; */
		color: #2c3e50;
		position: relative;
	}

	#nav {
		padding: 30px;
	}

	#nav a {
		font-weight: bold;
		color: #2c3e50;
	}

	#nav a.router-link-exact-active {
		color: #42b983;
	}

	.loading-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		z-index: 99999;
		width: 1920px;
		height: 1080px;
		top: 0;
		left: 0;
		background: #000;
	}

	.loading-text {
		color: rgb(253, 175, 56);
		font-family: '微软雅黑';
	}

	/* #wx_qrcode {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2000;
        top: 0;
        background: rgb(83, 82, 82);
        display: flex;
        justify-content: center;
        align-items: center;
    } */
	.no_permission {
		position: absolute;
		background: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 2100;
		width: 500px;
		height: 200px;
		top: calc(50% - 200px / 2);
		left: calc(50% - 500px / 2);
		font-size: 20px;
	}

	.close_no_modal {
		width: 40px;
		position: absolute;
		right: 10px;
		top: 8px;
		cursor: pointer;
	}

	@font-face {
		font-family: 'smalldsfont';
		src: url(./assets/font/DS-Digital/DS-DIGI-1.ttf);
	}

	@font-face {
		font-family: 'bigdsfont';
		src: url(./assets/font/DS-Digital/DS-DIGIB-2.ttf);
	}

	.num {
		font-family: 'bigdsfont';
	}

	.num_big {
		font-family: 'bigdsfont';
	}
</style>
