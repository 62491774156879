<template>
    <div class="home_right_content">
        <div class="home_right_wrapBg">
        </div>
        <div class="home_right_wrap">
          <Summarize />
          <Financing />
          <Enterprise v-if="showBox" />
        </div>
    </div>
</template>
<script>
    import '@/assets/css/home/home.css'
    import Summarize from '@/components/homeRightCont/summarize.vue'
    import Financing from '@/components/homeRightCont/financing.vue'
    import Enterprise from '@/components/homeRightCont/enterprise.vue'
    export default {
      components: {
        Summarize,
        Financing,
        Enterprise
      },
      data () {
          return {
            showBox: false
          }
      },
      mounted () {
          setTimeout(() => {
                this.showBox = true
          },1000)
      }
    }
</script>
<style lang="less" scoped>
  
  
</style>