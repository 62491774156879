<!--
 * @Author: PC
 * @Date: 2022-07-08 15:39:33
 * @LastEditTime: 2022-08-08 20:11:30
 * @Description: 请您输入文件描述
-->
<template>
    <div class="homeLeftCont_introduce">
        <Title :txt="'产业介绍'" />
        <div class="introduce_cont">
          <div class="introduce_cont_txt">
            <p>园区总建筑面积5.8万平米，汇集联影医疗、百图生科、志道生物、伟德杰、伟杰信、昱言、鑫康合、圆因生物、因诺维康、康美特、深势科技、博润天汇、当代超临界、剂泰、百放英库和巢生源科等17家生物医药高精尖企业，涵盖化学药剂研发平台、蛋白及多肽药、基因及细胞治疗药，抗体药，医疗器械，实验平台等领域。</p>
          </div>
        </div>
    </div>
</template>
<script>
    import '@/assets/css/home/homeLeft/introduce.less'
    import Title from '@/components/Title.vue'
    export default {
      components: {
        Title
      }
    }
</script>