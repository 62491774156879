/*
 * @Author: PC
 * @Date: 2022-04-24 14:30:18
 * @LastEditTime: 2023-08-26 16:39:38
 * @Description: 请您输入文件描述
 */
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      per: '海新域城市更新集团'
    },
    component: () => import("@/views/group.vue"),
  },
  {
    path: "/beilun",
    name: "beilun",
    meta: {
      per: '海星医药健康创新园A区'
    },
    component: () => import("@/views/overView.vue"),
  },
  {
    path: "/attract",
    name: "attract",
    meta: {
      per: '海星医药健康创新园A区'
    },
    component: () => import("@/views/attract.vue"),
  },
  {
    path: "/overView",
    name: "overView",
    meta: {
      per: '海星医药健康创新园A区'
    },
    component: Home,
  },
  {
    path: "/roHuiHome",
    name: "roHuiHome",
    meta: {
      per: '融汇国际大厦'
    },
    component: () => import("@/views/roHui/roHuiHome.vue"),
  },
  {
    path: "/roHuiIndustry",
    name: "roHuiIndustry",
    meta: {
      per: '融汇国际大厦'
    },
    component: () => import("@/views/roHui/roHuiIndustry.vue"),
  },
  {
    path: "/feiChiHome",
    name: "feiChiHome",
    meta: {
      per: '海星医药健康创新园B区'
    },
    component: () => import("@/views/feiChi/feiChiHome.vue"),
  },
  {
    path: "/jinHui",
    name: "jinHui",
    meta: {
      per: '金汇'
    },
    component: () => import("@/views/jinHui.vue"),
  },
  {
    path: "/zhongguancunHome",
    name: "zhongguancunHome",
    meta: {
      per: '中关村国际创新大厦'
    },
    component: () => import("@/views/zhongguancun/zhongguancunHome.vue"),
  },
  {
    path: "/zhongguancunAttract",
    name: "zhongguancunAttract",
    meta: {
      per: '中关村国际创新大厦'
    },
    component: () => import("@/views/zhongguancun/zhongguancunAttract.vue"),
  },
  {
    path: "/zhongguancunIndustry",
    name: "zhongguancunIndustry",
    meta: {
      per: '中关村国际创新大厦'
    },
    component: () => import("@/views/zhongguancun/zhongguancunIndustry.vue"),
  },
  {
    path: "/weixingHome",
    name: "weixingHome",
    meta: {
      per: '北京卫星制造厂'
    },
    component: () => import("@/views/weixing/weixingHome.vue"),
  },
  {
    path: "/dahang",
    name: "dahang",
    meta: {
      per: '大行基业'
    },
    component: () => import("@/views/dahang.vue"),
  },
  {
    path: "/haixing",
    name: "haixing",
    meta: {
      per: '海星医药'
    },
    component: () => import("@/views/haixing.vue"),
  },
  {
    path: "/other",
    name: "other",
    meta: {
      per: '其他资产'
    },
    component: () => import("@/views/other.vue"),
  },
  {
    path: "/xyds",
    name: "xyds",
    meta: {
      per: '馨雅大厦'
    },
    component: () => import("@/views/xyds.vue"),
  },
  {
    path: "/gdds",
    name: "gdds",
    meta: {
      per: '高德大厦'
    },
    component: () => import("@/views/gdds.vue"),
  },
  {
    path: "/smartPark",
    name: "smartPark",
    meta: {
      per: 'smartPark'
    },
    component: () => import("@/views/smartPark.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login.vue"),
  },
  {
    path: "/tips",
    name: "tips",
    component: () => import("@/tips.vue"),
  },
  {
    path: "/err404",
    name: "err404",
    component: () => import("@/views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  next()
  // if (to.name == 'Login') {
  //   // // console.log('333333-----')
  //   next()
  // }
  // let code = localStorage.getItem('code') ? JSON.parse(localStorage.getItem('code')) : []
  // // // console.log('code', to)
  // let power = code.userData.permissions
  // // next()
  // let isPower = !to.meta.per ? true : false;
  // // // console.log('to',to)
  // for (let key in power) {
  //   // // console.log('-----',power[key], to.name)
  //   if (power[key] == to.meta.per) {
  //     isPower = true;
  //     break
  //   }
  // }

  // if (isPower === false ) {
  //   // // console.log('222222-----')
  //   next({path: '/'})
  // } else {
  //   next()/
  // }
})


export default router;
