import { getSupporting,getParking,getWhole,getTheFloor } from '@/api/beilun'

const attract = {
  state: {
    test: '123',
    homeInfo: false,
    dataInfo: {
      allCars: 0,
      parking: 263,
      leased: 0, 
      notRented: 0,
      occupancy_rate: 0,
      rotation: [
        /* {
          pic: require('@/assets/images/attractbottomLeftRotation1.png'),
          name: '专属食堂',
          introduce: '1000平米可容纳300人同时就餐的美食城专属大厅食堂',
          address: '13栋负一层'
        },
        {
          pic: require('@/assets/images/attractbottomLeftRotation2.png'),
          name: '休闲咖啡',
          introduce: '满足都市办公休闲的娱乐场所',
          address: ''
        },
        {
          pic: require('@/assets/images/attractbottomLeftRotation3.png'),
          name: '便利店',
          introduce: '24小时超市满足购物需求',
          address: ''
        },
        {
          pic: require('@/assets/images/attractbottomLeftRotation4.png'),
          name: '健身房',
          introduce: '快乐运动 品质生活',
          address: ''
        }, */
      ],
      list: [
        /* {buldNum: '1号楼',area: 2203.69, status: 1,name: '', lessor: '伟杰信'},
        {buldNum: '2号楼',area: 1537.12, status: 1,name: '北京伟杰信生物科技有限公司', lessor: '伟杰信'},
        {buldNum: '3号楼',area: 4978.31, status: 1,name: '北京联影智能影像技术研究院', lessor: '影和医学'},
        {buldNum: '4号楼',area: 4998.42, status: 1,name: '北京志道生物科技有限公司', lessor: '志道生物'},
        {buldNum: '5号楼',area: 1701.36, status: 1,name: '北京伟德杰生物科技有限公司', lessor: '韦德杰'},
        {buldNum: '6号楼',area: 1701.20, status: 1,name: '北京康美特科技股份有限公司', lessor: '康美特'},
        {buldNum: '7号楼',area: 1218.90, status: 1,name: '北京康美特科技股份有限公司', lessor: '康美特'},
        {buldNum: '8号楼',area: 1218.90, status: 1,name: '北京伟德杰生物科技有限公司', lessor: '韦德杰'},
        {buldNum: '9号楼1F/2F',area: 1949.62, status: 1,name: '北京因诺惟康医药科技有限公司', lessor: '因诺惟康'},
        {buldNum: '9号楼3F',area: 1053.51, status: 1,name: '昱言科技（北京）有限公司', lessor: '昱言'},
        {buldNum: '9号楼4F',area: 1051.92, status: 1,name: '北京剂泰医药科技有限责任公司', lessor: '剂泰医药'},
        {buldNum: '9号楼-1F',area: 923.35, status: 1,name: '巢生源科（北京）科技管理有限公司', lessor: '巢生源科'},


        {buldNum: '10号楼1F/2F',area: 1977.07, status: 1,name: '百图生科（北京）智能技术有限公司', lessor: '百图生科'},
        {buldNum: '10号楼3F/4F/-1F/附属',area: 2349.91, status: 1,name: '北京百图生科生物技术有限公司', lessor: '百图生科'},
        
        {buldNum: '11号楼',area: 1220.18, status: 1,name: '北京志道生物科技有限公司', lessor: '志道生物'},
        {buldNum: '12号楼',area: 1220.20, status: 0,name: '', lessor: ''},
        {buldNum: '13号楼',area: 5337.28, status: 0,name: '', lessor: ''},
        {buldNum: '14号楼',area: 1184.98, status: 1,name: '北京博润天慧科技有限公司', lessor: '博润天慧'},
        {buldNum: '15号楼',area: 1218.81, status: 2,name: '', lessor: ''},
        {buldNum: '16号楼',area: 4072.00, status: 1,name: '百放英库医药科技（北京）有限公司', lessor: '百放生物'},
        {buldNum: '17号楼',area: 1220.18, status: 1,name: '北京鑫康合生物医药科技有限公司', lessor: '鑫康合'},
        {buldNum: '18号楼',area: 1201.54, status: 1,name: '圆因（北京）生物科技有限公司', lessor: '圆因'}, */
      ],
      otherList: [
       /*  {buldNum: '4层',area: 737.41, status: 0,name: '', lessor: ''},
        {buldNum: '3层',area: 726.17, status: 0,name: '', lessor: ''},
        {buldNum: '2层',area: 748.98, status: 3,name: '', lessor: ''},
        {buldNum: '1层',area: 686.44, status: 0,name: '', lessor: ''},
        {buldNum: '负一层',area: 1173.44, status: 1,name: '', lessor: '食堂'},
        {buldNum: '负二层',area: 1172.01, status: 0,name: '', lessor: ''},
        {buldNum: '附属层',area: 92.83, status: 0,name: '', lessor: ''}, */
      ]
    }
  },
  mutations: {
    
  },
  actions: {
      getAttractData ({ state }) {
        getSupporting().then(data => {
            // console.log('配套设施',data, state)
            state.dataInfo.rotation = [...data.data]
        })
        getParking().then(data => {
            // console.log('车辆数量',data)
            const _data = data.data[0]
            state.dataInfo.allCars = Number(_data.car_num)
            state.dataInfo.notRented = Number(_data.not_rent)
            state.dataInfo.leased = Number(_data.have_rent)
            state.dataInfo.occupancy_rate = Number(_data.occupancy_rate)
        })
        getWhole().then(data => {
            // console.log('园区运营情况', data)
            state.dataInfo.list = [ ...data.data ]
        })
        getTheFloor().then(data => {
            // console.log('13号楼', data)
            state.dataInfo.otherList = [...data.data]
        })
      }
  }
}

export default attract