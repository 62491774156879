<template>
	<div :class="`bottom-switch bottom-switch-${pageId}`">
		<div class="switch-button switch-button-prev" @click="onSwitchPage('prev')"></div>
		<div id="switchContainer" class="switch-container-wrap">
			<div class="switch-container">
				<div v-for="(item, index) in list" :key="item.id" :id="index == 0 && 'switchItem'" class="switch-item" :class="{ 'switch-item-active': pageId == item.id }" @click="changeScreenItem(item)">
					<div class="switch-wrap">
						<div class="switch-cover">
							<img :src="item.imgUrl" :alt="item.name" />
						</div>
						<div class="switch-name">
							<p>{{ item.name }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="switch-button switch-button-next" @click="onSwitchPage('next')"></div>
	</div>
</template>
<script>
	export default {
		props: {
			pageId: {
				type: Number,
				default: 1,
			},
		},
		computed: {},
		data() {
			return {
				code: [],
				permissions: {},
				list: [],
				screenList: [
					{
						id: 10,
						oneId: 22,
						name: '馨雅大厦',
						perName: '四季青科技园·馨雅大厦_fe',
						pathName: 'xyds',
						imgUrl: require('@/assets/images/screen_image9.png'),
						msg: {
							funcName: 'cameraFly',
							param: {
								id: 22,
								title: '馨雅大厦',
								content: '馨雅大厦位于西四环北路63号，地理位置优越，交通便利，附近有多条公交线路经过。楼内多种户型的办公间可供选择，并可根据客户的要求自由分隔，商务设施齐全，周边分布有餐饮、超市、酒店等配套设施。',
							},
						},
					},
					{
						id: 1,
						oneId: 2,
						name: '海星医药健康创新园A区',
						perName: '海星医药健康创新园A区',
						pathName: 'beilun',
						imgUrl: require('@/assets/images/screen_image.jpg'),
						msg: {
							funcName: 'cameraFly',
							param: {
								id: 5,
								title: '海星医药健康创新园A区',
								content: '中关村科学城医药健康产业园，是中关村科学城首个以生物医药产业为核心的产业园,项目总占地面积28702.44平米，总建筑面积 57695.46平米，由18个单体建筑组成，建筑控高为24米，建筑首层层高4.5米，2~4层高6.4米，项目产业定位为生物医药的研发和中试。',
							},
						},
					},
					{
						id: 2,
						oneId: 4,
						name: '融汇国际大厦',
						perName: '融汇国际大厦',
						pathName: 'roHuiHome',
						imgUrl: require('@/assets/images/screen_image2.jpeg'),
						msg: {
							funcName: 'cameraFly',
							param: {
								id: 2,
								title: '融汇国际大厦',
								content: '大厦位于北京市西北二环核心商区、总建筑面积45000平米，办公区面积234500平米，入驻企业涵盖安全技术、精准营销、云计算、基础设施等多个领域的行业重点企业，初步形成了业态相对丰富、链条较为完备的金融科技发展生态。',
							},
						},
					},
					{
						id: 3,
						oneId: 6,
						name: '中关村国际创新大厦',
						perName: '中关村国际创新大厦',
						pathName: 'zhongguancunHome',
						imgUrl: require('@/assets/images/screen_image6.jpg'),
						msg: {
							funcName: 'cameraFly',
							param: {
								id: 8,
								title: '中关村国际创新大厦',
								content: '中关村国际创新大厦被海淀区政府定位为前沿科技项目，并由海淀区领导亲自为大厦冠名，入驻企业由海淀区投促局进行甄选，均为新技术产业。项目于2015 年4 月16 日开始入驻并全程跟进大厦整体改造，内配套餐饮、咖啡厅、超市等设施一应俱全。北京万佳鑫物业秉承“为您着想、为您服务、让您满意”的宗旨，努力提升服务品质，为客户营造安全、舒适、高雅的办公环境。',
							},
						},
					},
					{
						id: 4,
						oneId: 9,
						name: '大行基业',
						perName: '大行基业_fe',
						pathName: 'dahang',
						imgUrl: require('@/assets/images/dahang/cover.png'),
						msg: {
							funcName: 'cameraFly',
							param: {
								id: 9,
								title: '大行基业大厦',
								content: '大行基业大厦是位于北京市海淀区人大北路33号，环境优雅，美观大方，生态良好，邻里和睦，祥和文明，康乐和谐。物业管理完善，贴心人性化，地理环境优越，交通便利，周边配套设施完善，满足生活所需。',
							},
						},
					},
					{
						id: 5,
						oneId: 10,
						name: '海星医药健康创新园C区',
						perName: '海星医药健康创新园C区_fe',
						pathName: 'haixing',
						imgUrl: require('@/assets/images/haixing/cover.jpg'),
						msg: {
							funcName: 'cameraFly',
							param: {
								id: 10,
								title: '海星医药健康创新园C区',
								content: '海星医药健康创新园C区位于北京市海淀区丰贤中路9号',
							},
						},
					},
					{
						id: 6,
						oneId: 7,
						name: '北京卫星制造厂',
						perName: '北京卫星制造厂',
						pathName: 'weixingHome',
						imgUrl: require('@/assets/images/screen_image8.jpg'),
						msg: {
							funcName: 'cameraFly',
							param: {
								id: 6,
								title: '北京卫星制造厂',
								content: '北京卫星制造厂旧址位于北京市海淀区创业大街——知春路，是北京乃至中国科技、教育、文化资源最为丰富的区域。项目以中关村大街、学院路为轴线，是中关村科学城核心区，也是中关村国家自主创新示范区核心区的核心。北京卫星制造厂是我国东方红一号卫星诞生地，也是我国多个卫星、返回舱诞生地，具有丰富的历史背景。',
							},
						},
					},
					{
						id: 7,
						oneId: 11,
						name: '其他资产',
						perName: '其他资产_fe',
						pathName: 'other',
						imgUrl: require('@/assets/images/other/cover.png'),
						msg: {
							funcName: 'cameraFly',
							param: {
								id: 11,
								title: '丹棱SOHO',
								content: '丹棱SOHO（原中冠大厦）位于北京中关村核心区，丹棱街与海淀东三街交口，北邻中关村广场步行街，南朝海淀医院，东接新中关购物中心，西靠北京市海淀区人民法院。地处中关村商务区核心区域。',
							},
						},
					},
					{
						id: 8,
						oneId: 3,
						name: '海星医药健康创新园B区',
						perName: '海星医药健康创新园B区',
						pathName: 'feiChiHome',
						imgUrl: require('@/assets/images/screen_image4.jpeg'),
						msg: {
							funcName: 'cameraFly',
							param: {
								id: 3,
								title: '海星医药健康创新园B区',
								content: '项目位于海淀区永丰产业基地丰贤中路9号，用地性质为高新技术产业用地，项目用地33433.56m2，总建筑面积68400 m2，地上建筑面积43400 m2，地下建筑面积25000 m2。控制规划指标，控制高度≤24米，总体容积率1.3，地块绿地率35％，项目打造具备生物医药企业所需的GMP生产厂房和研发基地。',
							},
						},
					},
					{
						id: 9,
						oneId: 5,
						name: '温泉冰雪体育公园项目',
						perName: '温泉冰雪体育公园项目',
						pathName: 'jinHui',
						imgUrl: require('@/assets/images/screen_image3.png'),
						msg: {
							funcName: 'cameraFly',
							param: {
								id: 4,
								title: '温泉冰雪体育公园项目',
								content: '位于海淀区温泉镇双坡路11号，总占地面积 1100亩。项目以冰雪运动为主，四季兼顾。项目拥有我市离市区最近的室外中大型滑雪场——北京西山滑雪场，雪道面积12万平米，单雪季接待人次超10万，现已成为海淀区冰雪运动新地标。',
							},
						},
					},
				],
				scrollType: '',
			};
		},
		watch: {
			$route: {
				handler: function (route) {
					const { name } = route;
					name && this.handleScroll(name);
				},
				immediate: true,
			},
		},
		methods: {
			handleScroll() {
				let scrollTo = {
					el: '#switchItem',
					container: '#switchContainer',
					offset: 0,
					x: true,
					y: false,
				};
				this.$nextTick(() => {
					if (this.list.length > 0) {
						let offset;
						const switchContainer = document.querySelector('.switch-container-wrap');
						const switchItem = document.querySelector('.switch-item-active');
						const containerW = switchContainer.offsetWidth;
						const itemW = switchItem.offsetWidth;
						const itemX = switchItem.offsetLeft - 60;
						const itemPost = itemX + itemW;
						if (this.scrollType == 'prev') {
							if (itemPost <= 716) {
								offset = 0;
							} else {
								offset = containerW + itemPost;
							}
						}

						if (this.scrollType == 'next') {
							if (itemPost > containerW) {
								offset = itemX;
							} else {
								offset = 0;
							}
						}
						scrollTo = { ...scrollTo, ...{ offset } };
						this.$scrollTo('#switchItem', 500, scrollTo);
					}
				});
			},
			onSwitchPage(type) {
				let index = this.list.findIndex(item => item.pathName == this.$route.name);
				if (type == 'prev') {
					this.scrollType = 'prev';
					index = index == 0 ? this.list.length - 1 : index - 1;
				}

				if (type == 'next') {
					this.scrollType = 'next';
					index = index == this.list.length - 1 ? 0 : index + 1;
				}
				this.changeScreenItem(this.list[index]);
			},
			changeScreenItem(item) {
				// this.$store.state.screenNumber = item.id;
				this.$store.state.home.homeInfo = false;
				this.$router.push({ name: item.pathName });
				if (this.$store.state.mapType) {
					this.roamPosition(item);
				}
			},
			roamPosition(item) {
				const screenWrap = document.getElementById('screenWrap');
				const msg = {
					funcName: 'showAllModal',
					param: { id: 5 },
				};
				screenWrap.contentWindow.postMessage(item.msg, '*');
				screenWrap.contentWindow.postMessage(msg, '*');
			},
		},
		mounted() {
			const loginValue = JSON.parse(localStorage.getItem('code'));
			let code = [];
			if (loginValue) {
				for (let key in loginValue.userData.permissions) {
					code.push(loginValue.userData.permissions[key]);
				}
				code = Array.from(new Set(code));
				this.code = code;
				this.screenList.map(item => {
					this.code.map(jtem => {
						if (item.perName == jtem) {
							this.list.push(item);
						}
					});
				});
			}
		},
	};
</script>
<style lang="less" scoped>
	.bottom-switch {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: absolute;
		z-index: 1010;
		height: 180px;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		user-select: none;

		.switch-container-wrap {
			max-width: 820px;
			overflow-x: scroll;

			&::-webkit-scrollbar {
				display: none;
			}

			.switch-container {
				display: flex;
				align-items: center;

				.switch-item {
					cursor: pointer;
					position: relative;
					width: 92px;
					height: 78px;
					flex-shrink: 0;
					margin-right: 12px;
					background-size: 100%;
					background-repeat: no-repeat;
					transition: all 0.2s;

					&:last-child {
						margin-right: 0;
					}

					.switch-wrap {
						position: absolute;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						overflow: hidden;
						width: 92px;
						height: 70px;
						top: 4px;
						left: 50%;
						transform: translateX(-50%);

						.switch-cover {
							width: 78px;
							height: 43px;

							img {
								width: 100%;
								max-height: 100%;
							}
						}

						.switch-name {
							width: 90%;
							font-size: 14px;
							font-weight: 400;
							color: #ffffff;
							text-align: center;

							p {
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
							}
						}
					}
				}

				.switch-item-active {
					width: 196px;
					height: 188px;

					.switch-wrap {
						top: 10px;
						width: 187px;
						height: 133px;

						.switch-cover {
							width: 166px;
							height: 94px;
							margin-bottom: 4px;
							transition: width 0.2s;
						}

						.switch-name {
							font-size: 20px;
							font-weight: bold;
						}
					}
				}
			}
		}

		.switch-button {
			width: 50px;
			height: 100px;
			outline: none;
			cursor: pointer;
			background-size: 100%;
			background-repeat: no-repeat;

			&:active {
				opacity: 0.5;
			}
		}

		.switch-button-prev {
			margin-right: 10px;
		}

		.switch-button-next {
			margin-left: 10px;
		}
	}

	.loop(@i) when (@i>0) {
		.loop((@i - 1));

		.bottom-switch-@{i} {
			.switch-item {
				background-image: url('../assets/images/bottomSwitch/item-@{i}-n.png');
			}

			.switch-item-active {
				background-image: url('../assets/images/bottomSwitch/item-@{i}-a.png');
			}

			.switch-button-prev {
				background-image: url('../assets/images/bottomSwitch/item-@{i}-l.png');
			}

			.switch-button-next {
				background-image: url('../assets/images/bottomSwitch/item-@{i}-r.png');
			}
		}
	}

	.loop(10);

	.bottom-switch-1 {
		// bottom: 33px;

		.switch-container-wrap .switch-container .switch-item-active .switch-wrap .switch-name {
			color: #d8eeff;
		}
	}

	.bottom-switch-2 {
		// bottom: 42px;

		.switch-container-wrap .switch-container .switch-item-active .switch-wrap .switch-name {
			color: #ddd0c1;
		}
	}

	.bottom-switch-3 {
		// bottom: 10px;

		.switch-container-wrap .switch-container .switch-item-active .switch-wrap .switch-name {
			color: #33d1a1;
		}
	}

	.bottom-switch-4 {
		.switch-container-wrap .switch-container .switch-item-active .switch-wrap .switch-name {
			color: #4ae5fa;
		}
	}

	.bottom-switch-5 {
		.switch-container-wrap .switch-container .switch-item-active .switch-wrap .switch-name {
			color: #18f5f5;
		}
	}

	.bottom-switch-6 {
		.switch-container-wrap .switch-container .switch-item-active .switch-wrap .switch-name {
			color: #d8eeff;
		}
	}

	.bottom-switch-7 {
		.switch-container-wrap .switch-container .switch-item-active .switch-wrap .switch-name {
			color: #d8eeff;
		}
	}

	.bottom-switch-8 {
		// bottom: 50px;

		.switch-container-wrap .switch-container .switch-item-active .switch-wrap .switch-name {
			color: #1cd5a2;
		}
	}

	.bottom-switch-9 {
		.switch-container-wrap .switch-container .switch-item-active .switch-wrap .switch-name {
			color: #d8eeff;
		}
	}

	.bottom-switch-10 {
		.switch-container-wrap .switch-container .switch-item-active .switch-wrap .switch-name {
			color: #d8eeff;
		}
	}
</style>
