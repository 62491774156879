import service from '@/utils/http'

export function signUp (data) {
    return service({
        url: 'login_fe',
        method: 'post',
        data:data
    })
}

export function logout (data) {
    return service({
        url: 'logout_fe',
        method: 'post',
        data: data
    })
}

export function getAccountData (data) {
    return service({
        url: 'get_user',
        method: 'post',
        data:data
    })
}