/*
 * @Author: PC
 * @Date: 2022-07-08 15:39:33
 * @LastEditTime: 2023-08-31 21:45:12
 * @Description: 请您输入文件描述
 */
import Vue from "vue";
import Vuex from "vuex";
import router from "../router";

import home from "@/store/beilun/home.js";
import attract from "@/store/beilun/attract.js";
import overView from "@/store/beilun/overView.js";

import zhongguancunHome from "@/store/zhongguancun/zhongguancunHome";
import zhongguancunAttract from "@/store/zhongguancun/zhongguancunAttract";
import zhongguancunIndustry from "@/store/zhongguancun/zhongguancunIndustry";

import weixingHome from "@/store/weixing/weixingHome";

import { set_GetUUIDFun } from "@/utils/utils.js";
import { getProjectOverview, getUserData } from "@/api/beilun";
// getAccountData
import { signUp, getAccountData } from "@/api/login";
import { notification } from "ant-design-vue";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        screenNumber: 2,
        companyContent: {},
        companyShow: false,
        companyName: "",
        showDataValue: true,
        loadingOk: true,
        rohuiShowValue: false,
        setAllValue: null,
        goNum: 1,
        oldNum: 0,
        goStatus: false,
        showCodeModel: true,
        animateDelay: false,
        mapType: false,
        feichiDataObj: {},
        noPermissionValue: false,
        showMapValue: false,
        loginType: 'code',//passwd: 密码; code: 二维码
        loginForm: {
            account: "",
            password: "",
        },
        loginRules: {
            account: [{ required: true, message: "请输入账号", trigger: "blur" }],
            password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        },
        dispatchValue: false,
        keepPassWord: false,
        powerStroage: localStorage.getItem("code") ? JSON.parse(localStorage.getItem("code")).userData.permissions : [],
        newGoNum: 0,
        allPark: [
            {
                id: 1,
                oneId: 5,
                name: "海星医药健康创新园A区",
                perName: "海星医药健康创新园A区",
                title: "海星医药健康创新园A区",
                content:
                    "中关村科学城医药健康产业园，是中关村科学城首个以生物医药产业为核心的产业园,项目总占地面积28702.44平米，总建筑面积 57695.46平米，由18个单体建筑组成，建筑控高为24米，建筑首层层高4.5米，2~4层高6.4米，项目产业定位为生物医药的研发和中试。",
                tranValue: 139,
                pathName: "beilun",
                show: false,
                imgUrl: require("@/assets/images/screen_image.jpg"),
            },
            {
                id: 2,
                oneId: 3,
                name: "融汇",
                perName: "融汇国际大厦",
                title: "融汇国际大厦",
                content:
                    "大厦位于北京市西北二环核心商区、总建筑面积45000平米，办公区面积234500平米，入驻企业涵盖安全技术、精准营销、云计算、基础设施等多个领域的行业重点企业，初步形成了业态相对丰富、链条较为完备的金融科技发展生态。",
                tranValue: 30,
                pathName: "roHuiHome",
                show: false,
                imgUrl: require("@/assets/images/screen_image4.jpeg"),
            },
            {
                id: 5,
                oneId: 8,
                name: "中关村国际创新大厦",
                perName: "中关村国际创新大厦",
                title: "中关村国际创新大厦",
                content:
                    "中关村国际创新大厦被海淀区政府定位为前沿科技项目，并由海淀区领导亲自为大厦冠名，入驻企业由海淀区投促局进行甄选，均为新技术产业。项目于2015 年4 月16 日开始入驻并全程跟进大厦整体改造，内配套餐饮、咖啡厅、超市等设施一应俱全。北京万佳鑫物业秉承“为您着想、为您服务、让您满意”的宗旨，努力提升服务品质，为客户营造安全、舒适、高雅的办公环境。",
                tranValue: -310,
                pathName: "zhongguancunHome",
                show: false,
                imgUrl: require("@/assets/images/screen_image6.jpg"),
            },
            {
                id: 3,
                oneId: 2,
                name: "海星医药健康创新园B区",
                perName: "海星医药健康创新园B区",
                title: "海星医药健康创新园B区",
                content:
                    "项目位于海淀区永丰产业基地丰贤中路9号，用地性质为高新技术产业用地，项目用地33433.56m2，总建筑面积68400 m2，地上建筑面积43400 m2，地下建筑面积25000 m2。控制规划指标，控制高度≤24米，总体容积率1.3，地块绿地率35％，项目打造具备生物医药企业所需的GMP生产厂房和研发基地。",
                tranValue: -83,
                pathName: "feiChiHome",
                show: false,
                imgUrl: require("@/assets/images/screen_image2.jpeg"),
            },
            {
                id: 6,
                oneId: 6,
                name: "北京卫星制造厂",
                perName: "北京卫星制造厂",
                title: "北京卫星制造厂",
                content:
                    "北京卫星制造厂旧址位于北京市海淀区创业大街——知春路，是北京乃至中国科技、教育、文化资源最为丰富的区域。项目以中关村大街、学院路为轴线，是中关村科学城核心区，也是中关村国家自主创新示范区核心区的核心。北京卫星制造厂是我国东方红一号卫星诞生地，也是我国多个卫星、返回舱诞生地，具有丰富的历史背景。",
                tranValue: -430,
                pathName: "weixingHome",
                show: false,
                imgUrl: require("@/assets/images/screen_image8.jpg"),
            },
            {
                id: 4,
                oneId: 4,
                name: "温泉冰雪体育公园项目",
                perName: "温泉冰雪体育公园项目",
                title: "温泉冰雪体育公园项目",
                content:
                    "位于海淀区温泉镇双坡路11号，总占地面积 1100亩。项目以冰雪运动为主，四季兼顾。项目拥有我市离市区最近的室外中大型滑雪场——北京西山滑雪场，雪道面积12万平米，单雪季接待人次超10万，现已成为海淀区冰雪运动新地标。",
                tranValue: -190,
                pathName: "jinHui",
                show: false,
                imgUrl: require("@/assets/images/screen_image3.png"),
            },
            {
                id: 7,
                oneId: 9,
                name: "大行基业大厦",
                perName: "大行基业大厦",
                title: "大行基业大厦",
                content:
                    "大行基业大厦是位于北京市海淀区人大北路33号，环境优雅，美观大方，生态良好，邻里和睦，祥和文明，康乐和谐。物业管理完善，贴心人性化，地理环境优越，交通便利，周边配套设施完善，满足生活所需。",
                tranValue: -190,
                pathName: "dahang",
                show: false,
                imgUrl: require("@/assets/images/dahang/cover.png"),
            },
            {
                id: 8,
                oneId: 10,
                name: "海星医药健康创新园C区",
                perName: "海星医药健康创新园C区",
                title: "海星医药健康创新园C区",
                content:
                    "海星医药健康创新园C区位于北京市海淀区丰贤中路9号",
                tranValue: -190,
                pathName: "haixing",
                show: false,
                imgUrl: require("@/assets/images/dahang/cover.png"),
            },
        ],
        powerCont: [],
    },
    mutations: {
        isDot(num) {
            let flagNum = 0;
            if (String(num).indexOf(".") > -1) {
                flagNum = "2";
            } else {
                flagNum = "0";
            }
            return flagNum;
        },
        changeScreen(state, department) {
            state.screenNumber = department;
        },
        clearData(state) {
            state.companyContent = "";
            state.companyName = "";
            state.companyShow = false;
        },
        changeShowFun(state, deprtment) {
            state.showDataValue = deprtment;
        },
        changeMapType(state) {
            // // console.log('changeMapType))))))', state.powerStroage)
            state.mapType = !state.mapType;
        },
        changeMapTypeStatus(state, deprtment) {
            state.mapType = deprtment
        },
        changeLoadingStatus(state, deprtment) {
            state.loadingOk = deprtment;
        },
        changeRohuiShow(state, department) {
            state.rohuiShowValue = department;
        },
        changeAnimateDelay(state, department) {
            state.animateDelay = department;
        },
        changeShowCodeModel(state) {
            state.showCodeModel = !state.showCodeModel;
        },
        changeShowMapValue(state, val) {
            state.showMapValue = val;
        },
        changeOldNum(state) {
            if (state.oldNum != 0) {
                state.oldNum = 0;
            } else {
                return;
            }
        },
        getPower(state) {
            // console.log('%%%%%%%%%%%%%%',localStorage.getItem('code'))
            state.powerStroage = localStorage.getItem("code") ? JSON.parse(localStorage.getItem("code")).userData.permissions : [];
            // console.log('))))))', state.powerStroage)
        },
    },
    actions: {
        changeCompany({ state }, val) {
            if (state.companyShow) {
                state.companyShow = false
                setTimeout(() => {
                    state.companyShow = true
                    state.companyContent = val
                    state.companyName = val.company_name;
                }, 200);
                return
            }
            state.companyShow = true;
            state.companyContent = val;
            state.companyName = val.company_name;
        },
        offModal({ state }) {
            state.companyContent = "";
            state.companyName = "";
            state.companyShow = false;
        },
        automaticGo({ state, dispatch, commit }, val) {
            // console.log(val, 11111);
            // dispatch('powerArr')
            let powerObj = state.powerStroage;
            let allPark = state.allPark;
            state.powerCont = [];
            for (let i = 0; i < allPark.length; i++) {
                for (let key in powerObj) {
                    if (powerObj[key] == allPark[i].perName) {
                        state.powerCont.push(allPark[i]);
                    }
                }
            }
            state.goStatus = val;
            //   state.mapType = true
            const screenWrap = document.getElementById("screenWrap");
            if (val) {
                dispatch("roamPosition");
                //   commit('changeAnimateDelay', false)
                // commit('getPower')
                // state.powerCont = localStorage.getItem('code') ? JSON.parse(localStorage.getItem('code')).userData.permissions : []
                let current = state.powerCont[state.newGoNum];
                console.log("🚀 ~ file: index.js ~ line 1 ~ state.setAllValue=setInterval ~ current", current);
                let msg = {
                    funcName: "cameraFly",
                    param: {
                        id: current.oneId,
                        dataDelay: true,
                        title: current.title,
                        content: current.content,
                    },
                };
                if (state.powerCont.length - 1 > state.newGoNum) {
                    state.newGoNum++;
                } else {
                    state.newGoNum = 0;
                }
                router.push({ name: current.pathName });
                screenWrap.contentWindow.postMessage(msg, "*");
                commit("changeAnimateDelay", false);
                state.setAllValue = setInterval(() => {
                    let current = state.powerCont[state.newGoNum];
                    console.log("🚀 ~ file: index.js ~ line 2 ~ state.setAllValue=setInterval ~ current", current);
                    let msg = {
                        funcName: "cameraFly",
                        param: {
                            id: current.oneId,
                            dataDelay: true,
                            title: current.title,
                            content: current.content,
                        },
                    };
                    if (state.powerCont.length - 1 > state.newGoNum) {
                        state.newGoNum++;
                    } else {
                        state.newGoNum = 0;
                    }
                    router.push({ name: current.pathName });
                    screenWrap.contentWindow.postMessage(msg, "*");
                    commit("changeAnimateDelay", false);
                    // dispatch("roamPosition");
                }, 40000);
            } else {
                clearInterval(state.setAllValue);
                state.oldNum = state.goNum;
                let msg = {
                    funcName: "stopRotate",
                };
                screenWrap.contentWindow.postMessage(msg, "*");
                //   state.goStatus = false
            }
        },
        NoautomaticGo({ state }) {
            if (state.goStatus) {
                state.goStatus = false;
                clearInterval(state.setAllValue);
            } else {
                return;
            }
            // state.mapType = true
        },
        roamPosition({ state }) {
            // console.log(state.oldNum, state.goNum);
            const screenWrap = document.getElementById("screenWrap");
            let msg = {};
            let _num = state.goNum;
            if (state.oldNum == state.goNum) {
                msg = {
                    funcName: "surround",
                };
            } else {
                if (_num == 1) {
                    msg = {
                        funcName: "cameraFly",
                        param: {
                            id: 5,
                            dataDelay: true,
                            title: "海星医药健康创新园A区",
                            content:
                                "中关村科学城医药健康产业园，是中关村科学城首个以生物医药产业为核心的产业园,项目总占地面积28702.44平米，总建筑面积 57695.46平米，由18个单体建筑组成，建筑控高为24米，建筑首层层高4.5米，2~4层高6.4米，项目产业定位为生物医药的研发和中试。",
                        },
                    };
                    router.push({ name: "beilun" });
                } else if (_num == 3) {
                    msg = {
                        funcName: "cameraFly",
                        param: {
                            id: 2,
                            dataDelay: true,
                            title: "融汇国际大厦",
                            content:
                                "大厦位于北京市西北二环核心商区、总建筑面积45000平米，办公区面积234500平米，入驻企业涵盖安全技术、精准营销、云计算、基础设施等多个领域的行业重点企业，初步形成了业态相对丰富、链条较为完备的金融科技发展生态。",
                        },
                    };
                    router.push({ name: "roHuiHome" });
                } else if (_num == 2) {
                    msg = {
                        funcName: "cameraFly",
                        param: {
                            id: 3,
                            dataDelay: true,
                            title: "海星医药健康创新园B区",
                            content:
                                "项目位于海淀区永丰产业基地丰贤中路9号，用地性质为高新技术产业用地，项目用地33433.56m2，总建筑面积68400 m2，地上建筑面积43400 m2，地下建筑面积25000 m2。控制规划指标，控制高度≤24米，总体容积率1.3，地块绿地率35％，项目打造具备生物医药企业所需的GMP生产厂房和研发基地。",
                        },
                    };
                    router.push({ name: "feiChiHome" });
                } else if (_num == 4) {
                    msg = {
                        funcName: "cameraFly",
                        param: {
                            id: 4,
                            dataDelay: true,
                            title: "温泉冰雪体育公园项目",
                            content:
                                "位于海淀区温泉镇双坡路11号，总占地面积 1100亩。项目以冰雪运动为主，四季兼顾。项目拥有我市离市区最近的室外中大型滑雪场——北京西山滑雪场，雪道面积12万平米，单雪季接待人次超10万，现已成为海淀区冰雪运动新地标。",
                        },
                    };
                    router.push({ name: "jinHui" });
                } else if (_num == 5) {
                    msg = {
                        funcName: "cameraFly",
                        param: {
                            id: 8,
                            dataDelay: true,
                            title: "中关村国际创新大厦",
                            content:
                                "中关村国际创新大厦被海淀区政府定位为前沿科技项目，并由海淀区领导亲自为大厦冠名，入驻企业由海淀区投促局进行甄选，均为新技术产业。项目于2015 年4 月16 日开始入驻并全程跟进大厦整体改造，内配套餐饮、咖啡厅、超市等设施一应俱全。北京万佳鑫物业秉承“为您着想、为您服务、让您满意”的宗旨，努力提升服务品质，为客户营造安全、舒适、高雅的办公环境。",
                        },
                    };
                    router.push({ name: "zhongguancunHome" });
                } else if (_num == 6) {
                    msg = {
                        funcName: "cameraFly",
                        param: {
                            id: 6,
                            dataDelay: true,
                            title: "北京卫星制造厂",
                            content:
                                "北京卫星制造厂旧址位于北京市海淀区创业大街——知春路，是北京乃至中国科技、教育、文化资源最为丰富的区域。项目以中关村大街、学院路为轴线，是中关村科学城核心区，也是中关村国家自主创新示范区核心区的核心。北京卫星制造厂是我国东方红一号卫星诞生地，也是我国多个卫星、返回舱诞生地，具有丰富的历史背景。",
                        },
                    };
                    router.push({ name: "weixingHome" });
                }
            }
            screenWrap.contentWindow.postMessage(msg, "*");
        },
        codeLogin({ state, commit }) {
            let loginValue = JSON.parse(localStorage.getItem("code"));
            if (loginValue && new Date().getTime() - loginValue.expired > 1000 * 60 * 60 * 24) {
                state.newGoNum = 0;
                localStorage.removeItem("code");
                loginValue = null;
            }
            //   https://api-dataview.bjstarfish.com/api/v1/login_fe
            setTimeout(() => {
                // console.log(router, 11111);
                if (!router.app._route.query.code && !loginValue) {
                    // console.log("返回登录页");
                    router.push({ path: "/login" });
                    // commit("changeShowMapValue", true);
                    // router.push({name:'roHuiHome'})
                    // router.push({ path: "/roHuiHome" });
                } else {
                    if (loginValue) {
                        if (loginValue.code) {
                            const _test = loginValue.userData.permissions;
                            const _perJson = JSON.stringify(_test);
                            if (
                                _perJson.indexOf("海星医药健康创新园A区") != -1 ||
                                _perJson.indexOf("融汇国际大厦") != -1 ||
                                _perJson.indexOf("海星医药健康创新园B区") != -1 ||
                                _perJson.indexOf("中关村国际创新大厦") != -1 ||
                                _perJson.indexOf("北京卫星制造厂") != -1 ||
                                _perJson.indexOf("温泉冰雪体育公园项目") != -1 ||
                                _perJson.indexOf("大行基业") != -1 ||
                                _perJson.indexOf("海星医药健康创新园C区") != -1 ||
                                _perJson.indexOf("其他资产") != -1
                            ) {
                                // console.log('登录成功~~~~~~~~~~')
                                commit("changeShowMapValue", true);
                                state.loginType = 'code'
                                // commit('changeMapType')
                            } else if (/高德大厦/.test(_perJson)) {
                                commit("changeShowMapValue", true);
                                state.loginType = 'code'
                            } else {
                                localStorage.removeItem("code");
                                notification.error({
                                    message: "无访问权限，请联系管理员核实~",
                                });
                                commit("changeShowMapValue", false);
                                commit("changeLoadingStatus", true);
                                state.mapType = false;
                                router.push({ path: "/login" });
                            }
                        } else {
                            getAccountData({
                                token: loginValue.token,
                            }).then((data) => {
                                // console.log(data);
                                localStorage.setItem("code", JSON.stringify({ token: loginValue.token, userData: data.data, expired: new Date().getTime() }));
                                const _test = data.data.permissions;
                                const _perJson = JSON.stringify(_test);

                                if (
                                    _perJson.indexOf("海星医药健康创新园A区") != -1 ||
                                    _perJson.indexOf("融汇国际大厦") != -1 ||
                                    _perJson.indexOf("海星医药健康创新园B区") != -1 ||
                                    _perJson.indexOf("中关村国际创新大厦") != -1 ||
                                    _perJson.indexOf("北京卫星制造厂") != -1 ||
                                    _perJson.indexOf("温泉冰雪体育公园项目") != -1 ||
                                    _perJson.indexOf("大行基业") != -1 ||
                                    _perJson.indexOf("海星医药健康创新园C区") != -1 ||
                                    _perJson.indexOf("其他资产") != -1
                                ) {
                                    commit("changeShowMapValue", true);
                                    state.loginType = 'code'
                                    // commit('changeMapType')
                                } else if (/高德大厦/.test(_perJson)) {
                                    commit("changeShowMapValue", true);
                                    state.loginType = 'code'
                                } else {
                                    localStorage.removeItem("code");
                                    notification.error({
                                        message: "无访问权限，请联系管理员核实~",
                                    });
                                    commit("changeShowMapValue", false);
                                    commit("changeLoadingStatus", true);
                                    state.mapType = false;
                                    router.push({ path: "/login" });
                                }
                            });
                        }
                    } else {
                        // 首次扫码登录成功执行
                        getUserData({
                            code: router.app._route.query.code,
                        }).then((data) => {
                            localStorage.setItem("code", JSON.stringify({ code: router.app._route.query.code, userData: data.data, expired: new Date().getTime() }));
                            commit("getPower");
                            // const _test = JSON.parse(localStorage.getItem('code')).userData.permissions
                            const _test = data.data.permissions;
                            const _perJson = JSON.stringify(_test);
                            if (
                                _perJson.indexOf("海星医药健康创新园A区") != -1 ||
                                _perJson.indexOf("融汇国际大厦") != -1 ||
                                _perJson.indexOf("海星医药健康创新园B区") != -1 ||
                                _perJson.indexOf("温泉冰雪体育公园项目") != -1 ||
                                _perJson.indexOf("中关村国际创新大厦") != -1 ||
                                _perJson.indexOf("北京卫星制造厂") != -1 ||
                                _perJson.indexOf("大行基业") != -1 ||
                                _perJson.indexOf("海星医药健康创新园C区") != -1 ||
                                _perJson.indexOf("其他资产") != -1
                            ) {
                                commit("changeShowMapValue", true);
                                state.loginType = 'code'
                                // commit('changeMapType')
                                // console.log('登录成功22222222~~~~~~~~~~')
                                router.push({ path: "/" });
                                // for (let key in _test) {
                                //     if (_test[key] == "海星医药健康创新园A区") {
                                //         router.push({ path: "/beilun" });
                                //         return;
                                //     } else if (_test[key] == "融汇国际大厦") {
                                //         router.push({ path: "/roHuiHome" });
                                //         return;
                                //     } else if (_test[key] == "海星医药健康创新园B区") {
                                //         router.push({ path: "/feiChiHome" });
                                //         return;
                                //     } else if (_test[key] == "温泉冰雪体育公园项目") {
                                //         router.push({ path: "/jinHui" });
                                //         return;
                                //     } else if (_test[key] == "中关村国际创新大厦") {
                                //         router.push({ path: "/zhongguancunHome" });
                                //         return;
                                //     } else if (_test[key] == "北京卫星制造厂") {
                                //         router.push({ path: "/weixingHome" });
                                //         return;
                                //     } else if (_test[key] == "大行基业") {
                                //         router.push({ path: "/dahang" });
                                //         return;
                                //     } else if (_test[key] == "海星医药健康创新园C区") {
                                //         router.push({ path: "/haixing" });
                                //         return;
                                //     } else if (_test[key] == "其他资产") {
                                //         router.push({ path: "/other" });
                                //         return;
                                //     }
                                // }
                            } else if (/高德大厦/.test(_perJson)) {
                                commit("changeShowMapValue", true);
                                state.loginType = 'code'
                                router.push({ path: "/gdds" });
                            } else {
                                notification.error({
                                    message: "无访问权限，请联系管理员核实~",
                                });
                                router.push({ path: "/login" });
                            }
                        });
                    }
                }
            }, 1000);
            //#region
            // setTimeout(() => {
            //     const loginValue = JSON.parse(localStorage.getItem('code'))
            //     if (loginValue && new Date().getTime() - loginValue.expired > 1000*60*60*24) {
            //         localStorage.removeItem('code');
            //     }
            //     if (JSON.parse(localStorage.getItem('code'))) {
            //         //#region 刷新直接获取最新权限的方法
            //             /* const _code = JSON.parse(localStorage.getItem('code')).code
            //             getUserData({
            //                 code: _code
            //             }).then(data => {
            //                 const _data = data.data
            //                 if (Object.keys(_data.permission).length == 0) {
            //                     state.noPermissionValue = true
            //                     state.showCodeModel = true
            //                 } else {
            //                     const _perJson = JSON.stringify(_data.permission)
            //                     if (_perJson.indexOf('海星医药健康创新园A区') != -1 ||
            //                         _perJson.indexOf('融汇国际大厦') != -1 ||
            //                         _perJson.indexOf('海星医药健康创新园B区') != -1 ||
            //                         _perJson.indexOf('温泉冰雪体育公园项目') != -1
            //                     ) {
            //                         state.showCodeModel = false
            //                     } else {
            //                         state.showCodeModel = true
            //                         state.noPermissionValue = true
            //                     }
            //                 }
            //             }) */
            //         //#endregion ==========================
            //         const _permission = JSON.parse(localStorage.getItem('code')).userData.permissions
            //         if (Object.keys(_permission).length == 0) {
            //             state.noPermissionValue = true
            //         } else {
            //             const _perJson = JSON.stringify(_permission)
            //             // console.log(11111,JSON.stringify(_permission).indexOf('海星医药健康创新园A区'))
            //             if (_perJson.indexOf('海星医药健康创新园A区') != -1 ||
            //                 _perJson.indexOf('融汇国际大厦') != -1 ||
            //                 _perJson.indexOf('海星医药健康创新园B区') != -1 ||
            //                 _perJson.indexOf('温泉冰雪体育公园项目') != -1
            //             ) {
            //                 state.showCodeModel = false
            //             } else {
            //                 state.noPermissionValue = true
            //             }
            //         }
            //     } else {
            //         if(router && router.app && router.app._route.query && router.app._route.query.code && val) {
            //             // console.log(178898989, router.app._route.query.code)
            //             const _code = router.app._route.query.code
            //             getUserData({
            //                 code: router.app._route.query.code
            //             }).then(data => {
            //                 // console.log('用户信息', data)
            //                 localStorage.setItem('code', JSON.stringify({ code: _code, userData: data.data, expired: new Date().getTime() }))
            //                 const _test = JSON.parse(localStorage.getItem('code')).userData.permissions
            //                 const _perJson = JSON.stringify(_test)
            //                 if (_perJson.indexOf('海星医药健康创新园A区') != -1 ||
            //                     _perJson.indexOf('融汇国际大厦') != -1 ||
            //                     _perJson.indexOf('海星医药健康创新园B区') != -1 ||
            //                     _perJson.indexOf('温泉冰雪体育公园项目') != -1
            //                 ) {
            //                     state.showCodeModel = false
            //                     for (let key in _test) {
            //                         if (_test[key] == '海星医药健康创新园A区') {
            //                             return
            //                         } else if (_test[key] == '融汇国际大厦') {
            //                             router.push({path:'/roHuiHome'})
            //                             return
            //                         } else if (_test[key] == '海星医药健康创新园B区') {
            //                             router.push({path:'/feiChiHome'})
            //                             return
            //                         } else if (_test[key] == '温泉冰雪体育公园项目') {
            //                             router.push({path:'/jinHui'})
            //                             return
            //                         }
            //                     }
            //                 } else {
            //                     state.noPermissionValue = true
            //                 }
            //             })
            //         } else {
            //             // console.log('需登录11')
            //             new window.WwLogin({
            //                 id: 'wx_qrcode', //登录页面显示二维码的容器id
            //                 /* appid: 'ww622984afb4494816', //企业微信的CorID，在企业微信管理端查看(小行家)
            //                 agentid: '1000005', //授权方的网页应用id，在具体网页应用查看（小行家）
            //                 redirect_uri: encodeURIComponent('http://192.168.0.108:8080'), //重定向的地址，需要将新encode */
            //                 appid: 'ww14ea3e754e4d6d0c', //企业微信的CorID，在企业微信管理端查看（贝伦）
            //                 agentid: '1000008', //授权方的网页应用id，在具体网页应用查看（贝伦）
            //                 redirect_uri: encodeURIComponent('https://dataview.bjstarfish.com'), //重定向的地址，需要将新encode
            //                 state: set_GetUUIDFun(),
            //             })
            //         }
            //         /* if(this.$route.query && this.$route.query.code) {
            //             // console.log(178898989)
            //             const _code = this.$route.query.code
            //             localStorage.setItem('code', JSON.stringify({ code: _code, expired: new Date().getTime() }))
            //             state.showCodeModel = false
            //         } */
            //     }
            // },1000)
            //#endregion
        },
        getFeichiData({ state }) {
            getProjectOverview().then((data) => {
                state.feichiDataObj = data.data[0];
                // console.log(state.feichiDataObj, "111222333");
            });
        },
        closeNoModal: ({ state }) => {
            state.noPermissionValue = false;
            localStorage.removeItem("code");
            new window.WwLogin({
                id: "wx_qrcode", //登录页面显示二维码的容器id
                // appid: 'ww622984afb4494816', //企业微信的CorID，在企业微信管理端查看(小行家)
                // agentid: '1000005', //授权方的网页应用id，在具体网页应用查看（小行家）
                // redirect_uri: encodeURIComponent('http://192.168.0.108:8080'), //重定向的地址，需要将新encode
                appid: "ww14ea3e754e4d6d0c", //企业微信的CorID，在企业微信管理端查看（贝伦）
                agentid: "1000008", //授权方的网页应用id，在具体网页应用查看（贝伦）
                redirect_uri: encodeURIComponent("https://dataview.bjstarfish.com"), //重定向的地址，需要将新encode
                state: set_GetUUIDFun(),
            });
        },
        setQyCode: ({ state }) => {
            state.showCodeModel = true;
            setTimeout(() => {
                if (state.showCodeModel) {
                    new window.WwLogin({
                        id: "wx_qrcode", //登录页面显示二维码的容器id
                        // appid: "ww622984afb4494816", //企业微信的CorID，在企业微信管理端查看(小行家)
                        // agentid: "1000005", //授权方的网页应用id，在具体网页应用查看（小行家）
                        // redirect_uri: encodeURIComponent("http://192.168.0.108:8080"), //重定向的地址，需要将新encode
                        appid: "ww14ea3e754e4d6d0c", //企业微信的CorID，在企业微信管理端查看（贝伦）
                        agentid: "1000008", //授权方的网页应用id，在具体网页应用查看（贝伦）
                        redirect_uri: encodeURIComponent("https://dataview.bjstarfish.com"), //重定向的地址，需要将新encode
                        state: set_GetUUIDFun(),
                    });
                }
            }, 500);
        },
        codeChangeValue: ({ state, commit, dispatch }) => {
            commit("changeShowCodeModel");
            // console.log(state.showCodeModel);
            if (state.showCodeModel) {
                setTimeout(() => {
                    dispatch("setQyCode");
                }, 500);
            }
        },
        // 记住密码时，进入登录页，自动填充账号密码
        fillDefaultValue: ({ state }) => {
            const _keepValue = JSON.parse(localStorage.getItem("keepAccount"));
            if (_keepValue) {
                state.keepPassWord = _keepValue.keep;
                state.loginForm = {
                    account: _keepValue.account,
                    password: _keepValue.password,
                };
            }
        },
        // 账号密码登录
        userSignIn: ({ state, commit }) => {
            // console.log(state.loginForm);
            const _form = { ...state.loginForm };
            return new Promise((resolve, reject) => {
                state.dispatchValue = true;
                signUp({
                    ..._form,
                })
                    .then((data) => {
                        // console.log('账号密码登录',data, router.app._route.name);
                        // let currentName = router.app._route.name
                        // for (let i = 0; i < state.allPark.length; i++) {
                        //   if (state.allPark[i].pathName == currentName) {
                        //     const screenWrap = document.getElementById("screenWrap");
                        //     let msg = {
                        //       funcName: "cameraFly",
                        //       param: {
                        //         id: state.allPark[i].oneId,
                        //         dataDelay: true,
                        //         title: state.allPark[i].title,
                        //         content: state.allPark[i].content,
                        //       }
                        //     }
                        //     screenWrap.contentWindow.postMessage(msg, "*");
                        //   }
                        // }
                        // commit('changeMapType')

                        const _data = data.data;
                        const _perJson = JSON.stringify(_data.permissions);
                        const _per = _data.permissions;
                        // 是否记住密码
                        if (state.keepPassWord) {
                            localStorage.setItem("keepAccount", JSON.stringify({ ..._form, keep: state.keepPassWord }));
                        } else {
                            if (localStorage.getItem("keepAccount")) {
                                localStorage.removeItem("keepAccount");
                            }
                            state.keepPassWord = false;
                            state.loginForm = {
                                account: "",
                                password: "",
                            };
                        }
                        state.dispatchValue = false;
                        localStorage.setItem("code", JSON.stringify({ token: _data.token, userData: _data, expired: new Date().getTime() }));
                        commit("getPower");
                        if (
                            _perJson.indexOf("海星医药健康创新园A区") != -1 ||
                            _perJson.indexOf("融汇国际大厦") != -1 ||
                            _perJson.indexOf("海星医药健康创新园B区") != -1 ||
                            _perJson.indexOf("温泉冰雪体育公园项目") != -1 ||
                            _perJson.indexOf("中关村国际创新大厦") != -1 ||
                            _perJson.indexOf("北京卫星制造厂") != -1 ||
                            _perJson.indexOf("大行基业") != -1 ||
                            _perJson.indexOf("海星医药健康创新园C区") != -1 ||
                            _perJson.indexOf("其他资产") != -1
                        ) {
                            commit("changeShowMapValue", true);
                            resolve();
                            state.loginType = 'passwd'
                            router.push({ path: "/" });
                            // for (let key in _per) {
                            //     if (_per[key] == "海星医药健康创新园A区") {
                            //         router.push({ path: "/beilun" });
                            //         return;
                            //     } else if (_per[key] == "融汇国际大厦") {
                            //         router.push({ path: "/roHuiHome" });
                            //         return;
                            //     } else if (_per[key] == "海星医药健康创新园B区") {
                            //         router.push({ path: "/feiChiHome" });
                            //         return;
                            //     } else if (_per[key] == "温泉冰雪体育公园项目") {
                            //         router.push({ path: "/jinHui" });
                            //         return;
                            //     }
                            // }
                        } else if (/高德大厦/.test(_perJson)) {
                            commit("changeShowMapValue", true);
                            resolve();
                            state.loginType = 'passwd'
                            router.push({ path: "/gdds" });
                        } else {
                            notification.error({
                                message: "无访问权限，请联系管理员核实~",
                            });
                        }
                    })
                    .catch((error) => {
                        // console.log(error);
                        state.dispatchValue = false;
                        reject();
                    });
            });
        },
        powerArr({ state }) {
            // return
            let powerObj = state.powerStroage;
            let allPark = state.allPark;
            for (let i = 0; i < allPark.length; i++) {
                for (let key in powerObj) {
                    if (powerObj[key] == allPark[i].perName) {
                        state.powerCont.push(allPark[i]);
                    }
                }
            }
            // let newObj = []
            // state.powerCont
        },
    },
    getters: {},
    modules: {
        home,
        attract,
        overView,

        zhongguancunHome,
        zhongguancunAttract,
        zhongguancunIndustry,

        weixingHome,
    },
});
