<!--
 * @Author: PC
 * @Date: 2022-07-08 15:39:33
 * @LastEditTime: 2022-08-08 15:42:44
 * @Description: 请您输入文件描述
-->
<template>
  <div class="summarize_wrap animate__animated animate__fadeInRight">
    <TitleRight :txt="'产业园区企业概况'" />
    <ul class="summarize_list">
      <li>
        <HomeIcon
          :width="'25'"
          :height="'25'"
          :iconSize="'0.9'"
          :absoluteValue="27"
          :src="require('@/assets/images/floor.png')" :color="'#5e8fed'" />
        <!-- <img class="summarize_list_num" src="~@/assets/images/XXX.png" alt=""> -->
        <span class="summarize_list_num">
            <!-- {{$store.state.home.dataInfo.survey.num ? $store.state.home.dataInfo.survey.num : '-'}} -->
            <ICountUp
                v-if="$store.state.home.dataInfo.survey.num"
                :delay="1000"
                :endVal="$store.state.home.dataInfo.survey.num"
                :options="{separator: ',', decimalPlaces: '0'}"
            />
            <span v-else>-</span>
        </span>
        <p class="summarize_list_txt">企业数量 <span>(家)</span></p>
      </li>
      <li>
        <HomeIcon
          :width="'25'"
          :height="'25'"
          :iconSize="'0.9'"
          :absoluteValue="27"
          :src="require('@/assets/images/floor2.png')" :color="'#714ec9'" />
        <!-- <img class="summarize_list_num" src="~@/assets/images/XXX.png" alt=""> -->
        <span class="summarize_list_num">
            <!-- {{$store.state.home.dataInfo.survey.topNum ? $store.state.home.dataInfo.survey.topNum : '-'}} -->
            <ICountUp
                v-if="$store.state.home.dataInfo.survey.topNum"
                :delay="1000"
                :endVal="$store.state.home.dataInfo.survey.topNum"
                :options="{separator: ',', decimalPlaces: '0'}"
            />
            <span v-else>-</span>
        </span>
        <p class="summarize_list_txt">国高新企业数量 <span>(家)</span></p>
      </li>
      <li>
        <HomeIcon
          :width="'25'"
          :height="'25'"
          :iconSize="'0.9'"
          :absoluteValue="27"
          :src="require('@/assets/images/people1.png')" :color="'#1eb3c6'" />
        <!-- <img class="summarize_list_num" src="~@/assets/images/XXX.png" alt=""> -->
        <span class="summarize_list_num">
            <!-- {{$store.state.home.dataInfo.survey.peoples ? $store.state.home.dataInfo.survey.peoples : '-'}} -->
             <ICountUp
                v-if="$store.state.home.dataInfo.survey.peoples"
                :delay="1000"
                :endVal="$store.state.home.dataInfo.survey.peoples"
                :options="{separator: ',', decimalPlaces: '0'}"
            />
            <span v-else>-</span>
        </span>
        <p class="summarize_list_txt">员工总人数</p>
      </li>
      <li>
        <HomeIcon
          :width="'25'"
          :height="'25'"
          :iconSize="'0.9'"
          :absoluteValue="27"
          :src="require('@/assets/images/people2.png')" :color="'#4e59e3'" />
        <!-- <img class="summarize_list_num" src="~@/assets/images/XXX.png" alt=""> -->
        <span class="summarize_list_num">
            <!-- {{$store.state.home.dataInfo.survey.personnel ? $store.state.home.dataInfo.survey.personnel : '-'}} -->
             <ICountUp
                v-if="$store.state.home.dataInfo.survey.personnel"
                :delay="1000"
                :endVal="$store.state.home.dataInfo.survey.personnel"
                :options="{separator: ',', decimalPlaces: '0'}"
            />
            <span v-else>-</span>
        </span>
        <p class="summarize_list_txt">科技人才数量</p>
      </li>
      <li>
        <HomeIcon
          :width="'25'"
          :height="'25'"
          :iconSize="'0.9'"
          :absoluteValue="27"
          :src="require('@/assets/images/term.png')" :color="'#ffc691'" />
        <!-- <img class="summarize_list_num" src="~@/assets/images/XXX.png" alt=""> -->
        <span class="summarize_list_num">
            <!-- {{$store.state.home.dataInfo.survey.property ? $store.state.home.dataInfo.survey.property : '-'}} -->
             <ICountUp
                v-if="$store.state.home.dataInfo.survey.property"
                :delay="1000"
                :endVal="$store.state.home.dataInfo.survey.property"
                :options="{separator: ',', decimalPlaces: '0'}"
            />
            <span v-else>-</span>
        </span>
        <p class="summarize_list_txt">创业创新成果 <span>(件)</span></p>
      </li>
      <li>
        <HomeIcon
          :width="'25'"
          :height="'25'"
          :iconSize="'0.9'"
          :absoluteValue="27"
          :src="require('@/assets/images/piece.png')" :color="'#e07379'" />
        <!-- <img class="summarize_list_num" src="~@/assets/images/XXX.png" alt=""> -->
        <span class="summarize_list_num">
            <!-- {{$store.state.home.dataInfo.survey.newResult ? $store.state.home.dataInfo.survey.newResult : '-'}} -->
             <ICountUp
                v-if="$store.state.home.dataInfo.survey.newResult"
                :delay="1000"
                :endVal="$store.state.home.dataInfo.survey.newResult"
                :options="{separator: ',', decimalPlaces: '0'}"
            />
            <span v-else>-</span>
        </span>
        <p class="summarize_list_txt">知识产权 <span>(项)</span></p>
        
      </li>
    </ul>
  </div>
</template>

<script>
import '@/assets/css/home/homeRight/summarize.less'
import TitleRight from '@/components/TitleRight.vue'
import HomeIcon from '@/components/HomeIcon.vue'
import ICountUp from 'vue-countup-v2';
export default {
  components: {
    TitleRight,
    HomeIcon,
    ICountUp
  }
}
</script>