/*
 * @Author: PC
 * @Date: 2022-07-08 15:39:33
 * @LastEditTime: 2022-09-15 12:48:03
 * @Description: 请您输入文件描述
 */
import { getYuanqu, getParking, getProject, getCompanyProFile, getPersonnel, getCarAccess, getCanteen } from "@/api/beilun";
import moment from "moment";
const overView = {
    state: {
        test: "123",
        ICountUpOption: {
            delay: 1000,
            separator: "",
        },
        homeInfo: false,
        dataInfo: {
            removal: 0,
            area: "",
            hireArea: 0,
            hiredArea: 0,
            allArea: 58426.02,
            buildAre: 28702.44,
            buildings: 0,
            plotRatio: 1.3,
            cars: 0,
            situation: {
                num: "",
                peoples: "",
                financing: "",
                personnel: "",
                result: "",
                property: "",
            },
            staffAccess: [],
            strangeAccess: [],
            peopleDate: [],
            staffCars: [],
            strangeCars: [],
            carsDate: [],
            canteenConsumeNum: [],
            canteenConsumeMoney: [],
            canteenDate: [],
            //   accessMax: 150
        },
    },
    mutations: {
        changeStaff(state, department) {
            state.staffAccess = department.reverse();
        },
        changeStrange(state, department) {
            state.strangeAccess = department.reverse();
        },
        changeStaffCar(state, department) {
            state.staffCars = department.reverse();
        },
        changeStrangeCar(state, department) {
            state.strangeCars = department.reverse();
        },
        changeCanteenNum(state, department) {
            state.canteenConsumeNum = department.reverse();
        },
        changeCanteenMoney(state, department) {
            state.canteenConsumeMoney = department.reverse();
        },
        changeDate(state, department) {
            if (department.type == "people") {
                state.peopleDate = department.data.reverse();
            } else if (department.type == "cars") {
                state.carsDate = department.data.reverse();
            } else {
                state.canteenDate = department.data.reverse();
            }
        },
        /* changeMaxValue (state, department) {
         let maxNum = Math.max(...department)
         // console.log(maxNum)
         if (maxNum > 150) {
             state.accessMax = maxNum + 20
         }
     } */
    },
    actions: {
        getYuanquData({ state, commit }) {
            getYuanqu().then((res) => {
                // console.log("园区概况数据", res);
                const { data } = res;
                if (data.length > 0) {
                    state.dataInfo.removal = Number(data[0].selling_rate);
                    state.dataInfo.hiredArea = Number(data[0].has_rented_area);
                    state.dataInfo.hireArea = (Number(data[0].can_rent_area) - Number(data[0].has_rented_area));
                    state.dataInfo.buildings = Number(data[0].num_of_building);
                }
                // console.log(state.dataInfo);
            });
            getCompanyProFile().then((data) => {
                // console.log("企业概况", data);
                const _data = data.data[0];
                /* 
                company_num: "15"
                employee_num: "114"
                financing: null
                innovations: "45"
                intellectual_property: "356"
                talent_num: null
            */
                //    state.dataInfo.situation.num = 1
                state.dataInfo.situation = {
                    num: _data.company_num ? Number(_data.company_num) : "",
                    peoples: _data.employee_num ? Number(_data.employee_num) : "",
                    financing: _data.financing ? Number(_data.financing) : "",
                    personnel: _data.talent_num ? Number(_data.talent_num) : "",
                    result: _data.innovations ? Number(_data.innovations) : "",
                    property: _data.intellectual_property ? Number(_data.intellectual_property) : "",
                };
                // console.log(state.situation);
            });
            getPersonnel().then((data) => {
                // console.log("最近七天进出情况", data);
                const _data = data.data;
                let _staffList = [];
                let _strangeList = [];
                let _date = [];
                _data.map((item, index) => {
                    if (index < 7) {
                        _staffList.push(Number(item.employee));
                        _strangeList.push(Number(item.stranger));
                        _date.push(moment(item.date).format("MM.DD"));
                    }
                });
                // let getMax = _staffList.concat(_strangeList)
                // console.log(_staffList, _strangeList, _date);
                commit("changeStaff", _staffList);
                commit("changeStrange", _strangeList);
                commit("changeDate", { type: "people", data: _date });
                // commit('changeMaxValue',getMax)
            });
            getCarAccess().then((data) => {
                // console.log("最近七天车辆进出情况", data);
                const _data = data.data;
                let _staffCar = [];
                let _strangeCar = [];
                let _date = [];
                _data.map((item, index) => {
                    if (index < 7) {
                        _staffCar.push(Number(item.employee));
                        _strangeCar.push(Number(item.stranger));
                        _date.push(moment(item.date).format("MM.DD"));
                    }
                });
                // console.log(_staffCar, _strangeCar, _date);
                commit("changeStaffCar", _staffCar);
                commit("changeStrangeCar", _strangeCar);
                commit("changeDate", { type: "cars", data: _date });
            });
            getCanteen().then((data) => {
                // console.log("最近七天饭堂消费情况", data);
                const _data = data.data;
                let _canteenNum = [];
                let _canteenMoney = [];
                let _date = [];
                _data.map((item, index) => {
                    if (index < 7) {
                        _canteenNum.push(Number(item.number));
                        _canteenMoney.push(Number(item.money));
                        _date.push(moment(item.date).format("MM.DD"));
                    }
                });
                // console.log(_canteenNum, _canteenMoney, _date);
                commit("changeCanteenNum", _canteenNum);
                commit("changeCanteenMoney", _canteenMoney);
                commit("changeDate", { type: "canteen", data: _date });
            });
            getParking().then((data) => {
                // console.log("车辆数量", data);
                state.dataInfo.cars = Number(data.data[0].car_num);
            });
        },
        getBeilunWrapData() {
            getProject().then((data) => {
                // console.log("园区概况2", data);
                // const _data = data.data[0]
                // state.dataInfo.plotRatio = _data.volume_rate ? Number(_data.volume_rate) : 0
                /*  state.dataInfo.allArea = _data.all_lands ? Number(_data.all_lands) : 0
            state.dataInfo.buildAre = _data.all_building ? Number(_data.all_building) : 0 */
            });
        },
    },
};

export default overView;
