<template>
  <div class="title_wrap">
      <div class="title_left">
      </div>
      <div class="title_cont">
        {{txt}}
      </div>
  </div>
</template>
<script>
  export default {
    props: {
      txt: {
        default: '',
        type: String
      }
    },
    
  }
</script>
<style lang="less" scoped>
  .title_wrap{
    display: flex;

    // border-left: 2px solid #99ccff;
    .title_left{
      width: 24px;
      height: 24px;
      background-image: linear-gradient(to right, #021b67, #04091f);
      position: relative;
      // z-index: -1;
      position: relative;
    }
    .title_left::before{
      content: '';
      position: absolute;
      top: -4px;
      left: 0;
      width: 2px;
      height: 150%;
      background: #99ccff;
    }
    .title_cont{
      width: 324px;
      padding-left: 10px;
      border-left: 1px solid #99ccff;
      background-image: linear-gradient(to right, rgba(4,32,73,.8), rgba(4, 32, 73, 0));
      text-align: left;
    }
  }
</style>