<template>
    <div class="homeLeftCont_medical">
        <Title :txt="'医疗健康产业图谱'" />
        <div style="height:360px;width:480px">
            <MedicalEchart v-if="showMedical"/>
        </div>
        <!-- <div id="medical_cont" ref="medical_cont" style="width: 600px; height: 600px;"></div> -->
    </div>
</template>
<script>
    import '@/assets/css/home/homeLeft/medical.less'
    import Title from '@/components/Title.vue'
    import MedicalEchart from '@/components/homeLeftCont/medicalEchart.vue'
    export default {
      components: {
        Title,
        MedicalEchart
      },
      data () {
          return {
            showMedical: false
          }
      },
      mounted () {
          setTimeout(() => {
              this.showMedical = true
          }, 1000);
      },
      methods: {
        initData() {

        }
      }
    }

</script>
