<template>
  <div class="homeMainCont_head">
    <h2 class="title">{{$store.state.home.dataInfo.activeInfo.name}}</h2>
    <p class="txt">{{$store.state.home.dataInfo.activeInfo.company_info}}</p>
  </div>
</template>
<script>
export default {

}
</script>
<style lang="less" scoped>
.homeMainCont_head {
  padding: 20px 40px;

  .title {
    font-size: 20px;
    text-align: center;
    margin-top: 20px;
  }

  .txt {
    text-indent: 2em;
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>