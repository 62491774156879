<template>
  <div class="homeMainCont_mainEchart">
    <div class="left_cont">
      <TitleMain :txt="'行业'" />
      <template v-if="$store.state.home.dataInfo.activeInfo.company_industry.length > 0">
        <div class="left_txt_title">
          <p class="left_txt_title_p">行业类型：
            <el-select v-model="typeValue" placeholder="请选择" size="small" :popper-append-to-body="false">
              <el-option v-for="item in $store.state.home.dataInfo.activeInfo.company_industry" :key="item.value"
                :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </p>
        </div>
        <div class="left_cont_middle">
          <div class="left_cont_middle_left">
            <el-radio-group v-model="radioValue" size="mini" fill="#2660D0">
              <el-radio-button label="comprehensive">综合实力</el-radio-button>
              <el-radio-button label="independent">自主研发</el-radio-button>
            </el-radio-group>
          </div>
          <div class="left_cont_middle_right">
            <p style="font-weight: bold; font-size:20px;color:#E93A46;">
              {{ grade($store.state.home.dataInfo.activeInfo.grade) &&
                  grade($store.state.home.dataInfo.activeInfo.grade)[radioValue].grade
              }}
            </p>
            <div class="left_cont_middle_right_bottom">
              <p>
                (超出同行<span>{{ grade($store.state.home.dataInfo.activeInfo.grade) &&
                    (grade($store.state.home.dataInfo.activeInfo.grade)[radioValue].proportion * 100).toFixed(2)
                }}%</span>)
              </p>
            </div>
          </div>
        </div>
        <MainEchartRgiht :strengthData="strength($store.state.home.dataInfo.activeInfo.tech_rd_strength)" />
      </template>
      <div v-else class="left_nodata">
        <img src="@/assets/images/word_cloud_nodata_bl.png" alt="" />
      </div>
      <div class="left_cont_bottom">
        <TitleMain :txt="'技术质量'" />
        <div class="left_txt_other"
          v-if="$store.state.home.dataInfo.activeInfo.patent_star && $store.state.home.dataInfo.activeInfo.patent_star.length > 0">
          <p style="font-size:14px;">发明专利：<span style="font-size:24px;color:#5E8FED;">{{
              $store.state.home.dataInfo.activeInfo.intellectual_property_statistics.independent_rd_invention_patent_num
          }}</span>
            件，占比 <span style="font-size:24px;color:#5E8FED;">{{
                $store.state.home.dataInfo.activeInfo.intellectual_property_statistics.independent_rd_invention_patent_num
                  ?
                  ($store.state.home.dataInfo.activeInfo.intellectual_property_statistics.independent_rd_invention_patent_num
                    / $store.state.home.dataInfo.activeInfo.intellectual_property_statistics.invention_patent_num *
                    100).toFixed(2) : 0
            }}</span>%，
            软著：<span style="font-size:24px;color:#5E8FED;">{{
                $store.state.home.dataInfo.activeInfo.intellectual_property_statistics.software_copyright_num
            }}</span>
            项
          </p>
          <p>实用新型：<span style="font-size:24px;color:#5E8FED;">{{
              $store.state.home.dataInfo.activeInfo.intellectual_property_statistics.independent_rd_utility_model_patent_num
          }}</span>
            件，占比 <span style="font-size:24px;color:#5E8FED;">{{
                $store.state.home.dataInfo.activeInfo.intellectual_property_statistics.independent_rd_utility_model_patent_num
                  ?
                  ($store.state.home.dataInfo.activeInfo.intellectual_property_statistics.independent_rd_utility_model_patent_num
                    / $store.state.home.dataInfo.activeInfo.intellectual_property_statistics.utility_model_patent_num *
                    100).toFixed(2) : 0
            }}</span>%
          </p>
          <div class="PieChat1">
            <div id="myChart1" :style="{ width: '350px', height: '160px' }"> </div>
            <div class="PieChat1-item">
              <div class="PieChat1-wrag">
                <p><span style="font-size:14px;color:#5E8FED;">{{
                    $store.state.home.dataInfo.activeInfo.patent_star[5].patent_num
                }}</span>
                  件，占比 <span style="font-size:14px;color:#5E8FED;">{{
                      ($store.state.home.dataInfo.activeInfo.patent_star[5].patent_num_proportion * 100).toFixed(2)
                  }}%</span>
                </p>
              </div>
              <div class="PieChat1-wrag">
                <p><span style="font-size:14px;color:#5E8FED;">{{
                    $store.state.home.dataInfo.activeInfo.patent_star[4].patent_num
                }}</span>
                  件，占比 <span style="font-size:14px;color:#5E8FED;">{{
                      ($store.state.home.dataInfo.activeInfo.patent_star[4].patent_num_proportion * 100).toFixed(2)
                  }}%</span>
                </p>
              </div>
              <div class="PieChat1-wrag">
                <p><span style="font-size:14px;color:#5E8FED;">{{
                    $store.state.home.dataInfo.activeInfo.patent_star[3].patent_num
                }}</span>
                  件，占比 <span style="font-size:14px;color:#5E8FED;">{{
                      ($store.state.home.dataInfo.activeInfo.patent_star[3].patent_num_proportion * 100).toFixed(2)
                  }}%</span>
                </p>
              </div>
              <div class="PieChat1-wrag">
                <p><span style="font-size:14px;color:#5E8FED;">{{
                    $store.state.home.dataInfo.activeInfo.patent_star[2].patent_num
                }}</span>
                  件，占比 <span style="font-size:14px;color:#5E8FED;">{{
                      ($store.state.home.dataInfo.activeInfo.patent_star[2].patent_num_proportion * 100).toFixed(2)
                  }}%</span>
                </p>
              </div>
              <div class="PieChat1-wrag">
                <p><span style="font-size:14px;color:#5E8FED;">{{
                    $store.state.home.dataInfo.activeInfo.patent_star[1].patent_num
                }}</span>
                  件，占比 <span style="font-size:14px;color:#5E8FED;">{{
                      ($store.state.home.dataInfo.activeInfo.patent_star[1].patent_num_proportion * 100).toFixed(2)
                  }}%</span>
                </p>
              </div>
            </div>
            <div class="PieChat1-img">
              <img src="../../assets/images/ring.png" />
            </div>
          </div>
        </div>
        <div v-else class="left_txt_other_nodata">
          <img src="@/assets/images/word_cloud_nodata_bl.png" alt="" />
        </div>
      </div>
    </div>
    <div class="right_cont">
      <Footer />
    </div>

  </div>
</template>
<script>
import TitleMain from '@/components/TitleMain.vue';
import MainEchartRgiht from '@/components/homeMainCont/mainEchartRight.vue';
import Footer from '@/components/homeMainCont/footer.vue';

export default {
  data() {
    return {
      radioValue: 'comprehensive',
      typeValue: '',
    };
  },
  computed: {
    grade() {
      const gradeName = {
        0: 'A',
        1: 'B',
        2: 'C',
        3: 'D',
        4: 'E'
      }
      return function (val) {
        return val.find((value) => {
          value.independent = {
            grade: gradeName[value.independent_rd_grade],
            proportion: value.independent_rd_exceed_proportion
          }
          value.comprehensive = {
            grade: gradeName[value.comprehensive_strength_grade],
            proportion: value.comprehensive_strength_exceed_proportion
          }
          return this.typeValue == value.industry_id
        })
      }
    },
    strength() {
      return function (val) {
        return val.find((value) => {
          return this.typeValue == value.industry_id
        })
      }
    }
  },
  components: {
    TitleMain,
    MainEchartRgiht,
    Footer
  },
  mounted() {
    this.defaultSelect()
    this.drawLine()
  },
  methods: {
    defaultSelect() {
      const opt = this.$store.state.home.dataInfo.activeInfo.company_industry
      if (opt && opt.length > 0) {
        this.typeValue = opt[0].value
      }
    },
    drawLine() {
      if (!this.$store.state.home.dataInfo.activeInfo.patent_star || this.$store.state.home.dataInfo.activeInfo.patent_star.length == 0) return
      let myChart1 = this.$echarts.init(document.getElementById('myChart1'))
      let infoData = [
        { value: this.$store.state.home.dataInfo.activeInfo.patent_star[5].patent_num, name: '5星专利' },
        { value: this.$store.state.home.dataInfo.activeInfo.patent_star[4].patent_num, name: '4星专利' },
        { value: this.$store.state.home.dataInfo.activeInfo.patent_star[3].patent_num, name: '3星专利' },
        { value: this.$store.state.home.dataInfo.activeInfo.patent_star[2].patent_num, name: '2星专利' },
        { value: this.$store.state.home.dataInfo.activeInfo.patent_star[1].patent_num, name: '1星专利' },
      ]
      let option = {
        tooltip: {
          trigger: 'item',
          confine: true
        },
        title: {
          text: '{b|技术评级}',
          textStyle: {
            rich: {
              b: {
                fontSize: "16",
                color: "#fff",
              }
            }
          },
          itemGap: 10,
          left: 'center',
          top: "center"
        },
        legend: {
          orient: 'vertical',
          x: '240',
          y: '20',
          align: 'left',
          itemHeight: 10,
          itemWidth: 10,
          itemGap: 10,
          textStyle: {
            color: '#fff',
            fontSize: '14',
          },
        },
        color: ['#5e8fed', '#dd7077', '#ffc691', '#51dbb2', '#4E59E3'],
        series: [{
          name: 'Access From',
          type: 'pie',
          radius: ['80%', '65%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'outer',
            alignTo: 'labelLine',
          },
          emphasis: {
            label: {
              show: false,
              fontSize: '40',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          itemStyle: {
            borderRadius: 0,
          },

          data: infoData,
        }],
      }
      myChart1.setOption(option)
    }
  }
}
</script>
<style lang="less">
.homeMainCont_mainEchart {
  position: relative;
  padding: 0 21px;
  display: flex;
  justify-content: space-between;

  .left_cont {

    .left_cont_middle {
      display: flex;
      justify-content: space-between;
      margin: 0 20px;
      margin-top: 18px;

      .left_cont_middle_left {
        display: flex;
        align-items: center;
        height: 70px;

        .el-radio-button__inner {
          background: transparent;
          color: #FFFFFF;
        }
      }

      .left_cont_middle_right {
        text-align: center;

        .left_cont_middle_right_bottom {
          width: 130px;
          height: 20px;
          background: #EB515C;
        }
      }
    }
  }
}

.left_cont_bottom {
  .left_txt_other {
    //  width: 100%;
    flex-direction: column;

    p {
      width: 100% !important;
      font-size: 13.5px !important;

      span {
        font-family: 'bigdsfont';
      }
    }
  }

  .left_txt_other {
    display: flex;
    padding: 18px 22px 0;

    p {
      width: 45%;
      font-size: 12px;
    }

    .redColor {
      margin: 0;
      color: #e54852;
    }

    .txt_info {
      font-size: 20px;
      margin: 0 7px;
      color: #5e8fed;

      // display: flex;
      p {
        color: '#fff';
      }
    }
  }

  .left_txt_box p:nth-child(1) {
    margin-right: 27px;
  }

  .left_txt_other_nodata {
    width: 100%;
    height: 420px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 160px;
      height: 110px;
    }
  }

  // margin-top: 20px;
  .PieChat1 {
    // margin-left: -50px;
    display: flex;
    position: relative;
    margin-left: -100px;
    padding-bottom: 75px;

    .PieChat1-img {
      position: absolute;
      top: 30px;
      left: 125px;
    }

    .PieChat1-item {
      margin-top: 22px;
      margin-left: -36px;
    }

    .PieChat1-wrag {
      padding-bottom: 5px;
      display: flex;
    }
  }
}

.left_txt_title>.left_txt_title_p {
  margin-top: 15px;
  margin-left: 15px;

  .el-select-dropdown__empty {
    padding: 15px 20px;
  }

  .el-select .el-input__inner:focus,
  .el-range-editor.is-active,
  .el-range-editor.is-active:hover,
  .el-select .el-input.is-focus .el-input__inner {
    border-color: #81ade9;
  }

  .el-select .el-input .el-select__caret {
    color: #ffffff;
    font-weight: bold;
  }

  .el-select-dropdown {
    background-color: #040a22;
    border: 1px solid #81ade9;
  }

  .popper__arrow::after {
    border-bottom-color: #81ade9 !important
  }

  .el-select-dropdown__item.selected {
    color: #ffffff;
  }

  .el-select-dropdown__item {
    color: #81ade9;
    font-weight: bold;
  }

  .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    background-color: #2660d0
  }
}

.el-input__inner {
  background-color: transparent !important;
  color: #ffffff !important;
}

.left_nodata {
  width: 100%;
  height: 246px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 160px;
    height: 110px;
  }
}
</style>