<template>
  <div>
    <SwiperList :switchBtnFlag="true" :swiperInfo="swiperInfo">
      <div class="enterpriseSwiper_list">
        <SwiperItem :style="{'width': 100/swiperInfo.length + '%'}" v-for="(item, index) in swiperInfo" :key="index">
          <div class="enterprise_list">
            <div @click="enterpriseInfoBtn(item2, index, index2)" v-for="(item2, index2) in item" :key="index2"
              :class="[{'enterprise_list_item': true,
              'enterprise_list_active': $store.state.home.activeList[0] === index && $store.state.home.activeList[1] === index2}]">
              <p>
                {{item2.name}}
              </p>
            </div>
          </div>
        </SwiperItem>
      </div>
    </SwiperList>
  </div>
</template>
<script>
import '@/assets/css/home/homeRight/enterprise.less'

import SwiperList from '@/components/SwiperList.vue'
import SwiperItem from '@/components/SwiperItem.vue'
export default {
  components: {
    SwiperList,
    SwiperItem
  },
  methods: {
    enterpriseInfoBtn(item2, index, index2) {
      console.log(item2)
      this.$store.state.home.activeList = []
      this.$store.state.home.activeList.push(index, index2)
      // // console.log(this.$store.state.home.activeList)
      this.$store.state.home.dataInfo.activeInfo = item2
      this.$store.state.home.moreFlag = false
      if (this.$store.state.home.homeInfo) {
        this.$store.state.home.homeInfo = false
        setTimeout(() => {
          this.$store.state.home.homeInfo = true
        }, 400)
        return
      }
      this.$store.state.home.homeInfo = true
      // // console.log(item2)
      // // console.log(this.$store.state.home.homeInfo)
      // // console.log(this.$store.state.beilun.homeInfo)
    }
  },
  props: {
    swiperInfo: {
      default: [],
      type: Array,
    }
  }
}
</script>
<style lang="less" scoped>
.enterpriseSwiper_list {
  width: 100%;
  display: flex;
}

.enterprise_list_item {
  cursor: pointer;
}
</style>