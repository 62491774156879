<template>
  <div class="HomeIcon_wrap">
    <div :style="{
    'border-color': color,
    'width': width+'px',
    'height': height+'px'}" class="plan_contList_box">
      <img :style="{'transform': 'scale('+ iconSize + ')'}" :src="src" alt="">
      <div :style="{
          'border-color': color,
          'top': `calc( 50% - ${absoluteValue}px / 2 )`,
          'left': `calc( 50% - ${absoluteValue}px / 2)`
      }" class="plan_contList_border"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    // 路径传递：require(路径)
    src: {
      default: '',
      type: String
    },
    color: {
      default: '',
      type: String
    },
    width: {
      default: '30',
      // type: String
    },
    height: {
      default: '30',
      // type: String
    },
    iconSize: {
      default: '1',
      type: String
    },
    absoluteValue: {
        default: 0,
        type: Number
    }
  }
}
</script>
<style lang="less" scoped>
  .HomeIcon_wrap{
    .plan_contList_box{
      width: 30px;
      height: 30px;
      display: flex;
      // margin-right: 15px;
      position: relative;
      // border: 2px solid #5e8fed;
      border-width: 2px;
      border-style: solid;
      img{
        margin: auto;
      }
    }
    .plan_contList_border {
      position: absolute;
      /* transform-origin: 17px 17px; */
      /* top: calc(50% - 34px / 2); */
      /* left: calc(50% - 32px / 2); */
      width: 100%;
      height: 100%;
      /* transform: scale(1.4); */
      border-width: 1px;
      border-style: solid;
      opacity: .2;
      transform: scale(0.5);
      animation: planBorderMove 1.3s infinite linear
    }
    
  }
  @keyframes planBorderMove {
        0% {
            opacity: 0;
            transform: scale(0.5);
        }
        50% {
            opacity: 0.8;
            transform: scale(1.2);
        }
        100% {
            opacity: 0;
            transform: scale(1.6);
        }
  }
</style>