<template>
  <div :class="[{'size': $store.state.home.homeInfo, 'noneAmin': $store.state.home.noneAmin}]"
    class="home_main_content">
    <!-- {{$store.state.home.homeInfo}} -->
    <div @click="close" class="close">
    </div>
    <div v-if="$store.state.home.homeInfo && !$store.state.home.moreFlag">

      <Head />
      <MainEchart v-if="$store.state.home.homeInfo && !$store.state.home.moreFlag" />
    </div>
    <div class="plan_tips" v-if="$store.state.home.homeInfo && $store.state.home.moreFlag">
      <Plan :planTxtFlag="false" />
    </div>
    <!-- <div v-if="$store.state.home.homeInfo && !$store.state.home.moreFlag" class="switchBtn">
      <div @click="switchBtnInfo('top')" title="上一页" class="switchBtn_item switchBtn_item_top">
        <img src="~@/assets/images/leftBtn.png" alt="">
      </div>
      <div @click="switchBtnInfo('bottom')" title="下一页" class="switchBtn_item switchBtn_item_bottom">
        <img src="~@/assets/images/rightBtn.png" alt="">
      </div>
    </div> -->
    <!-- <Footer /> -->
  </div>
</template>
<script>
import Head from '@/components/homeMainCont/head.vue'
import MainEchart from '@/components/homeMainCont/mainEchart.vue'
import Plan from '@/components/homeLeftCont/plan.vue'
// import Footer from '@/components/homeMainCont/footer.vue'


export default {
  components: {
    Head,
    MainEchart,
    Plan
    // Footer,
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      // // console.log(this.$store.state.home.test)
      // // console.log('_________-',this.store.state)
    },
    close() {
      this.$store.state.home.activeList = []
      this.$store.state.home.homeInfo = false
    },
    switchBtnTop() {

    },

    // 企业代表-切换效果
    switchBtnInfo(status) {
      if (status === 'bottom') {
        if (this.$store.state.home.dataInfo.swiperList[this.$store.state.home.activeList[0]].length - 1 > this.$store.state.home.activeList[1]) {
          this.$store.state.home.activeList = [this.$store.state.home.activeList[0], this.$store.state.home.activeList[1] += 1]
        } else {
          this.$store.state.home.activeList = [this.$store.state.home.activeList[0], 0]
        }
      } else {
        if (this.$store.state.home.activeList[1] === 0) {
          // console.log(this.$store.state.home.dataInfo.swiperList[this.$store.state.home.activeList[0]].length - 1)
          this.$store.state.home.activeList = [this.$store.state.home.activeList[0], this.$store.state.home.dataInfo.swiperList[this.$store.state.home.activeList[0]].length - 1]
        } else {
          this.$store.state.home.activeList = [this.$store.state.home.activeList[0], this.$store.state.home.activeList[1] -= 1]
        }
      }

      // 数据切换
      this.$store.state.home.dataInfo.activeInfo = this.$store.state.home.dataInfo.swiperList[this.$store.state.home.activeList[0]][this.$store.state.home.activeList[1]]
      this.$store.state.home.noneAmin = true
      if (this.$store.state.home.homeInfo) {
        this.$store.state.home.homeInfo = false
        setTimeout(() => {
          this.$store.state.home.noneAmin = false
          this.$store.state.home.homeInfo = true
        }, 250)
        return
      }
      // this.$store.state.home.dataInfo.swiperList[this.$store.state.home.activeList[0]][this.$store.state.home.activeList[1]]
      // // console.log(this.$store.state.home.dataInfo.swiperList[this.$store.state.home.activeList[0]][this.$store.state.home.activeList[1]])
      // // console.log(this.$store.state.home.dataInfo.swiperList,[this.$store.state.home.activeList[1]])
    }
  }
}
</script>
<style lang="less" scoped>
.size {
  transform: scale(1) !important;
}

.noneAmin {
  transform: scale(1) !important;
  transition: 0s !important;
}

.plan_tips {
  padding: 60px 51px 50px 21px;
}

.switchBtn {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
  height: 30px;
}

.switchBtn_item {
  width: 30px;
  height: 30px;
  margin: -10px 30px;
  cursor: pointer;
}

.switchBtn_item_top {
  // border-left: 10px solid transparent;
  // border-right: 10px solid transparent;
  // border-bottom: 20px solid #78a0d6;
}

.switchBtn_item_bottom {
  // border-left: 10px solid transparent;
  // border-right: 10px solid transparent;
  // border-top: 20px solid #78a0d6;
}

.home_main_content {
  background-image: url('../../assets/images/border3.png');
  background-repeat: no-repeat;
  background-size: 100%;
  width: 835px;
  height: 886px;
  box-sizing: border-box;
  position: absolute;
  left: 584px;
  top: 110px;
  transform: scale(0);
  transition: .3s;
  z-index: 9999;

  .close {
    width: 30px;
    height: 30px;
    cursor: pointer;
    position: absolute;
    top: 23px;
    right: 39px;
    background: url('./../../assets/images/close.png') no-repeat;
  }
}

.home_main_content::before {
  position: absolute;
  top: 0;
  left: 0;
  // content: '';
  width: 100%;
  height: 100%;
}

// .home_main_content::after {
//   background-image: url('../../assets/images/border3.png');
//   background-repeat: no-repeat;
//   position: absolute;
//   top: 0;
//   left: 0;
//   content: '';
//   width: 100%;
//   height: 100%;
//   z-index: -1;
// }
</style>