/*
 * @Author: ch3nh2
 * @Date: 2022-09-15 09:41:55
 * @LastEditors: ch3nh2
 * @LastEditTime: 2023-01-09 14:16:58
 * @FilePath: /beilunchanye_fe_20211208/src/utils/utils.js
 * @Description: none
 */
export const set_GetUUIDFun = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

/** 将数组进行分页，返回新的分页数组
 * @param {Object} pageSize 每页大小
 * @param {Object} arr 数组
 */
export function returnAllPageFunc(pageSize, arr) {
  let pageNum = 1
  let pageObj = {
    pageNum: 1,
    list: []
  }
  let pageResult = []

  let newArr = JSON.parse(JSON.stringify(arr))
  let totalPage = newArr.length ? Math.ceil(arr.length / pageSize) : 0 // 计算总页数

  for (let i = 1; i <= totalPage; i++) {
    if (totalPage == 1) {
      pageNum += 1
      pageObj.list = newArr.splice(0, arr.length)
    } else if (i <= totalPage) {
      pageNum += 1
      pageObj.list = newArr.splice(0, pageSize)
    } else {
      pageObj.list = newArr.splice(0, arr.length % pageSize)
    }
    pageResult.push(pageObj)
    pageObj = {
      pageNum: pageNum,
      list: []
    }
  }
  return pageResult
}