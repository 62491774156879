<template>
  <div class="title_wrap">
      <div class="title_cont">
        {{txt}}
      </div>
      <div class="title_left">
      </div>
  </div>
</template>
<script>
  export default {
    props: {
      txt: {
        default: '',
        type: String
      }
    },
    
  }
</script>
<style lang="less" scoped>
  .title_wrap{
    display: flex;
    justify-content: flex-end;
    border-right: 2px solid #99ccff;
    .title_left{
      width: 24px;
      height: 24px;
      background-image: linear-gradient(to left, #021b67, #04091f);
      position: relative;
      z-index: -1;
    }
    .title_cont{
      width: 324px;
      padding-right: 10px;
      text-align: right !important;
      border-right: 1px solid #99ccff;
      background-image: linear-gradient(to left, #042049, rgba(4, 32, 73, 0));
      text-align: left;
    }
  }
</style>