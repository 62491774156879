<template>
  <div>
    <slot></slot>
  </div>
</template>
<script>
export default {

}
</script>
<style lang="less" scoped>

</style>