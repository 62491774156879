<!--
 * @Author: PC
 * @Date: 2022-07-08 15:39:33
 * @LastEditTime: 2023-01-11 10:32:11
 * @Description: 请您输入文件描述
-->
<template>
  <div :class="[{ 'homeLeftCont_plan': true },
  { 'animate__animated': !$store.state.home.homeInfo && !$store.state.home.moreFlag },
  { 'animate__fadeInLeft': true }]">
    <Title :txt="'生物医药园区发展规划'" />
    <div class="plan_cont">
      <p class="plan_head_txt">根据运营空间产业布局，城市更新集团已经形成以海星医药健康创新园A区为研发中心，以海星医药健康创新园B区项目为中试配套、以上庄Z1地块为规模成长的海淀区医药健康全产业链产业集群。
      </p>
      <!-- <ul class="plan_contList">
          <li>
            <HomeIcon :absoluteValue="32" :src="require('@/assets/images/capsule.png')" :color="'#5e8fed'" />
            <p>万个药分子领域</p>
          </li>
          <li>
            <HomeIcon :absoluteValue="32" :src="require('@/assets/images/envelopes.png')" :color="'#4e59e3'" />
            <p>十亿税收</p>
          </li>
          <li>
            <HomeIcon :absoluteValue="32" :src="require('@/assets/images/medicine.png')" :color="'#714ec9'" />
            <p>千个药物研发项目</p>
          </li>
          <li>
            <HomeIcon :absoluteValue="32" :src="require('@/assets/images/diamonds.png')" :color="'#1eb3c6'" />
            <p>百亿产值</p>
          </li>
        </ul> -->
      <!-- <p v-if="!planTxtFlag" class="plan_head_txt plan_bottom_txt">{{$store.state.home.homeInfo && $store.state.home.moreFlag ? $store.state.home.dataInfo.moreInfo : '此外，海新域城市更新集团还构建了以北大西门中关村国际创新大厦、未来科技大厦为中心的人工智能、集成电路走廊，以及融汇国际大厦...'}}</p>
        <p v-if="planTxtFlag" class="plan_head_txt plan_bottom_txt">此外，海新域城市更新集团还构建了以北大西门中关村国际创新大厦、未来科技大厦为中心的人工智能、集成电路走廊，以及融汇国际大厦... <span @click="moreBtn">更多&gt;&gt;</span></p> -->
    </div>
  </div>
</template>
<script>
import '@/assets/css/home/homeLeft/plan.less'
import Title from '@/components/Title.vue'
import HomeIcon from '@/components/HomeIcon.vue'

export default {
  components: {
    Title,
    HomeIcon
  },
  props: {
    planTxtFlag: {
      default: true,
      type: Boolean
    }
  },
  methods: {
    moreBtn() {
      this.$store.state.home.moreFlag = true
      this.$store.state.home.homeInfo = !this.$store.state.home.homeInfo
      setTimeout(() => {
        this.$store.state.home.homeInfo = true
      }, 350)
      // // console.log(this.$store.state.home.moreFlag)
    }
  }
}
</script>