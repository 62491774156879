<template>
  <div @mouseover="inMouseover" @mouseleave="outMouseleave" class="swiper_wrap">
    <div :style="{'margin-left': - (active - 1) * 100 + '%', 'width': swiperInfo.length * 100 + '%'}"
      class="swiper_list">
      <slot></slot>
    </div>
    <div v-if="switchBtnFlag && swiperInfo.length > 1" class="switchBtn">
      <div @click="leftBtn" class="switchBtn_left">
        <img src="~@/assets/images/leftIcon.png" alt="">
      </div>
      <div @click="rightBtn" class="switchBtn_right">
        <img src="~@/assets/images/leftIcon.png" alt="">
      </div>
    </div>
    <div v-if="swiperInfo.length > 1" class="swiper_pagination">
      <span @click="swiperBtn(index)" v-for="(item, index) in swiperInfo" :key="index"
        :class="{'active': index == active - 1}"></span>
    </div>
  </div>
</template>
<script>
// import SwiperItem from '@/components/SwiperItem.vue'

export default {
  props: {
    swiperInfo: {
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
      type: Array
    },
    switchBtnFlag: {
      default: false,
      type: Boolean
    }
  },
  components: {
    // SwiperItem
  },
  mounted() {
    this.gotoPage()
  },
  data() {
    return {
      active: 1,
      swiperLeft: 0,
      timer: '',
    }
  },
  methods: {
    gotoPage() {
      this.timer = setInterval(() => {
        if (this.active >= this.swiperInfo.length) {
          this.active = 1
        } else {
          this.active += 1
        }
      }, 3500)
    },
    swiperBtn(index) {
      this.active = index + 1
    },
    inMouseover() {
      clearInterval(this.timer)
    },
    leftBtn() {
      if (this.active <= 1) {
        this.active = this.swiperInfo.length
      } else {
        this.active--
      }
      // console.log(this.active)
    },
    rightBtn() {
      if (this.active >= this.swiperInfo.length) {
        this.active = 1
      } else {
        this.active += 1
      }
    },
    outMouseleave() {
      this.gotoPage()
    }
  }
}
</script>
<style lang="less" scoped>
.swiper_wrap {
  overflow: hidden;
  position: relative;
}

.swiper_list {
  transition: .6s;
}

.swiper_pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  span {
    width: 8px;
    height: 8px;
    background: #9b9da6;
    border-radius: 50%;
    margin: 0 15px;
    cursor: pointer;
  }

  .active {
    background: #4482f9;
  }
}

.switchBtn {
  position: absolute;
  top: 140px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.switchBtn_left {
  margin-left: 32px;
}

.switchBtn_right {
  transform: rotateY(180deg);
  margin-right: 16px;
}
</style>