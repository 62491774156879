<template>
  <div class="BlockLine_wrap">
    <div :style="{'background': color}" class="block"></div>
    <div v-if="line" class="line_box">
      <div :style="{'background': color , 'height': height+'px'}" class="line"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    color: {
      default: 'green',
      type: String
    },
    height: {
      default: '30',
      type: String
    },
    line: {
      default: true,
      type: Boolean
    }
  }
}
</script>
<style lang="less" scoped>
  .BlockLine_wrap{
    width: 10px;
    margin-top: 6px;
    .block{
      width: 10px;
      height: 10px;
    }
    .line_box{
      display: flex;
      justify-content: center;
    }
    .line{
      width: 1px;
      // height: 30px;
    }
  }
</style>