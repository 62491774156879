<template>
  <div class="enterprise_wrap">
    <TitleRight :txt="'园区企业'" />
    <div class="swiper_box">
      <EnterpriseSwiper :swiperInfo="$store.state.home.dataInfo.swiperList" />
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import '@/assets/css/home/homeRight/enterprise.less'

import TitleRight from '@/components/TitleRight.vue'
import EnterpriseSwiper from '@/components/homeRightCont/enterpriseSwiper.vue'
// import FacilitiesSwiper from '@/components/attractLeft/FacilitiesSwiper.vue'

export default {
  components: {
    TitleRight,
    EnterpriseSwiper,
  },
  data() {
    return {
      swiperInfo: [],
      swiperOption: {
        // slidesPerView: 2,
        // 设置分页器
        pagination: {
          el: '.swiper-pagination',
          // 设置点击可切换
          // clickable: true
        },
        // nextButton: '.swiper-button-next',
        // prevButton: '.swiper-button-prev',
        // 设置前进后退按钮
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev'
        // },
        // 设置自动轮播
        // autoplay: {
        //   delay: 5000 // 5秒切换一次
        // },
        // // 设置轮播可循环
        // loop: true,
        // effect: 'flip',
        // grabCursor: true,
        // effect: 'cube',
        // grabCursor: true,
        // cube: {
        //     shadow: true,
        //     slideShadows: true,
        //     shadowOffset: 20,
        //     shadowScale: 0.94
        // }
      }
    }
  },
  methods: {
    onSwiper() {
      // console.log(swiper);
    },
    onSlideChange() {
      // console.log('slide change');
    }
  }
}
</script>
