const weixingHome = {
  state: {
    moreFlag: false,
    industryList: [
      {
        title: '属地产业政策:',
        txt: `海淀区重点发展新一代信息技术、智能制造与高端装备、医药健康、空天、新材料等高精尖产业。`
      },
      {
        title: '相关企业需求:',
        txt: `一批即将进入产业化阶段的项目、新增落地的引进项目和产业孵化项目，迫切希望在海淀区寻找高挑高、大空间、大承重、动力能源完备的场所用于承接后续产业化。`
      },
      {
        title: '产业聚集区位:',
        txt: `北京卫星厂区位优势明显，项目地处航天科技聚集区，具有浓厚的产业聚集效应。 `
      },
      {
        title: '属地产业政策:',
        txt: `海淀区重点发展新一代信息技术、智能制造与高端装备、医药健康、空天、新材料等高精尖产业。`
      },
      {
        title: '相关企业需求:',
        txt: `一批即将进入产业化阶段的项目、新增落地的引进项目和产业孵化项目，迫切希望在海淀区寻找高挑高、大空间、大承重、动力能源完备的场所用于承接后续产业化。 `
      }
    ],
    allAtlas: [
      {
        value: 5,
        name: '医药健康',
      },
      {
        value: 5,
        name: '智能制造与 高端装备',
      },
      {
        value: 5,
        name: '信息技术',
      },
      {
        value: 5,
        name: '集成电路',
      },
      {
        value: 5,
        name: '新材料',
      },
      {
        value: 5,
        name: '航空航天',
      },
    ]
  }
}

export default weixingHome